import React, { useEffect, useContext, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Card, Dropdown, Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button'
import logo from '../../assets/logo.png';
import AuthContext from '../../context/auth-context'
import Swal from 'sweetalert2'
import axios from 'axios'

interface Token {
  [key: string]: any;
}

const EnrolForm = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();

  const [uuid, setUuid] = useState<string | undefined>(undefined)
  const [user, setUser] = useState<Token | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)

  useEffect(() => {
    console.log("searchParams", searchParams.get("uuid"))
    const _uuid = searchParams.get("uuid")
    const _token = searchParams.get("token")

    if (_uuid !== null && _token !== null) {
      const [header, payload, signature] = _token.split('.');

      // Decode the base64 parts
      const decodedPayload = JSON.parse(atob(payload));
      setToken(_token)
      setUser(decodedPayload)
      setUuid(searchParams.get("uuid") || undefined)

      // Convert Unix timestamp to milliseconds
      const timestampInMillis = decodedPayload.expiry * 1000;

      // Get the current time in milliseconds
      const currentTimeInMillis = Date.now();

      // Compare the two timestamps
      if (timestampInMillis <= currentTimeInMillis) {
        Swal.fire({
          icon: 'error',
          title: 'Link expired.',
          html: "Please request for a new link from admin.",
          confirmButtonText: 'Ok'
          // footer: 
        }).then((result) => {
          navigate("/login");
        })
      }

    }
  }, [searchParams]);


  const handleEnrol = (tgUser?: undefined | TelegramUser) => {
    const url = `${process.env.REACT_APP_API_URI}staff/telegram/register`
    if (!uuid || !token) return

    let authBody;
    if (tgUser) {
      authBody = {
        "tg_user": tgUser,
        "token": token,
        "uuid": uuid
      }
    }

    axios.post(url, authBody).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Successfully registered.',
        html: "You may log-in to the system.",
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
        navigate("/login");
      })
    }).catch((err) => {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Link expired.',
        html: "Please request for a new link from admin.",
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
      })
    });
  }
  return (
    <div className='w-full flex justify-center items-center' style={{ margin: 0, top: "50%", position: "absolute", msTransform: "translateY(-50%)", transform: "translateY(-50%)" }}>
      <Card className=''>
        <div className="flex flex-col items-center pb-10">
          <img
            alt="Bonnie image"
            className="mb-3"
            src={logo}
            width="200"
          />
          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
            Hi, {user?.name}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Please register by clicking the link.
          </span>
          <div className="mt-4 flex space-x-3 lg:mt-6">
            {
              process.env.REACT_APP_TELE_BOT_USERNAME === "ams_dev_bot" &&
              <Button onClick={() => {
                handleEnrol({
                  id: 3,
                  first_name: "dan",
                  username: "danuser",
                  photo_url: "",
                  auth_date: 1701230055,
                  hash: "123123"

                })
              }}>Test enrol</Button>
            }
            <TelegramLoginButton
              botName={process.env.REACT_APP_TELE_BOT_USERNAME || ""}
              dataOnauth={(user: TelegramUser) => handleEnrol(user)}
            />
          </div>
        </div>
      </Card>

    </div>
  )
}

export default EnrolForm