import { Assignment } from '../components/cases/types'


export const parseTimestamp = (timestamp: string, fullYear?: boolean) => {
    const inputDate = new Date(timestamp.replace("GMT", ""));

    // console.log("unparsed", timestamp)
    // console.log("the date", inputDate)
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based, so add 1
    let year = inputDate.getFullYear() % 100; // Get last two digits of the year
    if (fullYear) {
        year = inputDate.getFullYear()
    }

    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString().padStart(2, '0')}`;


    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const time24HoursFormat = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return {
        date: formattedDate,
        time: time24HoursFormat
    }
}

export const convertDays = (days: string, short?: boolean) => {
    const dayMap: { [key: string]: string } = {
        "0": "Monday",
        "1": "Tuesday",
        "2": "Wednesday",
        "3": "Thursday",
        "4": "Friday",
        "5": "Saturday",
        "6": "Sunday"
    };
    const dayMapShort: { [key: string]: string } = {
        "0": "Mon",
        "1": "Tues",
        "2": "Wed",
        "3": "Thurs",
        "4": "Fri",
        "5": "Sat",
        "6": "Sun"
    };
    const daysArr = days.split(",")
    return daysArr.map((str: string) => {
        return short ? `${dayMapShort[str]}` : `${dayMap[str]}`
    }).join(", ")
}
export const cleanEmptyStrObj = (obj: { [key: string]: string }) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] === '' || obj[key] === null) {
            delete obj[key];
        }
    });
    return obj
}

export const statusColorMap = (status: string, tripType: string, assignment: Assignment[] | undefined) => {
    const color: { [key: string]: string } = {
        "cancel": "#F8B4B4",
        "assigned": "#0E9F6E"
    };


    // Handle 2w but only first trip assigned
    if (assignment) {
        const isTrip1Assigned = assignment.some(obj => obj.trip === 1);
        const isTrip2Assigned = assignment.some(obj => obj.trip === 2);

        /**
         * Return red color if:
         * - 1w but no assign, or
         * - 2w but only trip 1 assigned
         */

        if (!isTrip2Assigned && tripType === "2w") return "#FCE96A"
        if (!isTrip1Assigned && tripType === "1w") return "#FCE96A"

        /**
         * Return green if:
         * - 2w and 2 trips assigned
         * - 1w and 1 trip assigned
         */
        if (isTrip2Assigned && isTrip1Assigned && tripType === "2w") return "#84E1BC"
        if (isTrip1Assigned && tripType === "1w") return "#84E1BC"
    }

    // Other statuses that does not need to be coloured
    if (!Object.keys(color).includes(status)) return ""

    // Return all other colours
    return color[status]
}