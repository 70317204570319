import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import { Input, Select } from '../form-ui'
import { renderMessage } from '../../shared/msg-generator'
import { parseTimestamp } from '../../shared/utils'
import { serviceType, chairType, billType, tripType, daysOfWeek, month, year } from '../../shared/dropdown-constraints'
import { Staff } from './types'
import { staffType } from '../../shared/dropdown-constraints'
import { toast } from 'react-toastify';


import axios from 'axios'
import Swal from 'sweetalert2'

interface OptionItem {
  label: string;
  value: string;

}


function StaffForm() {
  const navigate = useNavigate();
  const location = useLocation();
  let staffData = location?.state?.staffData;
  let type: "create" | "edit"

  if (location?.state?.type) {
    type = location?.state?.type
  } else {
    type = staffData ? "edit" : "create"
  }


  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [onError, setOnError] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<boolean>(false);

  const [daysSelected, setDaysSelected] = useState<string[]>([]);


  useEffect(() => {
    console.log('daysSelected', daysSelected)
  }, [daysSelected]);

  useEffect(() => {
    console.log('staffData', staffData)
  }, [staffData]);

  // Initialise formValues if edit
  useEffect(() => {

    // Set default values
    setFormValues(
      {
        ...staffData,
        role: "Admin"
      }
    )

    // Overwrite default values if edit case
    if (staffData) {
      setFormValues(
        {
          ...staffData,
        }
      )
    }
  }, []);


  useEffect(() => {
    // setMessage(renderMessage(formValues))
  }, [formValues]);


  const handleStaffCreate = () => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}staff`
    const message = renderMessage(formValues)
    axios.post(url, formValues).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Staff created.',
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
        navigate("/staff");
      })

    }).catch((err) => {
      console.error(err)
      const errors = err.response?.data?.errors

      if (errors) {
        console.error(errors)
        Object.keys(errors).forEach((key) => {
          const message = errors[key].join(', ')
          toast.error(`${key}: ${message}`)
        });
        setBtnClicked((btnClicked) => !btnClicked)
      }
    });

  }

  const handleStaffEdit = () => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}staff/${staffData.id}`
    axios.put(url, formValues).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Staff edited.',
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
        navigate("/staff");
      })
    }).catch((err) => {
      console.error(err)
    });

  }

  const handleSubmit = (submitType: string) => {
    if (["create", "duplicate"].includes(submitType)) {
      handleStaffCreate()
    } else {
      handleStaffEdit()
    }

  }

  const handleInputChange = (name: string, value: string | undefined) => {
    // Only set the value in formValues if the value is not empty
    if (value !== undefined) {
      if (value.trim() === '') {
        // Remove the key from formValues if value is empty
        const { [name]: _, ...newFormValues } = formValues;
        setFormValues(newFormValues);
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    }
  };

  const generateTitle = () => {
    if (staffData) {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit Staff</h2>
    } else {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Create new Staff</h2>
    }
  }

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto lg:py-8" style={{ maxWidth: "1000px" }}>
        {
          generateTitle()
        }
        <form>
          <div>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-2">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} value={formValues["name"] || ''} name='name' fieldName='Staff Name' onChange={handleInputChange} />
                </div>
              </div>

            </div>

            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-2">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} value={formValues["tele_handle"] || ''} name='tele_handle' fieldName='Telegram Username' onChange={handleInputChange} />
                </div>
              </div>
              <div className="w-full">
                <div className="sm:col-span-2">
                  <Select values={staffType} value={formValues["role"] || "Driver"} name='role' fieldName='' type='regular' onChange={handleInputChange} />
                </div>
              </div>

            </div>

          </div>

          <div className='flex'>
            <Button disabled={onError || btnClicked} onClick={() => handleSubmit(type)} className="my-4 mr-3">
              {type} Staff
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default StaffForm;
