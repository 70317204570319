import React, { useState, useEffect } from 'react';
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch, PriceInput, BillToOrganisationList } from '../components/form-ui'
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import { Dialysis } from '../components/dialysis/types'
import { DialysisCaseProps } from '../components/cases/types'

export const DialysisCaseForm = (props: DialysisCaseProps) => {
  const {
    type,
    user,
    formValues,
    handleInputChange,
    setFormValues,
    handlePriceChange,
    onError,
    activeTab,
    btnClicked,
    handleCaseCreate,
    setOnError,
    tripType,
    serviceType,
    chairType,
    callType,
    billType,
    showBilling,
  } = props;

  interface OptionItem {
    label: string;
    value: string;

  }

  const [isSvcStart, setIsSvcStart] = useState<boolean>(false);
  const [dialysisList, setDialysisList] = React.useState<Dialysis[]>([])
  const [dialysisOptions, setDialysisOptions] = useState<OptionItem[]>([{ label: "s", value: "s" }]);
  const [billToOptions, setBillToOptions] = useState<OptionItem[]>([{ label: "s", value: "s" }]);

  const restrictedFieldsMedicDriver = [
    "pickup_date",
    "pickup_time",
    "trip_type",
    "service_type",
    "chair_type",
    "call_type",
    "location",
    "destination",
    "caller",
    "caller_number",
    "organisation",
    "dialysis_id"
  ]

  const restrictedFields: Record<string, String[]> = {
    "Admin": [],
    "Ctrl": [],
    "Medic": restrictedFieldsMedicDriver,
    "Driver": restrictedFieldsMedicDriver
  }

  const shouldDisable = (field: string) => {
    if (!user) return
    return restrictedFields[String(user?.role)].includes(field)
  }

  const fetchDialysis = () => {
    const URL = `${process.env.REACT_APP_API_URI}dialysis`

    axios.get(URL).then((res) => {
      setDialysisList(res.data)
    }).catch((err) => {
      console.error(err)
    });
  }


  useEffect(() => {
    if (activeTab !== "dialysis") return

    fetchDialysis()

    setFormValues(
      {
        case_type: "dialysis"
      }
    )

  }, [activeTab]);

  const parseTime = (timeString: string | undefined) => {
    if (!timeString) return ""
    const timeArray = timeString.split(":")
    if (timeArray.length !== 3) return ""
    return `${timeArray[0]}:${timeArray[1]}`

  }

  const fetchSingleDialysis = (id: string) => {
    const URL = `${process.env.REACT_APP_API_URI}dialysis/${id}`

    axios.get(URL).then((res) => {
      const data = res.data
      setFormValues({
        ...formValues,
        ...data,
        destination: data?.nkf_address,
        trip_type: "2w",
        "bill_type": "bill",
        pickup_time: parseTime(data?.appt_pickup),
      })
    }).catch((err) => {
      console.error("Cannot get single dialysis", err)
    });
  }

  const handleDialysisRecordChange = (name: string, value: string | undefined) => {
    if (!value) return
    handleInputChange(name, value)
    fetchSingleDialysis(value)
  }


  useEffect(() => {
    if (!dialysisList) return

    const options = dialysisList.map((row: Dialysis) => {
      return {
        label: `${row.id} - ${row.patient}`,
        value: String(row.id)
      }
    })
    setDialysisOptions(options)

  }, [dialysisList]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    return {
      actual: currentDate,
      formatted: currentDate.toLocaleDateString('en-SG')
    };
  };

  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, '0');
    const minutes = String(currentTime.getMinutes()).padStart(2, '0');

    return {
      actual: `${hours}:${minutes}`,
      formatted: `${hours}${minutes}`
    };
  };

  useEffect(() => {
  }, []);



  const handleServiceTypeChange = (name: string, value: string | undefined) => {
    handleInputChange(name, value)

    if (value && ["a&e", "discharge"].includes(value)) {
      handleInputChange("chair_type", "stretcher")
    }
  }

  const handleServiceStartEndChange = (name: string, value: string | undefined) => {
    if (value === ":") return
    handleInputChange(name, value)

    // Only checks for service_start
    if (name === "service_start") {
      if (value) {
        setIsSvcStart(true)
      } else {
        setIsSvcStart(false)
      }
    }
  }

  const handleCallTypeChange = (name: string, value: string | undefined) => {
    handleInputChange(name, value)

    if (value && ["1777", "dc"].includes(value)) {
      handleInputChange("pickup_date", getCurrentDate().formatted)
      handleInputChange("pickup_time", getCurrentTime().actual)
    }
  }

  return (
    <div>
      <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={dialysisOptions} value={formValues["dialysis_id"] || 's'} disabled={shouldDisable("dialysis_id")} name='dialysis_id' fieldName='Select Dialysis' type='searchable' onChange={handleDialysisRecordChange} />
          </div>
        </div>
      </div>


      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <DateInput helper onError={(e) => setOnError} value={formValues["pickup_date"] || ''} disabled={shouldDisable("pickup_date")} name='pickup_date' fieldName='Pickup Date' onChange={handleInputChange} />
          </div>
        </div>
        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["pickup_time"] || ''} disabled={shouldDisable("pickup_time")} name='pickup_time' fieldName='Pickup Time' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <RadioButtons values={tripType} value={formValues["trip_type"] || ''} disabled={shouldDisable("trip_type")} name='trip_type' fieldName='Trip Type' onChange={handleInputChange} />
          </div>
        </div>


        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={chairType} value={formValues["chair_type"] || ''} disabled={shouldDisable("chair_type")} name='chair_type' fieldName='Chair Type' type='regular' onChange={handleInputChange} />
          </div>
        </div>
      </div>


      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">

        <div className="w-full">
          <div className="sm:col-span-2">
            {/* <Input value={formValues["from"] || ''} name='from' fieldName='From' onChange={handleInputChange} /> */}
            <AddressSearch onError={(e) => setOnError} value={formValues["location"] || ''} disabled={shouldDisable("location")} name='location' fieldName='From' onChange={handleInputChange} />

          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <AddressSearch onError={(e) => setOnError} value={formValues["destination"] || ''} disabled={shouldDisable("destination")} name='destination' fieldName='To' onChange={handleInputChange} />
          </div>
        </div>

      </div>


      <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mb-2">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["patient"] || ''} disabled={shouldDisable("patient")} name='patient' fieldName='PT Name' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["nric"] || ''} disabled={shouldDisable("nric")} name='nric' fieldName='NRIC' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} type='number' value={formValues["body_weight"] || ''} disabled={shouldDisable("body_weight")} name='body_weight' fieldName='Weight (kg)' onChange={handleInputChange} />
          </div>
        </div>


      </div>

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["caller"] || ''} disabled={shouldDisable("caller")} name='caller' fieldName='Caller Name' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["caller_number"] || ''} disabled={shouldDisable("caller_number")} name='caller_number' fieldName='Caller Number' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-5">
        <div className="w-full">
          <div className="sm:col-span-2">
            <RadioButtons values={billType} value={formValues["bill_type"] || ''} disabled={shouldDisable("bill_type")} name='bill_type' fieldName='Bill Type' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      {
        showBilling &&
        <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-3">
          <div className="w-full">
            <div className="sm:col-span-2">
              <BillToOrganisationList values={billToOptions} value={formValues["organisation"] || 's'} disabled={shouldDisable("organisation")} name='organisation' fieldName='Bill To' type='searchable' onChange={handleInputChange} />
            </div>
          </div>

          <div className="w-full">
            <div className="sm:col-span-2">
              <Input onError={(e) => setOnError} value={formValues["job_number"] || ''} disabled={shouldDisable("job_number")} name='job_number' fieldName='Job No' onChange={handleInputChange} />
            </div>
          </div>
        </div>

      }


      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <PriceInput onError={(e) => setOnError(e)} value={formValues["price_details"] || ''} disabled={shouldDisable("price_details")} name='price_details' fieldName='Price Details' onChange={handlePriceChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} disabled value={formValues["price"] || ''} name='price' fieldName='Total Price' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-5">
        <div className="w-full">
          <div className="sm:col-span-2">
            <TextArea onError={(e) => setOnError} rows={3} value={formValues["remarks"] || ''} disabled={shouldDisable("remarks")} name='remarks' fieldName='Remarks' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <TextArea onError={(e) => setOnError} rows={3} value={formValues["chief_complaint"] || ''} disabled={shouldDisable("chief_complaint")} name='chief_complaint' fieldName='Chief Complaint' onChange={handleInputChange} />
          </div>
        </div>

      </div>


      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-5 mt-10">
        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["service_start"] || ''} name='service_start' fieldName='Service Start' onChange={handleServiceStartEndChange} />
          </div>
        </div>
        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["service_end"] || ''} disabled={!isSvcStart} name='service_end' fieldName='Service End' onChange={handleServiceStartEndChange} />
          </div>
        </div>
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["receipt_number"] || ''} name='receipt_number' fieldName='Receipt Number' onChange={handleInputChange} />
          </div>
        </div>

      </div>

    </div>
  )
}