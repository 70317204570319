import React, { useEffect, useContext } from 'react';
import { Card, Dropdown, Button, Modal } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button'
import logo from '../../assets/logo.png';
import AuthContext from '../../context/auth-context'

import axios from 'axios'

export interface ILoginModal {
  openModal: boolean;
  onCloseModal: () => void;
}

export const LoginModal: React.FC<ILoginModal> = (props) => {
  const { openModal, onCloseModal } = props;
  const navigate = useNavigate()

  return (
    <Modal dismissible={false} show={openModal} onClose={() => onCloseModal()}>
      <Modal.Body>
        <h5 className="text-xl font-bold dark:text-black">Please Re-login</h5>
        <p className="text-gray-500 dark:text-gray-400">You have been logged out for security reasons.</p>
        <div className="space-y-6">
          <Login onLogin={() => onCloseModal()} onFailLogin={() => { navigate("/login") }} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

interface ILoginProps {
  onLogin: () => void;
  onFailLogin?: () => void;
}

export const Login: React.FC<ILoginProps> = (props) => {
  const { onLogin, onFailLogin } = props;
  const location = useLocation();
  const navigate = useNavigate()

  const { authStatus, setAuthStatus } = useContext(AuthContext);

  useEffect(() => {
    console.log("hello from login", authStatus)

    // let logout = location?.state?.logout;

    // if (logout === true) {
    //   handleLogout()
    // }

  }, []);



  const handleLogin = (tgUser?: undefined | TelegramUser, id?: string | undefined, simulatedRole?: string | undefined) => {
    const url = `${process.env.REACT_APP_API_URI}auth/login`

    let authBody;
    if (tgUser) {
      authBody = tgUser
    } else {
      authBody = {
        "auth_date": Math.floor(Date.now() / 1000),
        "id": String(id),
        "hash": `${process.env.REACT_APP_API_TESTKEY || ""}`,
        "role": simulatedRole
      }
    }

    axios.post(url, authBody).then((res) => {
      setAuthStatus(true)
      localStorage.setItem("user", JSON.stringify(res.data))
      console.log("user", res.data)
      onLogin()
    }).catch((err) => {
      console.error(err)
      if (onFailLogin)
        onFailLogin()
    });
  }
  return (
    <div className="mt-4 flex space-x-3 lg:mt-6">
      {
        process.env.REACT_APP_TELE_BOT_USERNAME === "ams_dev_bot" &&
        <Button onClick={() => { handleLogin(undefined, "340205508", "Admin") }}>Test Admin</Button>
      }
      {
        process.env.REACT_APP_TELE_BOT_USERNAME === "ams_dev_bot" &&
        <Button onClick={() => { handleLogin(undefined, "340205508", "Ctrl") }}>Test Ctrl</Button>
      }
      {
        process.env.REACT_APP_TELE_BOT_USERNAME === "ams_dev_bot" &&
        <Button onClick={() => { handleLogin(undefined, "6490151419", "Medic") }}>Test Medic</Button>
      }
      {
        process.env.REACT_APP_TELE_BOT_USERNAME === "ams_dev_bot" &&
        <Button onClick={() => { handleLogin(undefined, "6490151419", "Driver") }}>Test Driver</Button>
      }
      <TelegramLoginButton
        botName={process.env.REACT_APP_TELE_BOT_USERNAME || ""}
        dataOnauth={(user: TelegramUser) => handleLogin(user, undefined)}
      />
    </div>
  )
}