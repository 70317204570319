import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import { Case } from '../types'
import { SearchInput } from '../../form-ui'
import { Button, Modal, Table as FbTable } from 'flowbite-react';
import { parseTimestamp } from '../../../shared/utils'
import './styles.css'
import axios from 'axios';


interface IOption {
    value: string;
    label: string;
}

interface IPatientSearchResult {
    [key: string]: Case[];
}


interface AddressSelectorModal {
    openModal: boolean;
    onCloseModal: () => void;
}

export const SearchPatientModal: React.FC<AddressSelectorModal> = ({ openModal, onCloseModal }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [patientName, setPatientName] = useState<string>();
    const [patientSearchResults, setPatientSearchResults] = useState<IPatientSearchResult>();

    useEffect(() => {
        console.log("tripAssignments")
    }, []);

    useEffect(() => {
        if (!patientName) return
        console.log("patientName", patientName)
        getPatientByName(patientName)
    }, [patientName]);

    const handleCloseModal = () => {
        if (onCloseModal) onCloseModal()
    }

    const onClickEdit = (caseData: Case) => {
        const caseId = caseData?.id
        navigate("/cases/edit/" + caseId, { state: { caseData } })
    }

    const onClickDuplicate = (caseData: Case) => {
        navigate("/cases/duplicate", { state: { caseData: _.omit(caseData, ['id']), type: "duplicate" } })
    }

    const renderPatientTable = (patientName: string, caseObj: Case[]) => {
        return (
            caseObj.map((caseObj, index: number) => (
                <FbTable.Row
                    key={index}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                    <FbTable.Cell className="font-medium text-gray-900 dark:text-white py-1">
                        {parseTimestamp(String(caseObj.timestamp)).date}<br />
                        {parseTimestamp(String(caseObj.timestamp)).time}
                    </FbTable.Cell>
                    <FbTable.Cell className="font-medium text-gray-900 dark:text-white py-1">
                        {caseObj.location}
                    </FbTable.Cell>
                    <FbTable.Cell className="font-medium text-gray-900 dark:text-white py-1">
                        {caseObj.destination}
                    </FbTable.Cell>
                    <FbTable.Cell className="font-medium text-gray-900 dark:text-white py-1">
                        Total: ${caseObj.price}<br />
                        {caseObj.price_details}
                    </FbTable.Cell>
                    <FbTable.Cell>
                        <div style={{ marginBottom: "10px" }}>
                            <Button onClick={() => { onClickEdit(caseObj) }}>
                                Edit
                            </Button>
                        </div>
                        <Button onClick={() => { onClickDuplicate(caseObj) }}>
                            Duplicate
                        </Button>
                    </FbTable.Cell>
                </FbTable.Row>
            ))
        )

    }

    const PatientSearchResult: React.FC<{ patientObj: IPatientSearchResult | undefined }> = ({ patientObj }) => {
        if (!patientObj) return null;

        if (patientObj && Object.keys(patientObj).length === 0) return <h5>No results found.</h5>

        return (
            <>
                {Object.keys(patientObj).map((patientName: string, index: number) => (
                    <div key={index} className='patient-row'>
                        <span style={{ marginTop: "20px", fontWeight: "600", fontSize: "20px" }}>{patientName}</span>
                        <FbTable>
                            <FbTable.Head>
                                <FbTable.HeadCell>
                                    Date
                                </FbTable.HeadCell>
                                <FbTable.HeadCell>
                                    Pickup Address
                                </FbTable.HeadCell>
                                <FbTable.HeadCell>
                                    Drop-off Address
                                </FbTable.HeadCell>
                                <FbTable.HeadCell>
                                    Price
                                </FbTable.HeadCell>
                                <FbTable.HeadCell>
                                    <span className="sr-only">
                                        Select
                                    </span>
                                </FbTable.HeadCell>
                            </FbTable.Head>

                            <FbTable.Body className="divide-y">
                                {renderPatientTable(patientName, patientObj[patientName])}
                            </FbTable.Body>
                        </FbTable>
                    </div>
                ))}
            </>
        );
    };

    const getPatientByName = (patientName: string) => {
        const TYPE = "like" // Search patient name by matching
        const url = `${process.env.REACT_APP_API_URI}cases/search?type=${TYPE}&patient=${patientName}`
        axios.get(url).then((res) => {
            setPatientSearchResults(res.data)
        }).catch((err) => {
            console.error("Cannot get patient name", err)
        })
    }

    return (
        <Modal size="5xl" show={openModal} onClose={handleCloseModal}>
            <Modal.Header>Search Patient</Modal.Header>
            <Modal.Body>
                <SearchInput value={patientName} name='search_pt_name' fieldName='Search Patient' placeholder='Patient name' onSearch={(name) => { setPatientName(name) }} />
                <div className="space-y-6" style={{ height: "350px" }}>
                    <PatientSearchResult patientObj={patientSearchResults} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button color="gray" onClick={onCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
