import { Case, Assignment, TripAssignments, InitialTripAssignments } from '../types'

/**
 * 
 * @param tripAssignments Takes in trip assignment as an array of IAssignment 
 * @param outputType Outputs either label or value key of IAssignment
 * @returns Returns an object with the trip as the key, and array of either label or value.
 * Best used to get an array of assignment based on staff's DB ID, or returns an array of names, for assignment msg mapping.
 */
export const processTripAssignments = (tripAssignments: InitialTripAssignments, outputType: "label" | "value") => {
    const tripsData: any = {};
    for (const tripKey in tripAssignments) {
        // If either driverValue or medicValue is not null, add to tripsData
        if (tripAssignments[tripKey] !== null) {
            tripsData[tripKey] = {
                staff: tripAssignments[tripKey].staff.map((tripData) => { return tripData[outputType] }),
                vehicle_id: tripAssignments[tripKey].vehicle?.[outputType] ?? null
            }
        }
    }
    return tripsData
}

/**
 * 
 * @param assignments Takes in assignment key of case object returned by API.
 * @returns Maps and returns an object where trip is the key and the value is an array of IAssignment
 * Best used to take in assignment object returned by API, and mapped into a format used for population and state management of
 * react select widget.
 */
export const initialTripAssignmentsMapper = (assignments: any) => {
    if (!assignments) return {}
    const initialTripAssignments: InitialTripAssignments = {};
    for (const assignment of assignments) {
        const tripKey = assignment.trip;
        if (!initialTripAssignments[tripKey]) {
            initialTripAssignments[tripKey] = {
                staff: [], // Initialize staff as an empty array
                vehicle: null // Initialize vehicle as null or provide a default value
            };
        }

        initialTripAssignments[tripKey].staff.push({
            label: `${assignment.name}`,
            value: assignment.id
        })
        initialTripAssignments[tripKey].vehicle = {
            label: assignment.vehicle_plate,
            value: assignment.vehicle_id
        }
    }
    return initialTripAssignments
}

interface IRenderAssignment {
    assignments: Assignment[] | undefined;
    inlineTrip?: boolean;
    handleClickVehiclePlate: (plate: string) => void;
}

export const RenderAssignment = (props: IRenderAssignment) => {
    const { assignments, inlineTrip, handleClickVehiclePlate } = props;

    const mappedAssignment = initialTripAssignmentsMapper(assignments)
    return <>
        {
            Object.entries(mappedAssignment).map(([key, value]) => {
                const tripAssignment = value.staff.map((staff) => { return staff.label })
                tripAssignment.sort()

                const carPlate = () => {
                    if (value?.vehicle?.label) {
                        return <a style={{ cursor: "pointer" }} className='font-medium text-blue-600 dark:text-blue-500 hover:underline' onClick={(e) => { handleClickVehiclePlate(String(value?.vehicle?.label)) }}>({value?.vehicle?.label})</a>
                        // return <Button color="white" pill size='xs' onClick={handleClickVehiclePlate(value?.vehicle?.label)}>{value?.vehicle?.label}</Button>
                    }
                }

                if (inlineTrip) {
                    return <p><b>Trip {key}:</b> {tripAssignment.join(", ")} {carPlate()}<br /></p>
                }
                return <><b>Trip {key}:</b> {tripAssignment.join(", ")} {carPlate()}<br /></>
            })
        }
    </>
};
