export const AbbreviationMap:Record<string, string>  = {
    "WAREHOUSE": "WAREHSE",
    "VOCATIONAL": "VOC",
    "UPPER": "UPP",
    "UNDER CONSTRUCTION": "U/C",
    "TAMPINES EXPRESSWAY": "TPE",
    "TOWNHOUSE": "TOWNHSE",
    "TANJONG": "TG",
    "TERRACE": "TER",
    "TECHNICAL": "TECH",
    "TOWN COUNCIL": "TC",
    "STATION": "STN",
    "SOUTH": "STH",
    "SAINT": "ST.",
    "STREET": "ST",
    "SQUARE": "SQ",
    "SINGAPORE": "S'PORE",
    "SELETAR EXPRESSWAY": "SLE",
    "SECONDARY": "SEC",
    "SCHOOL": "SCH",
    "SUNGEI": "S",
    "REDEVELOPMENT": "REDEVT",
    "ROAD": "RD",
    "POINT": "PT",
    "PRIMARY": "PRI",
    "PRESBYTERIAN": "PRESBY",
    "POLYCLINIC": "POLY",
    "PLACE": "PL",
    "PARK": "PK",
    "PAN ISLAND EXPRESSWAY": "PIE",
    "PLAYGROUND": "P/G",
    "PULAU": "P",
    "OPEN SPACE": "O/S",
    "NORTH": "NTH",
    "NEIGHBOURHOOD POLICE POSTS": "NPP",
    "NEIGHBOURHOOD POLICE CENTRES": "NPC",
    "NATIONAL": "NATL",
    "MOUNT": "MT",
    "MARKET": "MKT",
    "MASJID": "MJD",
    "MINISTRY": "MIN",
    "METHODIST": "METH",
    "METROPOLITAN": "MET",
    "MARINA COASTAL EXPRESSWAY": "MCE",
    "MANSIONS": "MANS",
    "MANSION": "MAN",
    "MAISONETTES": "MAIS",
    "MAISONETTE": "MAI",
    "LORONG": "LOR",
    "LINK": "LK",
    "LIBRARY": "LIB",
    "KALLANG PAYA LEBAR EXPRESSWAY": "KPE",
    "KILOMETRE": "KM",
    "KRANJI EXPRESSWAY": "KJE",
    "KAMPONG": "KG",
    "JUNIOR": "JNR",
    "JALAN": "JLN",
    "JUNIOR COLLEGES": "JC",
    "INTERNATIONAL": "INTL",
    "INSTITUTION": "INSTN",
    "INSTITUTE": "INST",
    "INDUSTRIAL": "IND",
    "HEIGHTS": "HTS",
    "HOUSE": "HSE",
    "HISTORIC SITE": "HS",
    "HEADQUARTERS": "HQRS",
    "HEADQUARTER": "HQR",
    "HOSPITAL": "HOSP",
    "GROVE": "GR",
    "GOVERNMENT": "GOVT",
    "GARDENS": "GDNS",
    "GARDEN": "GDN",
    "FACTORY": "FTY",
    "FOOD CENTRE": "FC",
    "FOOD BRIDGE": "FB",
    "EXPRESSWAY": "E'WAY",
    "ESTATE": "EST",
    "ELECTRONIC ROAD PRICING": "ERP",
    "ENVIRONMENT": "ENV",
    "ENGINEERING": "ENGRG",
    "EDUCATION": "EDN",
    "EAST COAST EXPRESSWAY": "ECP",
    "DRIVE": "DR",
    "DIVISION": "DIV",
    "DEVELOPMENT": "DEVT",
    "DEPARTMENT": "DEPT",
    "COMMONWEALTH": "C'WEALTH",
    "CENTRAL": "CTRL",
    "CENTRE/APARTMENTS": "CTR/APTS",
    "CENTRE": "CTR",
    "CENTRAL EXPRESSWAY": "CTE",
    "COURT": "CT",
    "CRESCENT": "CRES",
    "COMPLEX": "CPLX",
    "CARPARK": "CP",
    "CONDOMINIUM": "CONDO",
    "CLUBHOUSE": "CLUBHSE",
    "CLOSE": "CL",
    "CINEMAS": "CINES",
    "CINEMA": "CINE",
    "CHAMBERS": "CHBRS",
    "CHURCH": "CH",
    "COMMUNITY CENTRE/CLUB": "CC",
    "CENTRAL BUSINESS DISTRICT": "CBD",
    "CATHEDRAL": "CATH",
    "BUDDHIST": "BUDD",
    "BUKIT": "BT",
    "BRANCH": "BR",
    "BRANCH OFFICE": "BO",
    "BOULEVARD": "BLVD",
    "BLOCK": "BLK",
    "BUILDING": "BLDG",
    "BUKIT TIMAH EXPRESSWAY": "BKE",
    "AYER RAJAH EXPRESSWAY": "AYE",
    "AVENUE": "AVE",
    "APARTMENTS": "APTS",
    "APARTMENT": "APT",
    "ADMINISTRATION": "ADMIN",
    "ASSEMBLY": "ABLY"
  }
  