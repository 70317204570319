import React, { useState, useEffect } from 'react';
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch, PriceInput } from '../components/form-ui'
import { PrisonCaseProps } from '../components/cases/types'

export const PrisonCaseForm = (props: PrisonCaseProps) => {
  const {
    type,
    user,
    formValues,
    handleInputChange,
    setFormValues,
    handlePriceChange,
    onError,
    activeTab,
    btnClicked,
    handleCaseCreate,
    setOnError,
    tripType,
    serviceType,
    chairType,
    billType,
    showBilling,
  } = props;

  useEffect(() => {
    console.log("activetab", activeTab)
    // if (!formValues) return
    if (activeTab !== "prison") return
    setFormValues(
      {
        case_type: "prison",
        bill_type: "bill",
        organisation: "CGH Prison",
        service_type: "a&e"
      }
    )
    // Set default value for service_type
    handleServiceTypeChange("service_type", "a&e")
    // handleInputChange("case_type",undefined)
    // handleInputChange("bill_type",undefined)
    // handleInputChange("organisation",undefined)
    // handleInputChange("case_type", "prison")
    // handleInputChange("bill_type", "bill")
    // handleInputChange("organisation", "CGH Prison")
  }, [activeTab]);

  // useEffect(() => {
  //   // Set default value for service_type
  //   handleServiceTypeChange("service_type", "a&e")
  // }, []);

  const handleServiceTypeChange = (name: string, value: string | undefined) => {
    handleInputChange(name, value)

    if (value && ["a&e", "discharge"].includes(value)) {
      handleInputChange("chair_type", "stretcher")
    }
  }


  return (
    <div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <DateInput helper onError={(e) => setOnError} value={formValues["pickup_date"] || ''} name='pickup_date' fieldName='Pickup Date' onChange={handleInputChange} />
          </div>
        </div>
        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["pickup_time"] || ''} name='pickup_time' fieldName='Pickup Time' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <RadioButtons values={tripType} value={formValues["trip_type"] || ''} name='trip_type' fieldName='Trip Type' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={serviceType} value={formValues["service_type"] || ''} name='service_type' fieldName='Service Type' type='regular' onChange={handleServiceTypeChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={chairType} value={formValues["chair_type"] || ''} name='chair_type' fieldName='Chair Type' type='regular' onChange={handleInputChange} />
          </div>
        </div>
      </div>



      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">

        <div className="w-full">
          <div className="sm:col-span-2">
            {/* <Input value={formValues["from"] || ''} name='from' fieldName='From' onChange={handleInputChange} /> */}
            <AddressSearch onError={(e) => setOnError} value={formValues["location"] || ''} name='location' fieldName='From' onChange={handleInputChange} />

          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <AddressSearch onError={(e) => setOnError} value={formValues["destination"] || ''} name='destination' fieldName='To' onChange={handleInputChange} />
          </div>
        </div>

      </div>


      <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mb-2">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["patient"] || ''} name='patient' fieldName='PT Name' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["nric"] || ''} name='nric' fieldName='Inmate No' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} type='number' value={formValues["body_weight"] || ''} name='body_weight' fieldName='Weight (kg)' onChange={handleInputChange} />
          </div>
        </div>


      </div>

      <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["caller"] || ''} name='caller' fieldName='POC Name' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["caller_number"] || ''} name='caller_number' fieldName='Caller Number' onChange={handleInputChange} />
          </div>
        </div>
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["email"] || ''} name='email' fieldName='POC Email' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      {/* <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-5">
        <div className="w-full">
          <div className="sm:col-span-2">
            <RadioButtons values={[{ value: "bill", label: "Bill" }]} value={formValues["bill_type"] || ''} name='bill_type' fieldName='Bill Type' onChange={handleInputChange} />
          </div>
        </div>
      </div> */}

      {
        showBilling &&
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
          <div className="w-full">
            <div className="sm:col-span-2">
              <Input onError={(e) => setOnError} value={formValues["organisation"] || ''} name='organisation' fieldName='Bill To' onChange={handleInputChange} />
            </div>
          </div>

          <div className="w-full">
            <div className="sm:col-span-2">
              <Input onError={(e) => setOnError} value={formValues["job_number"] || ''} name='job_number' fieldName='Job No' onChange={handleInputChange} />
            </div>
          </div>
        </div>

      }


      {/* <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <PriceInput onError={(e) => setOnError(e)} value={formValues["price_details"] || ''} name='price_details' fieldName='Price Details' onChange={handlePriceChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} disabled value={formValues["price"] || ''} name='price' fieldName='Total Price' onChange={handleInputChange} />
          </div>
        </div>
      </div> */}

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-5">
        <div className="w-full">
          <div className="sm:col-span-2">
            <TextArea onError={(e) => setOnError} rows={3} value={formValues["remarks"] || ''} name='remarks' fieldName='Remarks' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <TextArea onError={(e) => setOnError} rows={3} value={formValues["chief_complaint"] || ''} name='chief_complaint' fieldName='Chief Complaint' onChange={handleInputChange} />
          </div>
        </div>

      </div>

    </div>
  )
}