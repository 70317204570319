import React, { useState, useEffect, ReactNode } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from 'flowbite-react';
import axios from 'axios';
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch } from './components/form-ui'

import { Route, Routes, Link, RouteProps, Navigate, useLocation } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SingleCase from "./pages/single-case"
import SignInPage from "./pages/authentication/login"
import EnrolForm from "./pages/enrol"

import Cases from "./components/cases/cases"
import Patients from "./components/patients/patients"
import Staff from "./components/staff/staff"
import StaffSingle from "./components/staff/staff-single"
import DialysisList from "./components/dialysis/dialysis-list"
import DialysisForm from "./components/dialysis/dialysis"

import Reporting from "./components/reporting"
import Settings from "./components/settings/settings"

import { LoginModal } from "./components/login"

import Menu from './layout/menu';

import AuthContext from './context/auth-context'


interface ProtectedRouteProps {
  allowedRoles?: string[];
  children: React.ReactNode; // Define children as React.ReactNode
}

axios.defaults.withCredentials = true;

function App() {
  const [authStatus, setAuthStatus] = useState<boolean>(localStorage.getItem("user") ? true : false)
  const [user, setUser] = useState<Record<string, string>>()
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)
  // const [authStatus, setAuthStatus] = useState(false)
  const location = useLocation();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        // Do something with the successful response
        return response;
      },
      (error) => {
        // Handle expired token
        if (error.response.status === 403) {
          setShowLoginModal(true)
        }
        // if (error.response) console.error("Axios: ", error.response);
        throw error;
      }
    );
  }, []);


  // Checks and updates location state when page changes.
  useEffect(() => {
    console.log("running")
    if (localStorage.getItem("user")) {
      setAuthStatus(true)
    } else {
      setAuthStatus(false)
    }

  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user") || "")
      setUser(user)
    }
  }, [localStorage.getItem("user")]);

  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles, children }) => {
    if (!authStatus) {
      return <Navigate to="/login" replace />;
    }

    if (allowedRoles && allowedRoles.length > 0) {
      const userRole = String(user?.role)

      if (!allowedRoles.includes(userRole)) {
        return null
      }
    }

    return <>{children}</>;
  };

  const controllersAdminOnly = ["Ctrl", "Admin"]


  return (
    <>
      <Menu />

      <div style={{ marginTop: "50px" }}>
        <AuthContext.Provider value={{ authStatus, setAuthStatus }}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<SignInPage />} />
            <Route path="/enroll-tele" element={<EnrolForm />} />

            <Route path="/staff" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><Staff /></ProtectedRoute>} />
            <Route path="/staff/create" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><StaffSingle /></ProtectedRoute>} />
            <Route path="/staff/edit/:id" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><StaffSingle /></ProtectedRoute>} />

            <Route path="/cases" element={<ProtectedRoute><Cases /></ProtectedRoute>} />
            <Route path="/cases/edit/:id" element={<ProtectedRoute><SingleCase /></ProtectedRoute>} />
            <Route path="/cases/create" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><SingleCase /></ProtectedRoute>} />
            <Route path="/cases/duplicate" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><SingleCase /></ProtectedRoute>} />

            <Route path="/patients" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><Patients /></ProtectedRoute>} />

            <Route path="/dialysis" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><DialysisList /></ProtectedRoute>} />
            <Route path="/dialysis/create" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><DialysisForm /></ProtectedRoute>} />
            <Route path="/dialysis/edit/:id" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><DialysisForm /></ProtectedRoute>} />

            <Route path="/settings" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><Settings /></ProtectedRoute>} />

            <Route path="/reports" element={<ProtectedRoute allowedRoles={controllersAdminOnly}><Reporting /></ProtectedRoute>} />

          </Routes>
        </AuthContext.Provider>
        <LoginModal openModal={showLoginModal} onCloseModal={() => { setShowLoginModal(false) }} />
        <ToastContainer />
      </div>
    </>
  )


}

export default App;
