import React, { useState, useEffect } from 'react';
import { Button, Modal, Table as FbTable } from 'flowbite-react';
import Swal from 'sweetalert2'
import { VehicleLocation } from '../types'

import axios from 'axios';
import L from 'leaflet';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import ambulance_icon from '../../../assets/ambulance-icon.png'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconSize: [60, 60],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: ambulance_icon,
    // shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;



interface SingleVehicleLocation {
    vehiclePlate: string | undefined;
    openModal: boolean;
    onCloseModal: () => void;
}

export const SingleVehicleLocation: React.FC<SingleVehicleLocation> = ({ vehiclePlate, openModal, onCloseModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fieldsDisabled, setFieldsDisabled] = useState<boolean>(false)
    const [vehicleDetail, setVehicleDetail] = useState<VehicleLocation>()

    const [mapElement, setMapElement] = useState(<></>)


    useEffect(() => {
        if (openModal && vehiclePlate) {
            console.log("open car")
            getVehicleLocation(vehiclePlate)
        }
    }, [openModal]);

    const renderMap = (vehicleDetail: VehicleLocation) => {
        const { Latitude, Longitude, Address, Speed, Status } = vehicleDetail
        const coordinates = [parseFloat(Latitude), parseFloat(Longitude)]; // Convert strings to numbers

        const markerMsg = `Speed: ${Speed}km/h\nAddress: ${Address}\nStatus: ${Status}`

        setMapElement(
            <>
                <MapContainer style={{ width: "100%", height: "100%" }} center={[parseFloat(Latitude), parseFloat(Longitude)]} zoom={20} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[parseFloat(Latitude), parseFloat(Longitude)]}>
                        <Popup>
                            {markerMsg}
                        </Popup>
                    </Marker>
                </MapContainer>
            </>
        )
    }

    useEffect(() => {
        if (vehicleDetail) {
            renderMap(vehicleDetail)
        }
    }, [vehicleDetail]);

    const getVehicleLocation = (plate: string) => {
        const url = `${process.env.REACT_APP_API_URI}vehicles/location/${plate}`
        axios.get(url).then((res) => {
            if (res.data.length >= 1) {
                setVehicleDetail(res.data[0])
            }
        }).catch((err) => {
            console.error("Cannot get vehicle location", err)
        })
    }

    return (
        <Modal show={openModal} onClose={onCloseModal}>
            <Modal.Header>Vehicle Location</Modal.Header>
            <Modal.Body>
                <div className="space-y-6" style={{ height: "350px" }}>

                    {mapElement}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={onClickAssign}>
                Confirm and copy
                </Button> */}
                <Button color="gray" onClick={onCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
