import React, { useState, useEffect } from 'react';
import { Button, Label, TextInput, Dropdown, Badge, ToggleSwitch } from 'flowbite-react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { HiPlus, HiOutlineCheckCircle } from 'react-icons/hi';
import { MdOutlinePeople } from 'react-icons/md';
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import { parseTimestamp } from '../../shared/utils'
import { SearchInput, Select } from '../form-ui'
import { Case, Assignment } from './types'
import { Staff } from '../staff/types'
import { Vehicle } from '../vehicle/types'
import { initialTripAssignmentsMapper } from './assign-case/utils'
import { processTripAssignments } from './assign-case/utils'
import { renderMessage } from '../../shared/msg-generator'
import { month, year } from '../../shared/dropdown-constraints'
import { toast } from 'react-toastify';

// Import modals
import { SingleVehicleLocation } from '../vehicle/vehicle-location/single-vehicle'
import { AssignCaseModal } from './assign-case'
import { SearchPatientModal } from './search-patient'

import Table from './table'
import CardListing from './card-listing'
import { setYear } from 'date-fns';


interface IOption {
  value: string;
  label: string;
}

type IViewModes = "table" | "card"

function Cases() {
  const [viewMode, setViewMode] = React.useState<IViewModes>("card")
  const [isCtrl, setIsCtrl] = React.useState<boolean>(false)
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)
  const [user, setUser] = useState<Record<string, string>>()

  const [data, setData] = React.useState<Case[]>([])

  const [vehicleOptions, setVehicleOptions] = useState<IOption[]>()
  const [staffOptions, setStaffOptions] = useState<IOption[]>()

  const [openAssignmentModal, setOpenAssignmentModal] = React.useState<boolean>(false)
  const [openPtSearchModal, setOpenPtSearchModal] = React.useState<boolean>(false)
  const [selectedCase, setSelectedCase] = React.useState<Case | undefined>(undefined)

  // Vehicle Modal Stuff
  const [openVehicleLocationModal, setOpenVehicleLocationModal] = React.useState<boolean>(false)
  const [selectedVehiclePlate, setSelectedVehiclePlate] = useState<string | undefined>(undefined)

  // Selected case
  const [rowSelection, setRowSelection] = useState<string[]>()

  // Filter options
  const [query, setQuery] = React.useState<Record<string, string> | undefined>()
  const [queryDate, setQueryDate] = React.useState<string>("")
  const [search, setSearch] = React.useState<string>("")
  const [queryMonth, setQueryMonth] = React.useState<string>("")
  const [queryYear, setQueryYear] = React.useState<string>("")

  // Statistics
  const [totalCases, setTotalCases] = React.useState<number>(0)
  const [totalCancelledCases, setTotalCancelledCases] = React.useState<number>(0)


  useEffect(() => {
    console.log("select", rowSelection)
  }, [rowSelection]);

  const navigate = useNavigate();

  const toggleViewMode = () => {
    const mode = viewMode === "table" ? "card" : "table"
    setViewMode(mode);
    localStorage.setItem("viewmode", mode)
  };


  const fetchCases = () => {
    const URL = `${process.env.REACT_APP_API_URI}cases`

    return axios.get(URL, {
      params: query
    }).then((res) => {
      return res.data;
    }).catch((err) => {
      console.error(err)
      return []; // Return an empty array in case of error
    });
  }

  const onStatusChange = (status: string, case_id: number | string | number[] | string[] | undefined) => {
    const url = `${process.env.REACT_APP_API_URI}cases/status`
    axios.put(url, { "case_id": case_id, status: status }).then((res) => {
      handleFetchAndSetCases()
      toast.success(`Case status changed to ${status}`)
    }).catch((err) => {
      toast.error(`Cannot change status: ${err.response.data?.error}`)
      console.error(err)
    });
  }

  const fetchVehicles = () => {
    const URL = `${process.env.REACT_APP_API_URI}vehicles`
    return axios.get(URL).then((res) => {
      return res.data
    }).catch(() => { })

  }

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user") || "")
      setUser(user)
    } else {
      setUser(undefined)
      navigate("/login")
    }
  }, [localStorage.getItem("user")]);

  function convertToRequiredFormat(date: string): string {
    const [dd, mm, yy] = date.split('/');
    return `20${yy}-${mm}-${dd}`;
  }

  useEffect(() => {
    if (!user) return
    setIsCtrl(user?.role === "Ctrl")
    setIsAdmin(user?.role === "Admin")

    handleFetchAndSetStaffAndVehicle()
    if (user?.role === "Admin") {
      const currentDate = new Date();
      const year = String(currentDate.getFullYear());
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

      setQueryMonth(month)
      setQueryYear(year)
    } else {
      setQueryDate(parseTimestamp(String(new Date)).date)
    }

  }, [user]);

  // Only controllers sees the table view
  useEffect(() => {
    // Dont change viewmode if it is already saved
    if (localStorage.getItem("viewmode")) return
    if (isCtrl || isAdmin) {
      setViewMode("table")
    } else {
      setViewMode("card")
    }
  }, [isCtrl, isAdmin]);

  // Check saved viewmode
  useEffect(() => {
    const storedViewMode = localStorage?.getItem("viewmode")
    if (!storedViewMode) return
    const allowedModes = ["card", "table"]
    if (!allowedModes.includes(String(storedViewMode))) return

    setViewMode(String(storedViewMode) as IViewModes)
  }, [localStorage.getItem("viewmode")]);

  const handleFetchAndSetCases = () => {
    fetchCases()
      .then(data => {
        setData(data)
        setTotalCases(data.length)
        setTotalCancelledCases(data.filter((obj: Case) => String(obj.status) === "cancel").length)

        console.log("data", data); // Fetched data or [] in case of an error
      });
  }

  const fetchStaff = () => {
    const URL = `${process.env.REACT_APP_API_URI}staff`


    return axios.get(URL).then((res) => {
      return res.data;
    }).catch((err) => {
      console.error(err)
      return []; // Return an empty array in case of error
    });
  }

  const onClickCaseAssignment = (caseData: Case) => {
    setSelectedCase(caseData)
    setOpenAssignmentModal((openAssignmentModal) => !openAssignmentModal)
  }

  const onClickPtSearch = (caseData: Case) => {
    setOpenPtSearchModal((openPtSearchModal) => !openPtSearchModal)
  }

  const onClickReceiptReceived = (selectedCase: string[] | undefined) => {
    if (!selectedCase) return
    onStatusChange("receipt_received", selectedCase)
    handleFetchAndSetCases()
  }

  const handleFetchAndSetStaffAndVehicle = () => {
    fetchStaff()
      .then(data => {
        const mappedStaffOptions = data.map((driver: Staff) => {

          return {
            label: `${driver.name}`,
            value: driver.id
          }
        })
        setStaffOptions(mappedStaffOptions)
      });

    fetchVehicles()
      .then(data => {
        const mappedVehicleOptions = data.map((vehicle: Vehicle) => {
          return {
            label: vehicle.plate,
            value: vehicle.id,
          }
        })
        setVehicleOptions(mappedVehicleOptions)
      }).catch((err) => {
        console.error("Cannot fetch veh", err)
      })
  }

  useEffect(() => {
    if (queryDate === "") return
    setQuery({
      date: queryDate
    })
    setSearch("")
    setQueryYear("")
    setQueryMonth("")
    console.log("date", queryDate)

  }, [queryDate]);

  useEffect(() => {
    if (search === "") return
    setQuery({
      search: search
    })
    setQueryDate("")
    setQueryYear("")
    setQueryMonth("")


  }, [search]);

  useEffect(() => {
    if (queryMonth === "" || queryYear === "") return
    setQuery({
      month: queryMonth,
      year: queryYear
    })
    setSearch("")
    setQueryDate("")
    console.log("queryMonth, queryYear", queryMonth, queryYear)

  }, [queryMonth, queryYear]);

  useEffect(() => {
    if (!query) return
    handleFetchAndSetCases()
  }, [query]);


  useEffect(() => {
    if (search === "") return
    setQuery({
      search: search
    })
    setQueryDate("")
    setQueryYear("")
    setQueryMonth("")


  }, [search]);

  useEffect(() => {
    if (queryMonth === "" || queryYear === "") return
    setQuery({
      month: queryMonth,
      year: queryYear
    })
    setSearch("")
    setQueryDate("")
    console.log("queryMonth, queryYear", queryMonth, queryYear)

  }, [queryMonth, queryYear]);

  useEffect(() => {
    if (!query) return
    handleFetchAndSetCases()
  }, [query]);



  const handleSetQueryDate = (date: string) => {
    setQueryDate(parseTimestamp(String(date)).date)
  }


  const handleReload = () => {
    handleFetchAndSetCases()
  }

  const handleOnCloseAssignmentModal = () => {
    setOpenAssignmentModal((openAssignmentModal) => !openAssignmentModal)
    handleFetchAndSetCases()
  }
  const handleOnClosePtSearchModal = () => {
    setOpenPtSearchModal((openPtSearchModal) => !openPtSearchModal)
  }



  const handleClickVehiclePlate = (plate: string) => {
    setSelectedVehiclePlate(plate)
    setOpenVehicleLocationModal((openVehicleLocationModal) => !openVehicleLocationModal)
  }

  const handleOnCloseVehicleLocationModal = () => {
    setOpenVehicleLocationModal((openVehicleLocationModal) => !openVehicleLocationModal)
    handleFetchAndSetCases()
  }

  const onClickEdit = (caseData: Case) => {
    const caseId = caseData?.uuid
    navigate("/cases/edit/" + caseId, { state: { caseData } })
  }

  const onClickCopy = (caseData: Case) => {
    const assignment = caseData?.assignment
    let renderedMessage;
    if (assignment) {
      if (!assignment) return
      const tripAssignment = initialTripAssignmentsMapper(assignment) || {}
      const tripDataLabelArr = processTripAssignments(tripAssignment, "label")
      renderedMessage = renderMessage(caseData, tripDataLabelArr)
    } else {
      renderedMessage = renderMessage(caseData)
    }
    navigator.clipboard.writeText(renderedMessage).then((r) => { alert("Copied") })
  }



  // useEffect(() => {
  //   console.log("form", formValues)
  // }, [formValues]);
  return (<>
    <div style={{ justifyContent: "space-between", display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1", display: "inline-flex", marginTop: "10px", alignItems: "stretch", gap: "20px" }}>
        <div style={{ justifyContent: "space-between", display: "flex", flexWrap: "wrap" }}>
          {
            (isCtrl || isAdmin) &&
            <div style={{ flex: "1", display: "inline-flex", marginTop: "10px", alignItems: "stretch", gap: "20px" }}>
              <div style={{ margin: "10px" }}>
                <Link to="/cases/create">
                  <Button size="md"><HiPlus className="mr-2 h-5 w-5" /><p>Case</p></Button>
                </Link>
                <div className="flex max-w-md flex-col gap-4 mt-3">
                  <ToggleSwitch style={{ whiteSpace: "nowrap" }} checked={viewMode === "card"} label={`In: ${viewMode} mode`} onChange={toggleViewMode} />
                </div>
              </div>
            </div>
          }
          <div style={{ flex: "1", display: "inline-flex", marginTop: "10px", alignItems: "stretch", gap: "20px" }}>
            <div className="w-50" style={{ margin: "10px" }}>
              <TextInput
                onChange={(e) => { handleSetQueryDate(e.target.value) }}
                value={convertToRequiredFormat(queryDate)}
                id="query_date"
                sizing="sm"
                type="date"
              />
            </div>
          </div>
          {
            (isCtrl || isAdmin) &&
            <div style={{ flex: "1", display: "inline-flex", marginTop: "10px", alignItems: "stretch", gap: "20px" }}>
              <div style={{ margin: "10px", width: "500px", maxWidth: "90vw" }}>
                <SearchInput size='xs' name='search' fieldName='' value={search} onSearch={setSearch} />
              </div>
            </div>
          }
          <div style={{ flex: "1", display: "inline-flex", marginTop: "10px", alignItems: "stretch", gap: "20px" }}>
            <div style={{ margin: "10px", width: "500px", maxWidth: "90vw" }}>
              Active cases: {totalCases - totalCancelledCases}<br />
              Cancelled cases: {totalCancelledCases}
            </div>
          </div>

          {
            isAdmin &&
            <>
              <div style={{ flex: "1", display: "inline-flex", marginTop: "10px", alignItems: "stretch", gap: "20px" }}>
                <div>
                  <Select name='month' fieldName='' values={month} value={queryMonth} type='regular' onChange={(n, v) => { setQueryMonth(String(v)) }} />
                </div>
                <div>
                  <Select name='year' fieldName='' values={year} value={queryYear} type='regular' onChange={(n, v) => { setQueryYear(String(v)) }} />
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <div >
        <div style={{ margin: "10px", display: "flex", justifyContent: 'flex-end', gap: '10px' }}>
          {
            isAdmin &&
            <Button color="failure" onClick={() => { onClickReceiptReceived(rowSelection) }} size="md"><HiOutlineCheckCircle className="mr-2 h-5 w-5" /><p>Receipt Received</p></Button>
          }
          {
            (isCtrl || isAdmin) &&
            <Button color="gray" onClick={onClickPtSearch} size="md"><MdOutlinePeople className="mr-2 h-5 w-5" /><p>Search Patient</p></Button>
          }
        </div>
      </div>


    </div>
    {
      viewMode === "table" ?
        <Table
          data={data}
          staffOptions={staffOptions}
          vehicleOptions={vehicleOptions}
          isAdmin={isAdmin}
          isCtrl={isCtrl}
          selectedCase={selectedCase}
          openPtSearchModal={openPtSearchModal}
          handleOnClosePtSearchModal={handleOnClosePtSearchModal}
          openAssignmentModal={openAssignmentModal}
          handleOnCloseAssignmentModal={handleOnCloseAssignmentModal}
          openVehicleLocationModal={openVehicleLocationModal}
          selectedVehiclePlate={selectedVehiclePlate}
          handleOnCloseVehicleLocationModal={handleOnCloseVehicleLocationModal}
          handleClickVehiclePlate={handleClickVehiclePlate}
          onClickCaseAssignment={onClickCaseAssignment}
          handleFetchAndSetCases={handleFetchAndSetCases}
          onClickEdit={onClickEdit}
          onClickCopy={onClickCopy}
          onStatusChange={onStatusChange}
          onSelectCases={setRowSelection}
        />
        :
        <CardListing
          data={data}
          staffOptions={staffOptions}
          vehicleOptions={vehicleOptions}
          isCtrl={isCtrl}
          isAdmin={isAdmin}
          selectedCase={selectedCase}
          openPtSearchModal={openPtSearchModal}
          handleOnClosePtSearchModal={handleOnClosePtSearchModal}
          openAssignmentModal={openAssignmentModal}
          handleOnCloseAssignmentModal={handleOnCloseAssignmentModal}
          openVehicleLocationModal={openVehicleLocationModal}
          selectedVehiclePlate={selectedVehiclePlate}
          handleOnCloseVehicleLocationModal={handleOnCloseVehicleLocationModal}
          handleClickVehiclePlate={handleClickVehiclePlate}
          onClickCaseAssignment={onClickCaseAssignment}
          handleFetchAndSetCases={handleFetchAndSetCases}
          onClickEdit={onClickEdit}
          onClickCopy={onClickCopy}
          onStatusChange={onStatusChange}
        />

    }
    <SingleVehicleLocation openModal={openVehicleLocationModal} vehiclePlate={selectedVehiclePlate} onCloseModal={handleOnCloseVehicleLocationModal} />
    <AssignCaseModal staffOptions={staffOptions} vehicleOptions={vehicleOptions} openModal={openAssignmentModal} caseData={selectedCase} onAssign={handleOnCloseAssignmentModal} onCloseModal={handleOnCloseAssignmentModal} />
    <SearchPatientModal openModal={openPtSearchModal} onCloseModal={handleOnClosePtSearchModal} />

  </>)
}

export default Cases;
