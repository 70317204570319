import React, { useState, useEffect } from 'react';
import { TextInput } from 'flowbite-react';
import {parse, format} from 'date-fns'
import { Button, Modal, Table, Checkbox, Label } from 'flowbite-react';
import axios from 'axios'
import Selector from 'react-select';
import { Case, TripAssignments, InitialTripAssignments } from '../cases/types'
import { NkfAddresses } from '../dialysis/types'
import { IBillTo } from '../settings/types'
import {AbbreviationMap} from '../../shared/road-abbreviations-acronyms'
import {parseTimestamp} from '../../shared/utils'
import {renderMessage} from '../../shared/msg-generator'
import { HiPlus } from 'react-icons/hi';
import { toast } from 'react-toastify';

import { Table as FbTable } from 'flowbite-react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    SortingState,
    useReactTable,
    getFilteredRowModel,
    FilterFn,
    FilterFns
  } from '@tanstack/react-table'
import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'
  

import './styles.css'


interface BasicInputProps {
    name: string;
    fieldName: string;
    value: string;
    onChange: (name: string, value: string | undefined) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>)=> void;
}

interface ValidateInput {
    regex: RegExp;
    error: string;
}

interface Input extends BasicInputProps {
    type?: "text" | "number";
    customType?: RegExp;
    validate?: ValidateInput;
    disabled?: boolean
    onError?: (error:boolean) => void;
}

interface DaySelector {
    name: string;
    fieldName: string;
    value: string[];
    values: OptionItem[];
    onChange: (name: string, value: string[] | undefined) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>)=> void;
}

interface SearchInput {
    name: string;
    fieldName: string;
    value?: string;
    onChange?: (name: string, value: string | undefined) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>)=> void;
    onSearch?: (value:string)=> void;
    placeholder?:string;
    size?: "xs" | "md" 
}

interface PriceInput {
    name: string;
    disabled?: boolean;
    fieldName: string;
    value: string;
    onChange: (name: string, price_details: string, price: string|undefined) => void;
    onError?: (error:boolean) => void;
}

interface DateTimeInput extends Input {
    helper?: boolean;
}

interface TextArea extends Input {
    customType?: RegExp;
    validate?: ValidateInput;
    rows: number;
}

interface Select {
    unsetOption? : OptionItem;
    values: OptionItem[];
    disabled?: boolean;
    type: "regular" | "searchable";
    value: string; name: string; 
    fieldName: string; 
    onChange: (name: string, value: string | undefined) => void; 
}

interface MultiSelect {
    unsetOption? : OptionItem;
    values: OptionItem[];
    disabled?: boolean;
    value: OptionItem[] | undefined; 
    name: string; 
    fieldName: string; 
    onChange: (name: string, value: OptionItem[] | undefined) => void; 
}

interface OptionItem {
    label: string;
    value: string;

}

interface RadioButtons extends BasicInputProps {
    values: OptionItem[];
    disabled?:boolean | number[];
}

interface AddressSearch extends Input {
    
}

// interface TimeInput {
//     name: string;
//     fieldName: string;
//     value: string;
//     onChange: (name: string, value: string | undefined) => void;
// }

interface AddressSelectorModal {
    addressCollection: OneMapData[] | undefined;
    openModal: boolean;
    onCloseModal: () => void;
    onSelectAddress: (address:OneMapData) => void;
}

interface RepeaterTableColumn {
    type: "dropdown" | "text" | "id",
    dropdownValues?: string[],
    defaultValue?: string,
    name: string,
    fieldName: string
}

interface RepeaterTable {
    name: string;
    fieldName: string;
    columns?: RepeaterTableColumn[]
    values?: Record<string, string>[] | []
    onChange?: (name: string, values: Record<string, string>[] | []) => void;
    // onCreate: (data: Record<string, string>[] | []) => void;
    // onEdit: (data: Record<string, string>[] | []) => void;
}

interface ITable {
    tableValues?: Record<string, string>[]
}

interface FileUpload {
    name: string;
    fieldName: string;
    type: "dropzone" | "regular";
    accept?: string;
    multiple?: boolean;
    uploadedFileName?: string;
    onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DataTableSettings {
    styles?: {
        cell?: React.CSSProperties;
        row?: React.CSSProperties;
    },
    pagination?: {
        enabled?: boolean;
        displayRowCount?: boolean;
    },
    filters?: {
        search?: boolean;
    }
}

interface DataTable<T> {
    columns: ColumnDef<T, T>[]
    data: Record<string, string>[] | []
    headerButtons?: React.ComponentType[];
    buttons?: React.ComponentType[];
    columnType?: T;
    settings?: DataTableSettings;
    // onChange?: (name: string, values: Record<string, string>[] | []) => void;
    // onCreate: (data: Record<string, string>[] | []) => void;
    // onEdit: (data: Record<string, string>[] | []) => void;
}


export const Input: React.FC<Input> = (props, ...restProps) => {
    const { type, name, fieldName, value, customType, validate, disabled, onChange,onBlur } = props
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const re = /^\d+$/;

            if (e.target.value === '' || re.test(e.target.value)) {
                onChange(name, e.target.value);
            }
        } else if (type === undefined && customType) {
            console.log("comes here", customType)
            const re = customType;
            console.log("check", e.target.value === '' || re.test(e.target.value))
            if (e.target.value === '' || re.test(e.target.value)) {
                onChange(name, e.target.value);
            }
        } else {
            onChange(name, e.target.value);
        }
    };

    const handleBlur = (e:any) =>{
        validateInput(e)
        if(props.onBlur){
            props.onBlur(e)
        }
    }

    const validateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (validate) {
            const re = validate.regex;
            if (e.target.value === '' || re.test(e.target.value)) {
                onChange(name, e.target.value);
                setErrorMsg(undefined);
            } else {
                onChange(name, undefined);
                setErrorMsg(validate.error)
            }
        }
    }

    const inputClassNames = `block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 ${errorMsg
        ? "dark:text-white dark:border-red-500 border-red-600 dark:focus:border-red-500 focus:border-red-600"
        : "border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:border-blue-600"
        } appearance-none focus:outline-none focus:ring-0 peer`;

    const labelClassNames = `absolute text-sm ${errorMsg ? "text-red-600 dark:text-red-500" : "text-gray-500 dark:text-gray-400"
        } duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 ${errorMsg ? "peer-focus:px-2" : ""
        } peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 ${errorMsg ? "peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4" : ""
        } left-1`;

    const inputElement = (
        <input
            disabled={disabled}
            type="text"
            value={value}
            onBlur={handleBlur}
            onChange={handleInputChange}
            name={name}
            className={inputClassNames}
            placeholder=" "
        />
    );

    const labelElement = (
        <label
            htmlFor={name}
            className={labelClassNames}
        >
            {fieldName}
        </label>
    );

    const errorMessageElement = errorMsg !== undefined && (
        <p
            id="outlined_error_help"
            className={`mt-2 text-xs ${errorMsg ? "text-red-600 dark:text-red-400" : "text-red-600 dark:text-red-400"
                }`}
        >
            {errorMsg}
        </p>
    );


    return (
        <>
            <div className="relative">
                {inputElement}
                {labelElement}
                {errorMessageElement}
            </div>
        </>
    )
}

export const SearchInput: React.FC<SearchInput> = (props) => {
    const { name, fieldName, value, placeholder, size, onChange, onSearch } = props;
    const [textInputArea, setSearchInputValue] = useState<string>();

    useEffect(() => {
        if (value !== undefined) {
            setSearchInputValue(value)
        }
    }, [value]);

    const sizeBtnPadding = {
        "xs" : "py-0.5",
        "md" : "py-2"
    }

    const sizeInput = {
        "xs" : "42px",
        "md" : "auto"
    }


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        setSearchInputValue(v);
        if (onChange)
            onChange(name, v);
    };

    const onClickSearch = () => {
        if (!onSearch) return
        onSearch(textInputArea || "")
    }

    return (
        <>
            <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{fieldName}</label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input style={{ height : sizeInput[size || "md"]}} value={textInputArea} onChange={(e)=> {handleInputChange(e)}} type="search" id="search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholder} />
                <button type="button" onClick={onClickSearch} className={`text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 ${sizeBtnPadding[size || "md"]} dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}>Search</button>
            </div>
        </>
    )
}

export const TextArea: React.FC<TextArea> = (props) => {
    const { name, fieldName, rows, value, onChange } = props;
    const [textAreaValue, setTextAreaValue] = useState<string>(value);
  
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const v = e.target.value;
      setTextAreaValue(v);
      onChange(name, v); // Call the parent component's onChange callback with the new value
    };
  
    return (
      <>
        <textarea
          onChange={handleInputChange}
          value={value}
          id={name}
          rows={rows}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder={fieldName}
        />
      </>
    );
  };

export const Select: React.FC<Select> = (props, ...restProps) => {
    const { name, fieldName, value, values, disabled, type, onChange } = props;
    const [selectOption, setSelectOption] = useState<string>(value !== "" && value !== undefined ? value : values[0].value );
    const [selectedSelectorOption, setSelectedSelectorOption] = useState<OptionItem | OptionItem[]>();
    useEffect(() => {
        onChange(name, selectOption);
    }, []);


    const handleSelectChange = (selectedValue: string) => {
        setSelectOption(selectedValue)
        onChange(name, selectedValue);
    };

    // Initalise default selector option, if there is one, else pick the 0th index as the default
    useEffect(() => {
        setSelectedSelectorOption(values.filter(( val ) => val.value == value)[0])
    }, [values]);

    // Selector change handler
    const handleSelectorChange = (selectedValue:OptionItem) => {
        onChange(name, String(selectedValue.value));
        setSelectedSelectorOption(selectedValue)
    }

    const selectOptions = values.map((item, index) => (
        <option key={item.value} value={item.value} selected={item.value === value}>{item.label}</option>
    ));

    const selectorTypes:Record<string, any> = {
        regular : 
            <select disabled={disabled} onChange={(e) => handleSelectChange(e.target.value)} value={value} id={name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                {selectOptions}
            </select>,
        searchable : 
            <Selector 
                isDisabled={disabled}
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={selectedSelectorOption}
                value={selectedSelectorOption}
                // isClearable
                // value={value}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                isSearchable
                onChange={(e) => {
                    e && handleSelectorChange(e)
                }}
                options={values}
            />
    }

    return (
        <div>
            <label
                htmlFor={name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {fieldName}
            </label>
            {
                selectorTypes[type || "regular"]
            }
        </div>
    );
};

export const MultiSelect: React.FC<MultiSelect> = (props, ...restProps) => {
    const { name, fieldName, value, values, disabled, onChange } = props;
    const [selectedSelectorOption, setSelectedSelectorOption] = useState<OptionItem[]>();

    const handleSelectorChange = (selectedValue: OptionItem[]) => {
        onChange(name, selectedValue);
    };

    // Update the state when the 'value' prop changes
    // useEffect(() => {
    //     if (value) {
    //         setSelectedSelectorOption(value);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (selectedSelectorOption) {
    //         onChange(name, selectedSelectorOption);
    //         console.log("selectedSelectorOption", selectedSelectorOption)
    //     }
    // }, [selectedSelectorOption]);

    return (
        <>
            <label
                htmlFor={name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {fieldName}
            </label>
            <Selector
                isDisabled={disabled}
                className="basic-single"
                classNamePrefix="select"
                value={value}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                isMulti
                isSearchable
                onChange={(e) => {
                    e && handleSelectorChange(e.map((item) => item));
                }}
                options={values}
            />
        </>
    )
}


export const RadioButtons: React.FC<RadioButtons> = (props, ...restProps) => {
    const { name, fieldName, value, values, disabled, onChange } = props;

    const handleRadioChange = (selectedValue: string) => {
        onChange(name, selectedValue);
    };

    // Check if disabled is an array of num or boolean, returns boolean 
    // disabled -> Can be bool or array of numbers.
    const handleDisable = (disabled:boolean|number[]|undefined, index:number) => {
        if (!disabled) return
        
        if (typeof disabled === 'boolean') {
            return disabled
        } else {
            return disabled.includes(index)
        }
    }

    const radioOptions = values.map((item, index) => (
        <li key={item.value} className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
            <div className="flex items-center pl-3">
                <input
                    id={name + item.value}
                    disabled={handleDisable(disabled, index)}
                    type="radio"
                    value={item.value}
                    name={name}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    checked={item.value === value}
                    onChange={() => handleRadioChange(item.value)}
                />
                <label
                    htmlFor={name + item.value}
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                    {item.label}
                </label>
            </div>
        </li>
    ));

   
    return (
        <div>
            <label
                htmlFor={name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {fieldName}
            </label>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                {radioOptions}
            </ul>
        </div>
    );
};

export const PriceInput: React.FC<PriceInput> = (props) => {
    // Get current GST amount
    let gstAmt = 8;
    if (new Date().getFullYear() === 2024) gstAmt = 9

    const {name, fieldName, value, disabled, onChange, onError} = props;
    const [calculations, setCalculation] = useState<string>(value); 
    const [gstError, setGstError] = useState<boolean>(false); 
    const [grossAmount, setGrossAmount] = useState<number | undefined>(undefined); 
    const [gstAmount, setGstAmount] = useState<number | undefined>(undefined); 
    const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined); 

    useEffect(() => {
        if (!value) return
        setCalculation(value)
    }, [value]);

    const handleCalculation = (value:string, addGst?:boolean) =>{
        let gstInPrice = undefined;
        let workingValues:string[]= [];
        setGstAmount(undefined)

        if (value.includes("+") ) {
            workingValues = value.split("+")
        } else {
            workingValues = [value]
        }
        
        let grossTotal = 0;

        workingValues.forEach((value) => {
            if (value.trim() === "") return;
            
            if (value.includes("(GST)")) {
                gstInPrice = parseFloat(value)
                setGstAmount(gstInPrice)
            } else {
                grossTotal += parseFloat(value)
            }
        })

        setGrossAmount(grossTotal)

        const checkedGst = (grossTotal * gstAmt) / 100
        if (addGst && gstInPrice && checkedGst !==gstInPrice) {
            gstInPrice = checkedGst
        }

        if (gstInPrice && checkedGst !==gstInPrice) {
            // alert("wrong gst amount")
            setGstAmount(undefined)
            setGstError(true)
            gstInPrice = 0
        }

        if (checkedGst ===gstInPrice) {
            setGstError(false)
        }
        
        if (gstInPrice === undefined) {
            gstInPrice = 0
            setGstError(false)
        }
        const total = grossTotal + gstInPrice 

        setTotalPrice(total)

        onChange(name, value,String(total))
    }
    
    const handleCalculationChange = (value: string) => {
        setCalculation(value)
    };

    const handleCalculationsWithGst = () => {
        if(!totalPrice) return

        const gstPayable = (totalPrice * gstAmt) / 100

        const tempCalc = `${value} + ${gstPayable}(GST)`
        setCalculation(tempCalc)
        handleCalculation(tempCalc, true)

    }    

    
    useEffect(() => {
        if (onError) onError(gstError)
      }, [gstError]);


    return (
        <>
            {/* <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label> */}
                {
                    gstError &&
                    <small style={{color : "red"}}>GST Calculation error</small>
                }
            <div className="relative">
                <input value={calculations} onBlur={(e) => handleCalculation(e.target.value)} onChange={(e) => {handleCalculationChange(e.target.value)}} type="search" id="search" className="block w-full p-4 pl-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Price working" required />
                <button disabled={gstError === true || gstAmount !== undefined} onClick={handleCalculationsWithGst} type="button" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">+ {String(gstAmt)}% GST</button>
            </div>
        </>
    )
}


export const TimeInput: React.FC<DateTimeInput> = (props) => {
    const { helper, name, onChange } = props;

    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');

        return {
            actual: `${hours}:${minutes}`,
            formatted: `${hours}${minutes}`
        };
    };

    const generateTimeOptions = () => {
        const currentTime = new Date();
        const options = [
            { label: "Now", value: getCurrentTime().actual },
            { label: `${addMinutes(currentTime, 15).actual}`, value: addMinutes(currentTime, 15).formatted },
            { label: addMinutes(currentTime, 30).actual, value: addMinutes(currentTime, 30).formatted },
        ];
        return options;
    };

    const addMinutes = (time: Date, minutes: number) => {
        const newTime = new Date(time);
        newTime.setTime(newTime.getTime() + minutes * 60 * 1000);

        const formattedHours = String(newTime.getHours()).padStart(2, '0');
        const formattedMinutes = String(newTime.getMinutes()).padStart(2, '0');

        return {
            actual: `${formattedHours}:${formattedMinutes}`,
            formatted: `${formattedHours}:${formattedMinutes}`
        };

    };

    const handleParse = (input:string) => {
        let formattedInput = ""
        if (!input.includes(":") && input.length % 2 === 0 ){
            const middleIndex = input.length / 2
            formattedInput = input.slice(0, middleIndex) + ":" + input.slice(middleIndex);
        } else{
            return
        }
        onChange(name, formattedInput)
    }


    if (helper) {
        return (
            <>
                <Input onBlur={(e) => {handleParse(e.target.value)}} customType={/^\d{0,5}$/} validate={{ regex:/^(?:[01]\d|2[0-3])(?::[0-5]\d)?|[01]\d|2[0-3][0-5]\d$/, error: "Invalid time" }} {...props} />
                <RadioButtons values={generateTimeOptions()} {...props} fieldName='' />
            </>
        )
    }

    return (
        <Input customType={/^\d{0,4}$/} validate={{ regex: /^(?:[01]\d|2[0-3])(?::[0-5]\d)?|[01]\d|2[0-3][0-5]\d$/, error: "Invalid time" }} {...props} />
    )
}

export const DateInput: React.FC<DateTimeInput> = (props) => {
    const { helper, value,onChange } = props;
    const [formattedValue, setFormattedValue] = useState(value);
    const [parsedDate, setParsedDate] = useState<String | undefined>(undefined);

    const getCurrentDate = () => {
        const currentDate = new Date();
        return {
            actual: currentDate,
            formatted: currentDate.toLocaleDateString('en-SG')
        };
    };

    const generateDateOptions = () => {
        const currentDate = new Date();
        const options = [
            { label: "Today", value: getCurrentDate().formatted },
            { label: "Tomorrow", value: addDays(currentDate, 1).toLocaleDateString('en-SG') },
            { label: addDays(currentDate, 2).toLocaleDateString('en-SG'), value: addDays(currentDate, 2).toLocaleDateString('en-SG') },
            { label: addDays(currentDate, 3).toLocaleDateString('en-SG'), value: addDays(currentDate, 3).toLocaleDateString('en-SG') },
            // Add more options as needed
        ];
        return options;
    };

    const addDays = (date: Date, days: number) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    };

    const handleBlur = () => {
        console.log("tryParseDate", props.value)
        // console.log("tryParseDate", Date.parse(props.value))

        // Dont handle empty or undefined
        if (!props.value || props.value === "") return
        const dateValues = props.value.split("/")
        if ([0,1].includes(dateValues.length)) return;


        const day = dateValues[0].padStart(2, '0')
        const month = dateValues[1].padStart(2, '0')
        let year = String(new Date().getFullYear())

        if (dateValues.length === 3) {
            year = dateValues[2].padStart(4, '20')
        }

        const fullDate = `${day}/${month}/${year}`

        console.log("fullDate", fullDate)
        let parsedDate;
        try{
            parsedDate = parse(fullDate, 'dd/mm/yyyy', new Date());
            parsedDate = String(format(parsedDate, 'dd/mm/yyyy'))
            console.log("parsedDate", parsedDate)
            setParsedDate(parsedDate)
            onChange(props.name, parsedDate);
        } catch (error) {
            console.error("invalid dat", error)
        }
    };

    const handleWrapperChange = (name: string, value: string | undefined) => {
        setParsedDate(undefined);
        onChange(name, value);
    };


    if (helper) {
        return (
            <>
                <Input {...props} type='text' onChange={handleWrapperChange} value={parsedDate ? String(parsedDate) : props.value} onBlur={handleBlur} validate={{ regex: /^(?:(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])(?:\/(?:[0-9]{4}|[0-9]{2}))?|(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/([0-9]{4}|[0-9]{2})?|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2]))$/, error: "Invalid date" }}  />
                <RadioButtons {...props} onChange={handleWrapperChange} values={generateDateOptions()} fieldName='' />
            </>
        );
    }

    return (
        <Input type='text' validate={{ regex: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(?:[0-9]{4}|[0-9]{2})$/, error: "Invalid date" }} {...props} />
    );
}

interface OneMapData {
    ADDRESS?:string; 
    BLK_NO?:string; 
    BUILDING?:string; 
    POSTAL?:string; 
    ROAD_NAME?:string; 
    SEARCHVAL?:string; 
}

export const getLocation = (location: string): Promise<OneMapData[]> => {
    const URL = `${process.env.REACT_APP_API_URI}`
    const query = `one-map-search?searchVal=${location}&returnGeom=N&getAddrDetails=Y`
    return axios.get(URL + query, {withCredentials: false}).then((res) => {
        return res.data.results;
      }).catch((err) => {
        console.error(err)
        return []; // Return an empty array in case of error
      });
  };






export const AddressSelectorModal: React.FC<AddressSelectorModal> = ({ addressCollection, openModal, onCloseModal, onSelectAddress  }) => {

    const handleSelectAddress = (address:OneMapData) =>{
        onSelectAddress(address)
        onCloseModal()
    }

    useEffect(() => {
        console.log("addressCollection", addressCollection)
      }, [addressCollection]);
    

    const GenerateRows: React.FC<any> = ({ addressCollection }) => {
        return (
          addressCollection !== undefined &&
          addressCollection.map((data: OneMapData, index: number) => (
            <Table.Row
              key={index}
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell className="font-medium text-gray-900 dark:text-white py-1">
                {data.ADDRESS}
              </Table.Cell>
              <Table.Cell>
                <Button onClick={() => handleSelectAddress(data)}>
                  Select
                </Button>
              </Table.Cell>
            </Table.Row>
          ))
        );
      };
      
      
      

    return (
        <Modal show={openModal} onClose={() => onCloseModal()}>
            <Modal.Header>Select address</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                            Address
                            </Table.HeadCell>
                            <Table.HeadCell>
                            <span className="sr-only">
                                Select
                            </span>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            <GenerateRows addressCollection={addressCollection} />
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button color="gray" onClick={() => onCloseModal()}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const AddressSearch: React.FC<AddressSearch> = (props, ...restProps) => {
    const {name, fieldName, value, onChange} = props;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [addressObj, setAddressObj] = useState<OneMapData | undefined>(undefined); 
    const [addressValue, setAddressValue] = useState<string>(""); 
    const [addressCollection, setAddressCollection] = useState<OneMapData[] | undefined>(undefined); 

    useEffect(() => {
        if (!value) return
        setAddressValue(value)
    }, [value]);

    useEffect(() => {
        if(addressObj) {
            const postal = addressObj?.POSTAL && addressObj?.POSTAL !== "NIL" ? "S" + addressObj?.POSTAL : ""
            const tmpAddr = `${addressObj?.BLK_NO || ""} ${AbbreviationMapper(addressObj?.ROAD_NAME || "")} ${postal}`
            setAddressValue(tmpAddr)
            handleTextAreaChange(tmpAddr)
        }
    }, [addressObj]);

    const AbbreviationMapper = (rdName:string) => {
        const tokenisedRdName = rdName.split(" ")

        for (let i = 0; i < tokenisedRdName.length; i++) {
            const word = tokenisedRdName[i];
            if (AbbreviationMap.hasOwnProperty(word)) {
                tokenisedRdName[i] = AbbreviationMap[word];
            }
          }
          return tokenisedRdName.join(' ');
    }

    
    const onClickSearch = async() =>{
        if (!addressValue) return
        try {
            const address = await getLocation(addressValue)
            console.log("addr", address)
    
            if (address.length === 1) {
                setAddressObj(address[0])
                // setAddressValue(address[0].ADDRESS || "")
            } else if (address.length === 0) {
                alert("No address found.")
            } else {
                setOpenModal((openModal) => !openModal)
                setAddressCollection(address)
            }

        } catch (err) {
            console.error("Cannot search address", err)
        }
    
    }

    const handleTextAreaChange = (value: string) => {
        setAddressValue(value)
        onChange(name, value)
    };

    return (
        <>
            {/* <label htmlFor={name} className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{fieldName}</label> */}
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <textarea rows={3} value={addressValue} name={name} onChange={(e) => handleTextAreaChange(e.target.value)} id={name} className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={fieldName}></textarea>                
                <button type='button' onClick={onClickSearch} className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
            </div>
            <AddressSelectorModal 
                onSelectAddress={(address) => {setAddressObj(address)}}
                addressCollection={addressCollection}
                openModal={openModal}
                onCloseModal={() => setOpenModal((openModal) => !openModal)}
            />
        </>
    )
}


export const getNkfAddresses = (): Promise<NkfAddresses[]> => {
    const URL = `${process.env.REACT_APP_API_URI}`
    const query = `setting`
    return axios.get(URL + query).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
        return [{label : "", value : ""}]; // Return an empty array in case of error
      });
  };


export const NkfAddress: React.FC<Select> = (props) => {
    const { values, type, value, name, fieldName, onChange, ...restProps } = props;
    const [options, setOptions] = useState<OptionItem[]>([{ label: "s", value : "s"}]); 
    
    useEffect(() => {
        getNkfAddresses().then((res:any) => {
            if (!res) return
            
            setOptions(res.map((nkf:NkfAddresses) => {
                return {
                    label: nkf.location,
                    value: nkf.id
                }
            }))
        })
    }, []);

    return  <Select {...props} values={options}  />
}

export const getBillToOrganisation = (): Promise<IBillTo[]> => {
    const URL = `${process.env.REACT_APP_API_URI}`
    const query = `settings/bill_to`
    return axios.get(URL + query).then((res) => {
        return res.data.config;
      }).catch((err) => {
        console.error(err)
        return [{label : "", value : ""}]; // Return an empty array in case of error
      });
  };


export const BillToOrganisationList: React.FC<Select> = (props) => {
    const { values, type, value, name, fieldName, unsetOption, onChange, ...restProps } = props;
    const [options, setOptions] = useState<OptionItem[]>([{ label: "s", value : "s"}]); 
    
    useEffect(() => {
        getBillToOrganisation().then((res:any) => {
            if (!res) return

            let options:OptionItem[] = []
            if (unsetOption) {
                options = [unsetOption]
            }

            options = options.concat(
                res.map((billTo:IBillTo) => {
                    return {
                        label: `${billTo.organisation}`,
                        value: `${billTo.short_code}`
                    }
                })
            )
            
            setOptions(options)
        })
    }, []);

    return  <Select {...props} values={options}  />
}

export const DaySelector: React.FC<DaySelector> = (props) => {
    const { name, fieldName, value, values, onChange } = props;
    const [selectedDays, setSelectedDays] = useState<string[]>(value);
    
    useEffect(() => {
        if (!value) return
        setSelectedDays(value)
    }, [value]);

    const handleCheckboxChange = (selectedValue: string) => {
        const updatedSelectedDays = selectedDays.includes(selectedValue)
          ? selectedDays.filter((day) => day !== selectedValue)
          : [...selectedDays, selectedValue];
    
        setSelectedDays(updatedSelectedDays);
        onChange(fieldName, updatedSelectedDays);
      };
    
      const renderDays = () => {
        return values.map((day) => {
          return (
            <div key={day.value} style={{ width: "fit-content", textAlign: "center" }}>
              <input
                type="checkbox"
                id={day.value}
                checked={selectedDays.includes(day.value)}
                onChange={() => handleCheckboxChange(day.value)}
              />
              <div style={{ display: "block" }}>
                <label htmlFor={day.value}>{day.label}</label>
              </div>
            </div>
          );
        });
      };
    
    return <div style={{ display: "flex", flexWrap : "wrap", gap : "45px"}} className='border border-gray-300 rounded-lg bg-gray-50 p-3'>
        <>{renderDays()}</>
    </div>
}


export const RepeaterTable: React.FC<RepeaterTable> = (props) => {
    const { name, fieldName, values, columns, onChange } = props;
    
    const [showModal, setShowModal] = useState<boolean>(false)
    const [tableValues, setTableValues] = useState<Record<string, string>[] | [] >([])
    const [selectedRow, setSelectedRow] = useState<Record<string, string> | undefined>(undefined)

    interface DataModal {
        show:boolean;
        columns:RepeaterTableColumn[] | undefined;
        values: Record<string, string> | undefined;
        handleCloseDataModal: () => void;
        handleOnCreate: (row: Record<string, string>) => void;
        handleOnEdit: (oldRow: Record<string, string>, row: Record<string, string>) => void;
    }
      
    const onClickCreate = () => {
        setSelectedRow(undefined)
        setShowModal(true)
    }

    const onClickEdit = (row:Record<string, string>) => {
        setSelectedRow(row)
        setShowModal(true)
    }

    const onClickDelete = (row:Record<string, string>) => {
        const updatedTableValues = tableValues.filter(_row => _row !== row);

        // Update the state with the new array
        setTableValues(updatedTableValues);
        toast.success(`Record deleted.`)
    }

    const handleCloseDataModal = () => {
        setShowModal((showModal) => !showModal)
    }

    const handleOnCreate = (row: Record<string, string>) => {
        setTableValues((prevTableValues) => [...prevTableValues, row]);
        handleCloseDataModal()
        toast.success(`Record added.`)
    }

    const handleOnEdit = (oldRow: Record<string, string>, row: Record<string, string>) => {
        const rowIndex = tableValues.findIndex(_row => _row === oldRow);

        if (rowIndex !== -1) {
            // Create a new array with the updated row
            const updatedTableValues = [
            ...tableValues.slice(0, rowIndex),
            { ...oldRow, ...row },
            ...tableValues.slice(rowIndex + 1),
            ];

            // Update the state with the new array
            setTableValues(updatedTableValues);
        } else {
            // Handle the case where the oldRow is not found
            console.error("Old row not found for editing:", oldRow);
        }
        handleCloseDataModal()
        toast.success(`Record modified.`)
    }

    useEffect(() => {
        if (!onChange) return
        onChange(name, tableValues);
    }, [tableValues]);

    useEffect(() => {
        if (!values) return
        setTableValues(values)
    }, [values]);

    

    const DataModal = (props:DataModal) => {
        const {show, columns, values, handleCloseDataModal} = props
        const [rowValues, setRowValues] = useState<Record<string, string>>(values || {});
        
        const handleColumnInputChange = (name: string, value: string | undefined) => {
            const updatedValue = value !== undefined ? value : '';
          
            setRowValues((prevRowValues) => ({
              ...prevRowValues,
              [name]: updatedValue,
            }));
        };
          
    
        return <Modal size="5xl" show={show} onClose={handleCloseDataModal}>
            <Modal.Header>{!values ? "Create" : "Edit"} record</Modal.Header>
            <Modal.Body>
                <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
                    {
                        columns && columns.map((col) => {
                            if (col.type === "id") return
                            return <div className="sm:col-span-2">
                                {
                                    col.type === "text" &&
                                    <Input value={rowValues[col.name] || ''} name={col.name} fieldName={col.fieldName} onChange={handleColumnInputChange} />
                                }
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    !values ?
                    <Button onClick={() => {handleOnCreate(rowValues)}}>
                        Create
                    </Button>
                    :
                    <Button color="gray" onClick={() => {handleOnEdit(values, rowValues)}}>
                        Edit
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    }

    const renderColumns = () => {

        let cols =  columns && columns.map((col:any) => {
            if (col.type === "id") return

            return {
                accessorFn: (row :any) => row[col.name],
                id: col.name,
                enableMultiSort: true,
                cell: (info:any) => <>{info.getValue()}</>,
                header: String(col.fieldName),
                // footer: props => props.column.id,
            }
        })

        cols?.push({
            accessorFn: (row :any) => row.name,
            id: 'name',
            enableMultiSort: false,
            cell: (info:any) => <>
                <div style={{display:"flex", gap: "5px"}}>
                <Button size="xs"  onClick={() => {onClickEdit(info.row.original)}} >
                    Edit
                </Button>
                <Button size="xs" color="failure" onClick={() => {onClickDelete(info.row.original)}}>
                    Delete
                </Button>
                </div>
            </>,
            header: "",
            // footer: props => props.column.id,
        })
        return cols as ColumnDef<any, any>[];
    }
  
    return (
        <>
            <div className="overflow-x-auto">
                <h2>{fieldName}</h2>
                <Button style={{marginTop: "10px"}} size="xs" onClick={onClickCreate}><HiPlus className="mr-2 h-4 w-4" /><p>Create</p></Button>

                    <SortableTable 
                        columns={
                            renderColumns()
                        }
                        data={tableValues}
                        settings={{ 
                            styles : {
                                cell: { padding: "15px 5px", color: "black" }
                            },
                            filters: {search:true}
                        }}
                    />
                    <DataModal show={showModal} columns={columns} handleCloseDataModal={handleCloseDataModal} handleOnCreate={handleOnCreate} handleOnEdit={handleOnEdit} values={selectedRow}  />
            </div>
        </>
    );
  };
  

  export const FileUpload: React.FC<FileUpload> = (props) => {
    const { type, name, fieldName, onUpload, accept, uploadedFileName, ...restProps } = props;
    const [fileName, setFileName] = useState('');
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files were selected
        if (event.target.files && event.target.files.length > 0) {
          // Get the first file and extract the name
          const selectedFile = event.target.files[0];
          setFileName(selectedFile.name);
          onUpload(event)
        } else {
          // No files selected, reset the file name
          setFileName('');
        }
      };
    
    const Regular = () => {
        return (
            <>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Upload file</label>
                <input onChange={handleFileChange} accept={accept} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" />
            </>
        )
    }

    const DropZone = () => {
        return (
            <div className="flex items-center justify-center w-full">
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    {
                        fileName ? 
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Uploaded: {fileName}</span></p>
                        :
                        <>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">Accepts {accept}</p>
                        </>
                    }
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept={accept} />
                </label>
            </div>
        )
    }

    const UploadType = {
        "dropzone" : <DropZone/>,
        "regular" : <Regular/>
    }

    return  UploadType[type]
}

export const DynamicTable: React.FC<ITable> = (props) => {
    const {tableValues} = props
    if (!tableValues) return <></>
    const columns = Object.keys(tableValues[0])

    return (
        <Table style={{marginTop: "10px"}}>
            <Table.Head>
                {
                    columns && columns.map((col) => {
                        return <Table.HeadCell>{col}</Table.HeadCell>
                    })
                }
                <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {
                    Array.isArray(tableValues) && tableValues.map((row) => { 
                        return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        {
                            columns && columns.map((col) => {
                                return <Table.Cell>{row[col]}</Table.Cell>
                            })
                        }
                        {/* <Table.Cell>
                            <a onClick={() => {onClickEdit(row)}} className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                Edit
                            </a>
                            <a onClick={() => {onClickDelete(row)}} className="ml-5 font-medium text-red-600 hover:underline dark:text-cyan-500">
                                Delete
                            </a>
                        </Table.Cell> */}
                    </Table.Row>
                    })
                }                    
            </Table.Body>
        </Table>
    )
}


const SortableTable: React.FC<DataTable<any>> = (props) => {
    const { columns, data, settings, headerButtons} = props
    
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value)
      
        // Store the itemRank info
        addMeta({
          itemRank,
        })
      
        // Return if the item should be filtered in/out
        return itemRank.passed
      }
    const table = useReactTable({
        data,
        columns,
        state: {
          sorting,
          globalFilter
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        enableMultiSort: true,
        debugTable: true
    })

    const pagination = () => {
        if (settings?.pagination?.enabled) {
            return <div className="flex items-center gap-2">
                <Button
                size="xs"
                color="gray"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                >
                {'<<'}
                </Button>
                <Button
                size="xs"
                color="gray"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                >
                {'<'}
                </Button>
        
                <Button
                size="xs"
                color="gray"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                >
                {'>'}
                </Button>
        
                <Button
                size="xs"
                color="gray"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
                >
                {">>"}
                </Button>
                <span className="flex items-center gap-1">
                <div>Page</div>
                <strong>
                    {table.getState().pagination.pageIndex + 1} of{' '}
                    {table.getPageCount()}
                </strong>
                </span>
                <span className="flex items-center gap-1">
                | Go to page:
                <input
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    table.setPageIndex(page)
                    }}
                    className="border p-1 rounded w-16"
                />
                </span>
                <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                    table.setPageSize(Number(e.target.value))
                }}
                >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                    </option>
                ))}
                </select>
            </div> 
            } else {
                return <></>
            }
        }    

      return (
        <div className="p-2">
          <div className="h-2" />
          <div className="flex " style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
            <div style={{ flex: "1", display: "inline-flex" }}>
                {
                    settings?.filters?.search &&
                    <div style={{marginBottom: "10px"}}>
                        <Input value={globalFilter ?? ''} name='search' fieldName='Search' onChange={(n, v) => {setGlobalFilter(String(v))}} />
                    </div>
                }
                {
                headerButtons?.map((component) => {
                    return <div style={{ margin: "10px" }}>
                        {React.isValidElement(component) ? (
                            component
                        ) : (
                            <span>{/* Render a fallback or handle appropriately */}</span>
                        )}
                  </div>
                })
                }
            </div>    
    
          </div>
          <div style={{ overflowX: "scroll" }}>
            <FbTable striped>
                <FbTable.Head>
                {table.getFlatHeaders().map(header => {
                    return (
                    <FbTable.HeadCell key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                        <div
                            {...{
                            className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                            onClick: header.column.getToggleSortingHandler(),
                            }}
                        >
                            {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                            )}
                            {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        )}
                    </FbTable.HeadCell>
                    )
                })}

                </FbTable.Head>
                <FbTable.Body>
                {table
                    .getRowModel()
                    .rows
                    .map(row => {
                    return (
                        <FbTable.Row key={row.id}>
                        {row.getVisibleCells().map(cell => {
                            return (
                            <FbTable.Cell style={settings?.styles?.cell} key={cell.id}>
                                {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                                )}
                            </FbTable.Cell>
                            )
                        })}
                        </FbTable.Row>
                    )
                    })}
                </FbTable.Body>
            </FbTable>
        </div>
        <div className="h-2" />
        {pagination()}
        {
        settings?.pagination?.displayRowCount && <div>{table.getRowModel().rows.length} Rows</div>
        }
        </div>
    )
    
}

/**
 * 
 * Experimental codes
 * 
 */

// Try to parse date with any input
// export const DateInput: React.FC<DateTimeInput> = (props) => {
//     const { helper, onChange, name, fieldName } = props;
//     const [parsedDate, setParsedDate] = useState<Date | undefined>(undefined);
//     const [inputValue, setInputValue] = useState<string>(props.value);

//     const getCurrentDate = () => {
//         const currentDate = new Date();
//         return {
//             actual: currentDate,
//             formatted: currentDate.toLocaleDateString('en-SG')
//         };
//     };

//     const generateDateOptions = () => {
//         const currentDate = new Date();
//         const options = [
//             { label: "Today", value: getCurrentDate().formatted },
//             { label: "Tomorrow", value: addDays(currentDate, 1).toLocaleDateString('en-SG') },
//             { label: addDays(currentDate, 3).toLocaleDateString('en-SG'), value: addDays(currentDate, 3).toLocaleDateString('en-SG') },
//             { label: addDays(currentDate, 4).toLocaleDateString('en-SG'), value: addDays(currentDate, 4).toLocaleDateString('en-SG') },
//             // Add more options as needed
//         ];
//         return options;
//     };

//     const addDays = (date: Date, days: number) => {
//         const newDate = new Date(date);
//         newDate.setDate(newDate.getDate() + days);
//         return newDate;
//     };

//     const isValidDate = (d: Date) => {
//         return d instanceof Date && !isNaN(d.getTime());
//       }

//     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const newValue = event.target.value;
//         setInputValue(newValue);
//         onChange(name, newValue);
//     };

//     const handleWrapperChange = (name: string, value: string | undefined) => {
//         onChange(name, value);
//         setParsedDate(undefined);
//     };



//     const handleBlur = () => {
//         // Sugar.Date.setLocale('en');
//         let tryParseDate = Sugar.Date.create(props.value);
//         console.log("tryParseDate", props.value)
//         console.log("tryParseDate", tryParseDate)
        
    
//         if (tryParseDate instanceof Date && isValidDate(tryParseDate)) {
//             onChange(name, Sugar.Date.format(tryParseDate, '%d/%m/%Y'));
//             setParsedDate(tryParseDate);
//         } else {
//             onChange(name, '');
//             setParsedDate(undefined);
//         }
//     };
    

//     if (helper) {
//         return (
//             <>
//                 <Input {...props} onChange={handleWrapperChange}   type='text' onBlur={handleBlur} value={parsedDate ? String(parsedDate) : props.value} validate={{ regex: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(?:[0-9]{4}|[0-9]{2})$/, error: "Invalid date" }} />
//                 <RadioButtons values={generateDateOptions()} {...props} onChange={handleWrapperChange} />
//             </>
//         );
//     }

//     return (
//         <Input type='text' validate={{ regex: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(?:[0-9]{4}|[0-9]{2})$/, error: "Invalid date" }} {...props} />
//     );

// }
