import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch, NkfAddress, DaySelector } from '../form-ui'
import { renderMessage } from '../../shared/msg-generator'
import { parseTimestamp } from '../../shared/utils'
import { serviceType, chairType, billType, tripType, daysOfWeek, month, year } from '../../shared/dropdown-constraints'
import { NkfAddresses } from './types'

import { RegularCaseForm, PrisonCaseForm } from '../../templates'
import { toast } from 'react-toastify';


import axios from 'axios'
import Swal from 'sweetalert2'

interface OptionItem {
  label: string;
  value: string;

}


function DialysisForm() {
  const navigate = useNavigate();
  const location = useLocation();
  let caseData = location?.state?.dialysisData;
  let type: "create" | "edit" | "duplicate"

  if (location?.state?.type) {
    type = location?.state?.type
  } else {
    type = caseData ? "edit" : "create"
  }


  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [onError, setOnError] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<boolean>(false);

  const [daysSelected, setDaysSelected] = useState<string[]>([]);


  useEffect(() => {
    console.log('daysSelected', daysSelected)
  }, [daysSelected]);

  useEffect(() => {
    console.log('caseData', caseData)
  }, [caseData]);

  const handleDaySelection = (name: string, daysSelected: string[] | undefined) => {
    if (!daysSelected) return
    handleInputChange(name, daysSelected.toString())
  }

  // Initialise formValues if edit
  useEffect(() => {

    // Set default values
    setFormValues(
      {
        ...caseData,
        chair_type: "stretcher",
        nkf_id: "1"
      }
    )

    // Overwrite default values if edit case
    if (caseData) {
      setDaysSelected(caseData.days.split(","))
      setFormValues(
        {
          ...caseData,
          ...(
            caseData.timestamp &&
            {
              pickup_date: parseTimestamp(caseData.timestamp, true).date,
              pickup_time: parseTimestamp(caseData.timestamp, true).time
            }
          )
        }
      )
    }
  }, []);


  useEffect(() => {
    // setMessage(renderMessage(formValues))
  }, [formValues]);


  const handleCaseCreate = () => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}dialysis`
    const message = renderMessage(formValues)
    axios.post(url, formValues).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Dialysis created.',
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
        navigate("/dialysis");
      })

    }).catch((err) => {
      console.error(err)
      const errors = err.response?.data?.errors

      if (errors) {
        console.error(errors)
        Object.keys(errors).forEach((key) => {
          const message = errors[key].join(', ')
          toast.error(`${key}: ${message}`)
        });
        setBtnClicked((btnClicked) => !btnClicked)
      }
    });

  }

  const handleCaseEdit = () => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}dialysis/${caseData.id}`
    axios.put(url, formValues).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Dialysis edited.',
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
        navigate("/dialysis");
      })
    }).catch((err) => {
      console.error(err)
    });

  }

  const handleSubmit = (submitType: string) => {
    if (["create", "duplicate"].includes(submitType)) {
      handleCaseCreate()
    } else {
      handleCaseEdit()
    }

  }

  const handleInputChange = (name: string, value: string | undefined) => {
    // Only set the value in formValues if the value is not empty
    if (value !== undefined) {
      if (value.trim() === '') {
        // Remove the key from formValues if value is empty
        const { [name]: _, ...newFormValues } = formValues;
        setFormValues(newFormValues);
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    }
  };

  const generateTitle = () => {
    if (caseData && type !== "duplicate") {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit Dialysis</h2>
    } else if (!caseData) {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Create new Dialysis</h2>
    } else {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Duplicate Dialysis</h2>
    }
  }

  const handleEnabledToggle = () => {
    if (formValues.enabled === "y") {
      handleInputChange("enabled", "n")
    } else {
      handleInputChange("enabled", "y")
    }
  }

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto lg:py-8" style={{ maxWidth: "1000px" }}>
        {
          generateTitle()
        }
        <form>
          <div>
            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-2">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <Select values={chairType} value={formValues["chair_type"] || ''} name='chair_type' fieldName='Chair Type' type='regular' onChange={handleInputChange} />
                </div>
              </div>
              <div className="w-full">
                <div className="sm:col-span-2">
                  <NkfAddress value={formValues["nkf_id"] || ''} name='nkf_id' fieldName='NKF' values={[]} type='searchable' onChange={handleInputChange} />
                </div>
              </div>
            </div>

            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mb-2">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} value={formValues["patient"] || ''} name='patient' fieldName='PT Name' onChange={handleInputChange} />
                </div>
              </div>

              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} value={formValues["nric"] || ''} name='nric' fieldName='NRIC' onChange={handleInputChange} />
                </div>
              </div>

              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} type='number' value={formValues["body_weight"] || ''} name='body_weight' fieldName='Weight (kg)' onChange={handleInputChange} />
                </div>
              </div>
            </div>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} value={formValues["caller"] || ''} name='caller' fieldName='NOK Name' onChange={handleInputChange} />
                </div>
              </div>

              <div className="w-full">
                <div className="sm:col-span-2">
                  <Input onError={(e) => setOnError} value={formValues["caller_number"] || ''} name='caller_number' fieldName='NOK Number' onChange={handleInputChange} />
                </div>
              </div>

            </div>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-2 mt-10">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <TimeInput onError={(e) => setOnError} helper value={formValues["appt_pickup"] || ''} name='appt_pickup' fieldName='Pickup Time' onChange={handleInputChange} />
                </div>
              </div>

              <div className="w-full">
                <div className="sm:col-span-2">
                  <TimeInput onError={(e) => setOnError} helper value={formValues["nkf_pickup"] || ''} name='nkf_pickup' fieldName='Return Time' onChange={handleInputChange} />
                </div>
              </div>

              <div className="w-full">
                <div className="sm:col-span-2">
                  <AddressSearch onError={(e) => setOnError} value={formValues["location"] || ''} name='location' fieldName='From' onChange={handleInputChange} />
                </div>
              </div>

              <div className="w-full">
                <div className="sm:col-span-2">
                  <TextArea onError={(e) => setOnError} rows={3} value={formValues["remarks"] || ''} name='remarks' fieldName='Remarks' onChange={handleInputChange} />
                </div>
              </div>
            </div>



            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-2 mt-10">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <DaySelector values={daysOfWeek} value={daysSelected} name='days' fieldName='days' onChange={handleDaySelection} />
                </div>
              </div>
            </div>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-2 mt-10">
              <div className="w-full">
                <div className="sm:col-span-2">
                  <div style={{ width: "fit-content", textAlign: "center" }}>
                    <input
                      name='enabled'
                      id='enabled'
                      type="checkbox"
                      checked={formValues?.enabled === "y"}
                      onChange={handleEnabledToggle}
                    />
                    <label htmlFor='enabled' style={{ marginLeft: "20px" }}>Automatically create Dialysis</label>
                  </div>
                </div>
              </div>
            </div>



          </div>

          <div className='flex'>
            <Button disabled={onError || btnClicked} onClick={() => handleSubmit(type)} className="my-4 mr-3">
              {type} Dialysis
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default DialysisForm;
