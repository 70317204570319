import React, { useState, useEffect, HTMLProps } from 'react';
import { Table as FbTable } from 'flowbite-react';
import { Button, Label, TextInput, Dropdown } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import { convertDays } from '../../../shared/utils'
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { SearchInput, Select } from '../../form-ui'
import { Dialysis } from '../types'
import DialysisForm from '../dialysis'
import { month, year } from '../../../shared/dropdown-constraints'

import { HiPlus } from 'react-icons/hi';
import { RiAiGenerate } from "react-icons/ri";
import { Link } from "react-router-dom"

import './styles.css'


import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'


interface IOption {
  value: string;
  label: string;
}

function Table() {
  const rerender = React.useReducer(() => ({}), {})[1]
  const navigate = useNavigate();

  // Hook to get custom Row ID
  const getRowId = (row: any) => {
    return row.id;
  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})
  const [queryMonth, setQueryMonth] = React.useState<string>("")
  const [queryYear, setQueryYear] = React.useState<string>("")

  const [btnClicked, setBtnClicked] = useState<boolean>(false);
  const [onError, setOnError] = useState<boolean>(false);

  // Block off generate cases button if no year and month set
  useEffect(() => {
    setBtnClicked(!queryMonth || !queryYear)
  }, [queryMonth, queryYear]);

  useEffect(() => {
    console.log("rowSelection", rowSelection)
  }, [rowSelection]);

  const handleCreateDialysisCase = () => {
    if (!queryMonth && !queryYear) return
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}dialysis/generate`
    axios.post(url, { dialysis: Object.keys(rowSelection), month: queryMonth, year: queryYear }).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Dialysis case(s) Generated.',
        confirmButtonText: 'Ok'
        // footer: 
      }).then((result) => {
        fetchDialysis()
        setBtnClicked((btnClicked) => !btnClicked)
      })

    }).catch((err) => {
      console.error(err)
      const errors = err.response?.data?.errors

      if (errors) {
        console.error(errors)
        Object.keys(errors).forEach((key) => {
          const message = errors[key].join(', ')
          toast.error(`${key}: ${message}`)
        });
        setBtnClicked((btnClicked) => !btnClicked)
      }
    });

  }


  const onClickEdit = (dialysisData: Dialysis) => {
    const dialysisId = dialysisData?.id
    navigate("/dialysis/edit/" + dialysisId, { state: { dialysisData } })
  }

  const RowCheckbox = ({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    )
  }

  const columns = React.useMemo<ColumnDef<Dialysis>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <RowCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <RowCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        accessorFn: row => row.id,
        id: 'id',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "Dialysis ID",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.patient,
        id: 'name',
        enableMultiSort: true,
        cell: info => <>
          PT: {info.row.original.patient}, ({info.row.original.nric}), {info.row.original.body_weight}kg<br />
          CL: {info.row.original.caller}, {info.row.original.caller_number}<br />
          {info.row.original.location} ➡️ {info.row.original.nkf_address}<br />
          <strong>Appt: {info.row.original.days ? convertDays(info.row.original.days, true) : "Not set"}</strong>
        </>,
        header: "Details",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.nkf_pickup,
        id: 'nkf_pickup',
        enableMultiSort: true,
        cell: info => (
          <>
            Pickup: {info.row.original.appt_pickup}<br />
            NKF Pickup: {info.row.original.nkf_pickup}<br />
          </>
        ),
        header: "Appointment Time",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.dates_created,
        id: 'dates_created',
        enableMultiSort: true,
        cell: info => (
          <>
            {
              info.row.original.dates_created &&
              info.row.original.dates_created.split(",").map((v) => {
                return <><span>{v}</span><br /></>
              })
            }
          </>
        ),
        header: "Created for the months of",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.remarks,
        id: 'remarks',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "Remarks",
        // footer: props => props.column.id,
      },
      {

        header: 'Action',
        accessor: 'action',
        cell: info =>
        // (<button className="btn1" onClick={() => {console.log("info", info.row.original)}}>Details</button>)
        (
          <>
            <div style={{ display: "flex" }}>
              <Button style={{ padding: 0, margin: "1px" }} onClick={() => { onClickEdit(info.row.original) }} className="">
                Edit
              </Button>
            </div>
          </>

        )
        ,
      }
    ],
    [] // Only render on staffOption.
  )

  const [data, setData] = React.useState<Dialysis[]>([])

  const onStatusChange = (status: string, case_id: number | undefined) => {
    const url = `${process.env.REACT_APP_API_URI}cases/status`
    axios.put(url, { "case_id": case_id, status: status }).then((res) => {
      fetchDialysis()
      alert(`Case status changed to ${status}`)
    }).catch((err) => {
      console.error(err)
    });
  }

  const fetchDialysis = () => {
    const URL = `${process.env.REACT_APP_API_URI}dialysis`

    axios.get(URL).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.error(err)
    });
  }

  useEffect(() => {
    console.log(queryYear, queryMonth)
    fetchDialysis()
  }, [queryYear, queryMonth]);


  const table = useReactTable({
    data,
    columns,
    getRowId, // Overwrite TanTable hook to use custom ID (Dialysis ID)
    state: {
      sorting,
      rowSelection
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableMultiSort: true,
    debugTable: true
  })

  return (
    <div className="p-2">
      <div className="h-2" />
      <div className="flex " style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ flex: "1", display: "inline-flex" }}>
          <div style={{ margin: "10px" }}>
            <Link to="/dialysis/create">
              <Button size="md"><HiPlus className="mr-2 h-5 w-5" /><p>Create Dialysis</p></Button>
            </Link>

          </div>
        </div>
        <div style={{ flex: "1" }}>
          <div style={{ margin: "10px", display: "flex", justifyContent: 'flex-end' }}>
            <div style={{ marginRight: "10px" }}>
              <Select name='month' fieldName='' values={month} value={queryMonth} type='regular' onChange={(n, v) => { setQueryMonth(String(v)) }} />
            </div>
            <div>
              <Select name='year' fieldName='' values={year} value={queryYear} type='regular' onChange={(n, v) => { setQueryYear(String(v)) }} />
            </div>
            <div style={{ margin: "10px" }}>
              <Button disabled={onError || btnClicked} onClick={() => { handleCreateDialysisCase() }} color="light" size="md"><RiAiGenerate className="mr-2 h-5 w-5" /><p>Generate Cases</p></Button>
            </div>
          </div>
        </div>


      </div>
      <div style={{ overflowX: "scroll" }}>

        <FbTable striped>
          <FbTable.Head>
            {table.getFlatHeaders().map(header => {
              return (
                <FbTable.HeadCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </FbTable.HeadCell>
              )
            })}

          </FbTable.Head>
          <FbTable.Body>
            {table
              .getRowModel()
              .rows
              .map(row => {
                return (
                  <FbTable.Row key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <FbTable.Cell style={{ padding: "1px", color: "black" }} key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </FbTable.Cell>
                      )
                    })}
                  </FbTable.Row>
                )
              })}
          </FbTable.Body>
        </FbTable>
      </div>
      <div className="h-2" />
    </div>
  )
}

export default Table;