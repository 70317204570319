import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch } from '../components/form-ui'

import SingleCase from "../components/cases/case"


function SingleCasePage() {

  return (
    <SingleCase/>
  );
}

export default SingleCasePage;
