import React, { useState, useEffect } from 'react';
import { Table as FbTable } from 'flowbite-react';
import { Button, Label, TextInput, Dropdown, Badge, Card } from 'flowbite-react';
import { initialTripAssignmentsMapper, RenderAssignment } from '../assign-case/utils'

import { Case, Assignment } from '../types'
import { parseTimestamp } from '../../../shared/utils'
import { CaseBadge, StatusBadge, PaymentTypeBadge } from '../../../shared/badge-generator'
import { CasesProps } from "../types"
// import './styles.css'

const CardListing = (props: CasesProps) => {
  const {
    data,
    staffOptions,
    vehicleOptions,
    isCtrl,
    isAdmin,
    selectedCase,
    openPtSearchModal,
    handleOnClosePtSearchModal,
    openAssignmentModal,
    handleOnCloseAssignmentModal,
    openVehicleLocationModal,
    selectedVehiclePlate,
    handleOnCloseVehicleLocationModal,
    handleClickVehiclePlate,
    onClickCaseAssignment,
    handleFetchAndSetCases,
    onClickEdit,
    onClickCopy
  } = props;

  const [user, setUser] = useState<Record<string, string>>()

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user") || "")
      setUser(user)
    }
  }, [localStorage.getItem("user")]);

  const Cards = () => {

    const compareTimestamps = (a: Case, b: Case) => {
      const timeA = parseTimestamp(String(a.timestamp)).time;
      const timeB = parseTimestamp(String(b.timestamp)).time;

      // Assuming the time format is HH:mm
      return timeB.localeCompare(timeA);
    };

    const sortedData = data.slice().sort(compareTimestamps);

    return <>
      {
        sortedData.map((value, index) => {
          return <Card key={value.id} style={{ margin: "20px" }} className="max-w-xxl">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <strong>{parseTimestamp(String(value?.timestamp)).time}</strong>
              <strong>{value?.trip_type}</strong>
              <CaseBadge status={String(value?.case_type)} />

              <StatusBadge status={String(value?.status)} />
            </div>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              <strong>From:</strong> {value?.location}<br />
              <strong>To:</strong> {value?.destination}
            </p>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              <strong>PT:</strong> {value?.patient}, ({value?.nric}), {value?.body_weight}kg, {value?.chair_type}<br />
              <strong>CL:</strong> {value?.caller}, {value?.caller_number}<br />
            </p>
            {
              value?.bill_type === "bill" &&
              <p className="font-normal text-gray-700 dark:text-gray-400">
                Bill: {value?.organisation}
              </p>
            }
            CC: {value?.chief_complaint}<br />
            Re: {value?.remarks}


            <div style={{ display: 'flex' }}>
              {
                value?.payment_status === "paid" ?
                  <Badge style={{ width: "fit-content" }} color="success" >Paid</Badge>
                  :
                  <Badge style={{ width: "fit-content" }} color="failure" >Unpaid</Badge>
              }
              <p className="font-normal text-gray-700 dark:text-gray-400 ml-5">
                {value?.price_details !== String(value?.price) && `${value?.price_details} = `} <strong>{value?.price ? `$${value?.price}` : "No price"}</strong>
              </p>
            </div>
            <RenderAssignment inlineTrip handleClickVehiclePlate={handleClickVehiclePlate} assignments={value?.assignment} />
            <div className="grid gap-4 grid-cols-3 sm:gap-6 mb-5">
              <Button onClick={() => { onClickEdit(value) }}>
                Edit
              </Button>
              {
                ["Admin", "Ctrl"].includes(String(user?.role)) &&
                <Button onClick={() => { onClickCaseAssignment(value) }}>
                  Assign
                </Button>
              }
              <Button style={{ padding: 0, margin: "1px" }} onClick={() => { onClickCopy(value) }} className="">
                Msg
              </Button>
            </div>


          </Card >
        })
      }
    </>
  }

  return (
    <Cards />
  )
}


export default CardListing;