import { Dropdown, Navbar } from 'flowbite-react';
import React, { useContext, useEffect, useState } from 'react';
import logo from '../assets/logo.png'
import { Route, Routes, Link, NavLink } from "react-router-dom"
import { useNavigate, useLocation } from "react-router-dom";

import AuthContext from '../context/auth-context'

import axios from 'axios'

export default function Menu() {
  const { authStatus, setAuthStatus } = useContext(AuthContext);
  const [user, setUser] = useState<any>()
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user") || "")
      setUser(user)
    } else {
      setUser(undefined)
      // navigate("/login")
    }
  }, [localStorage.getItem("user")]);


  const handleLogout = () => {
    const url = `${process.env.REACT_APP_API_URI}auth/logout`
    axios.post(url).then((res) => {
      localStorage.clear();
      setAuthStatus(false)
      console.log("logged out", res.data)
      navigate("/login")
    }).catch((err) => {
      console.error(err)
    });
  }

  const standardMenu = {
    "Cases": "/cases",
  }

  const menuMap: Record<string, Record<string, string>> = {
    "Medic": {
      ...standardMenu
    },
    "Driver": {
      ...standardMenu
    },
    "Ctrl": {
      ...standardMenu,
      "Dialysis": "/dialysis",
      "Staff": "/staff",
      "Reports": "/reports",
      "Settings": "/settings"
    },
    "Admin": {
      ...standardMenu,
      "Dialysis": "/dialysis",
      "Staff": "/staff",
      "Reports": "/reports",
      "Settings": "/settings"
    },
  }

  const Menu: React.FC = () => {
    if (!user) return <></>
    if (!(user?.role in menuMap)) return <></>;
    console.log("user?.role", user?.role)
    return <>{
      Object.entries(menuMap[String(user?.role)]).map(([label, link]) => (
        <NavLink key={label} to={link}>
          <Navbar.Link>
            {label}
          </Navbar.Link>
        </NavLink>
      ))
    }</>

  }


  // const handleLogout = () => {
  //   navigate("/login", {state : {logout:true}})
  // }
  return (
    user &&
    <Navbar
      fluid
      rounded
      className='fixed top-0 left-0 z-50  w-full'
    >
      <Navbar.Brand>
        <img
          alt="Flowbite React Logo"
          className="mr-3 h-6 sm:h-9"
          src={logo}
        />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          inline
          label={`${user?.first_name || ""} ${user?.last_name || ""}`}
        >
          <Dropdown.Header>
            <span className="block text-sm">
              Hello.
            </span>
            {/* <span className="block truncate text-sm font-medium">
              name@flowbite.com
            </span> */}
          </Dropdown.Header>
          {/* <Dropdown.Item>
            Settings
          </Dropdown.Item> */}
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogout}>
            Sign out
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Menu />
      </Navbar.Collapse>
    </Navbar>
  )
}
