import React, { useState, useEffect } from 'react';
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch, PriceInput, BillToOrganisationList } from '../components/form-ui'
import { shouldDisableBillType } from '../components/cases/utils'
import { RegularCaseProps } from '../components/cases/types'
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";


export const RegularCaseForm = (props: RegularCaseProps) => {
  const {
    type,
    user,
    formValues,
    handleInputChange,
    setFormValues,
    handlePriceChange,
    onError,
    activeTab,
    btnClicked,
    handleCaseCreate,
    setOnError,
    tripType,
    serviceType,
    chairType,
    callType,
    billType,
    showBilling,
  } = props;

  interface OptionItem {
    label: string;
    value: string;

  }
  const [billToOptions, setBillToOptions] = useState<OptionItem[]>([{ label: "s", value: "s" }]);


  const restrictedFieldsMedicDriver = [
    "pickup_date",
    "pickup_time",
    "trip_type",
    "service_type",
    "chair_type",
    "call_type",
    "location",
    "destination",
    "caller",
    "caller_number",
    "organisation"
  ]

  const restrictedFields: Record<string, String[]> = {
    "Admin": [],
    "Ctrl": [],
    "Medic": restrictedFieldsMedicDriver,
    "Driver": restrictedFieldsMedicDriver
  }

  const shouldDisable = (field: string) => {
    if (!user) return
    return restrictedFields[String(user?.role)].includes(field)
  }



  useEffect(() => {
    if (activeTab !== "regular") return
    setFormValues(
      {
        case_type: "regular"
      }
    )

    // Set default value for service_type
    handleServiceTypeChange("service_type", "a&e")
    handleCallTypeChange("call_type", "regular")
    // handleInputChange("case_type", undefined)
    // handleInputChange("case_type", "regular")
  }, [activeTab]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    return {
      actual: currentDate,
      formatted: currentDate.toLocaleDateString('en-SG')
    };
  };

  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, '0');
    const minutes = String(currentTime.getMinutes()).padStart(2, '0');

    return {
      actual: `${hours}:${minutes}`,
      formatted: `${hours}${minutes}`
    };
  };

  useEffect(() => {
  }, []);



  const handleServiceTypeChange = (name: string, value: string | undefined) => {
    handleInputChange(name, value)

    if (value && ["a&e", "discharge"].includes(value)) {
      handleInputChange("chair_type", "stretcher")
    }
  }

  const handleServiceStartEndChange = (name: string, value: string | undefined) => {
    if (value === ":") return
    handleInputChange(name, value)
  }

  const handleCallTypeChange = (name: string, value: string | undefined) => {
    handleInputChange(name, value)

    if (value && ["1777", "dc"].includes(value)) {
      handleInputChange("pickup_date", getCurrentDate().formatted)
      handleInputChange("pickup_time", getCurrentTime().actual)
    }
  }

  return (
    <div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <DateInput helper onError={(e) => setOnError} value={formValues["pickup_date"] || ''} disabled={shouldDisable("pickup_date")} name='pickup_date' fieldName='Pickup Date' onChange={handleInputChange} />
          </div>
        </div>
        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["pickup_time"] || ''} disabled={shouldDisable("pickup_time")} name='pickup_time' fieldName='Pickup Time' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-4 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <RadioButtons values={tripType} value={formValues["trip_type"] || ''} disabled={shouldDisable("trip_type")} name='trip_type' fieldName='Trip Type' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={serviceType} value={formValues["service_type"] || ''} disabled={shouldDisable("service_type")} name='service_type' fieldName='Service Type' type='regular' onChange={handleServiceTypeChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={chairType} value={formValues["chair_type"] || ''} disabled={shouldDisable("chair_type")} name='chair_type' fieldName='Chair Type' type='regular' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Select values={callType} value={formValues["call_type"] || ''} disabled={shouldDisable("call_type")} name='call_type' fieldName='Call Type' type='regular' onChange={handleCallTypeChange} />
          </div>
        </div>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">

        <div className="w-full">
          <div className="sm:col-span-2">
            {/* <Input value={formValues["from"] || ''} name='from' fieldName='From' onChange={handleInputChange} /> */}
            <AddressSearch onError={(e) => setOnError} value={formValues["location"] || ''} disabled={shouldDisable("location")} name='location' fieldName='From' onChange={handleInputChange} />

          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <AddressSearch onError={(e) => setOnError} value={formValues["destination"] || ''} disabled={shouldDisable("destination")} name='destination' fieldName='To' onChange={handleInputChange} />
          </div>
        </div>

      </div>


      <div className="grid gap-4 sm:grid-cols-3 sm:gap-6 mb-2">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["patient"] || ''} disabled={shouldDisable("patient")} name='patient' fieldName='PT Name' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["nric"] || ''} disabled={shouldDisable("nric")} name='nric' fieldName='NRIC' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} type='number' value={formValues["body_weight"] || ''} disabled={shouldDisable("body_weight")} name='body_weight' fieldName='Weight (kg)' onChange={handleInputChange} />
          </div>
        </div>


      </div>

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["caller"] || ''} disabled={shouldDisable("caller")} name='caller' fieldName='Caller Name' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} value={formValues["caller_number"] || ''} disabled={shouldDisable("caller_number")} name='caller_number' fieldName='Caller Number' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-5">
        <div className="w-full">
          <div className="sm:col-span-2">
            <RadioButtons values={billType} value={formValues["bill_type"] || ''} disabled={shouldDisable("bill_type") || shouldDisableBillType(type, user, formValues)} name='bill_type' fieldName='Bill Type' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      {
        showBilling &&
        <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-3">
          <div className="w-full">
            <div className="sm:col-span-2">
              <BillToOrganisationList values={billToOptions} value={formValues["organisation"] || 's'} disabled={shouldDisable("organisation")} name='organisation' fieldName='Bill To' type='searchable' onChange={handleInputChange} />
            </div>
          </div>

          <div className="w-full">
            <div className="sm:col-span-2">
              <Input onError={(e) => setOnError} value={formValues["job_number"] || ''} disabled={shouldDisable("job_number")} name='job_number' fieldName='Job No' onChange={handleInputChange} />
            </div>
          </div>
        </div>

      }


      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-3">
        <div className="w-full">
          <div className="sm:col-span-2">
            <PriceInput onError={(e) => setOnError(e)} value={formValues["price_details"] || ''} disabled={shouldDisable("price_details")} name='price_details' fieldName='Price Details' onChange={handlePriceChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <Input onError={(e) => setOnError} disabled value={formValues["price"] || ''} name='price' fieldName='Total Price' onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-5">
        <div className="w-full">
          <div className="sm:col-span-2">
            <TextArea onError={(e) => setOnError} rows={3} value={formValues["remarks"] || ''} disabled={shouldDisable("remarks")} name='remarks' fieldName='Remarks' onChange={handleInputChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <TextArea onError={(e) => setOnError} rows={3} value={formValues["chief_complaint"] || ''} disabled={shouldDisable("chief_complaint")} name='chief_complaint' fieldName='Chief Complaint' onChange={handleInputChange} />
          </div>
        </div>

      </div>


      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-5 mt-10">
        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["service_start"] || ''} name='service_start' fieldName='Service Start' onChange={handleServiceStartEndChange} />
          </div>
        </div>

        <div className="w-full">
          <div className="sm:col-span-2">
            <TimeInput onError={(e) => setOnError} helper value={formValues["service_end"] || ''} disabled={!formValues?.service_start} name='service_end' fieldName='Service End' onChange={handleServiceStartEndChange} />
          </div>
        </div>
        {
          formValues?.service_end &&
          <div className="w-full">
            <div className="sm:col-span-2">
              <Input onError={(e) => setOnError} value={formValues["receipt_number"] || ''} name='receipt_number' fieldName='Receipt Number' onChange={handleInputChange} />
            </div>
          </div>
        }
        {
          formValues?.payment_id &&
          <div className="w-full">
            <div className="sm:col-span-2">
              <Input onError={(e) => setOnError} value={formValues["payment_id"] || ''} name='payment_id' disabled fieldName='Payment ID' onChange={handleInputChange} />
            </div>
          </div>
        }
      </div>

    </div>

  )
}