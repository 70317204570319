import React from 'react';

type AuthContextType = {
    authStatus: boolean; // Assuming authStatus can be a string or null
    setAuthStatus: React.Dispatch<React.SetStateAction<boolean>>;
  };
  
  const AuthContext = React.createContext<AuthContextType>({
    authStatus: false,
    setAuthStatus: () => null,
  });

export default AuthContext