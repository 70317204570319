import React, { useState, useEffect, HTMLProps } from 'react';
import { Table as FbTable } from 'flowbite-react';
import { Button, Label, TextInput, Dropdown, Badge } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import { HiClipboardCopy, HiUserGroup, HiPencil } from 'react-icons/hi';

import { renderMessage } from '../../../shared/msg-generator'
import { RenderAssignment } from '../assign-case/utils'
import { statusColorMap } from '../../../shared/utils'
import { processTripAssignments } from '../assign-case/utils'
import { Staff } from '../../staff/types'
import { Vehicle } from '../../vehicle/types'

import { Case, Assignment } from '../types'
import { parseTimestamp } from '../../../shared/utils'
import { CaseBadge, StatusBadge, PaymentTypeBadge } from '../../../shared/badge-generator'

import { CasesProps } from "../types"

import './styles.css'



import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'


interface IOption {
  value: string;
  label: string;
}

const Table = (props: CasesProps) => {
  const {
    data,
    staffOptions,
    vehicleOptions,
    isAdmin,
    isCtrl,
    selectedCase,
    openPtSearchModal,
    handleOnClosePtSearchModal,
    openAssignmentModal,
    handleOnCloseAssignmentModal,
    openVehicleLocationModal,
    selectedVehiclePlate,
    handleOnCloseVehicleLocationModal,
    handleClickVehiclePlate,
    onClickCaseAssignment,
    handleFetchAndSetCases,
    onClickEdit,
    onClickCopy,
    onSelectCases,
    onStatusChange
  } = props;
  const rerender = React.useReducer(() => ({}), {})[1]
  const navigate = useNavigate();



  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})

  useEffect(() => {
    console.log("rowSelection", rowSelection)
    const _rowSelection = Object.keys(rowSelection)
    if (onSelectCases) onSelectCases(_rowSelection)
  }, [rowSelection]);

  // Hook to get custom Row ID
  const getRowId = (row: any) => {
    return row.id;
  }

  // Assignment Modal Stuff


  const getStaffById = (id: number) => {
    if (staffOptions) {
      console.log("staffOptions", staffOptions)
      console.log("id", id)
      const filteredData = staffOptions.filter(staff => Number(staff.value) === id)
      console.log("sdsd", filteredData)
      if (filteredData.length === 0) return
      return filteredData[0]?.label
    }
  }

  const columns = React.useMemo<ColumnDef<Case>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          isAdmin &&
          <RowCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          isAdmin &&
          <div className="px-1">
            <RowCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        accessorFn: row => row.timestamp,
        id: 'date',
        enableMultiSort: true,
        cell: info => (<>
          {parseTimestamp(String(info.getValue())).date}<br />
          <small>{info.row.original?.create_by && getStaffById(info.row.original?.create_by)}</small>
        </>),
        header: "Pickup date",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.timestamp,
        id: 'time',
        enableMultiSort: true,
        cell: info => (parseTimestamp(String(info.getValue())).time),
        header: "Pickup time",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.price,
        id: 'price',
        enableMultiSort: true,
        cell: info => (
          <>
            <div style={{ display: "flex" }}>
              <PaymentTypeBadge status={String(info.row.original.bill_type)} />
              <div style={{ marginLeft: "5px" }}>
                {/* {
                  info.row.original?.payment_id ?
                    <Badge style={{ width: "fit-content" }} color="success" >Paid</Badge>
                    :
                    <Badge style={{ width: "fit-content" }} color="failure" >Unpaid</Badge>
                } */}
              </div>
            </div>
            {info.row.original.organisation} {
              info.getValue() && info.getValue() !== "" ?
                <>${info.getValue()}</>
                :
                <>No price</>
            }<br />
            {
              info.row.original?.job_number
            }
          </>
        ),
        header: "Bill To",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.price,
        id: 'price',
        enableMultiSort: true,
        cell: info => (
          <>
            {
              info.row.original?.payment_status === "paid" ?
                <Badge style={{ width: "fit-content" }} color="success" >Paid</Badge>
                :
                <Badge style={{ width: "fit-content" }} color="failure" >Unpaid</Badge>
            }

          </>
        ),
        header: "",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.status,
        id: 'status',
        enableMultiSort: true,
        cell: info => (
          <>
            <Dropdown
              className=''
              placement='right'
              label={<StatusBadge status={String(info.getValue())} />}
              style={{ padding: 0, margin: "1px 0px", backgroundColor: "transparent" }}
            >
              <Dropdown.Item onClick={() => { onStatusChange("created", info.row.original.id) }} >
                Created
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("cancel", info.row.original.id) }} >
                Cancelled
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("on_hold", info.row.original.id) }} >
                On-Hold
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => { onStatusChange("assigned", info.row.original.id) }} >
                Assigned
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("assigned_t1", info.row.original.id) }} >
                Assigned T1
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("assigned_t2", info.row.original.id) }} >
                Assigned  T2
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("pt_reach", info.row.original.id) }} >
                Patient Reached
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("pt_onboard", info.row.original.id) }} >
                Patient Onboard
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("pt_offload", info.row.original.id) }} >
                Patient Offloaded
              </Dropdown.Item> */}
            </Dropdown>
          </>
        ),
        header: "Status",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.trip_type,
        id: 'trip_type',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "Trip Type",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.case_type,
        id: 'case_type',
        enableMultiSort: true,
        cell: info => (
          // <>{info.getValue()}</>
          <CaseBadge status={String(info.getValue())} />
        ),
        header: "Case Type",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.location,
        id: 'location',
        enableMultiSort: true,
        cell: info => (
          <div style={{ backgroundColor: statusColorMap(String(info.row.original.status), String(info.row.original.trip_type), info.row.original.assignment) }}>
            PT: {info.row.original.patient}, ({info.row.original.nric}), {info.row.original.body_weight}kg , {info.row.original?.chair_type}<br />
            CL: {info.row.original.caller}, {info.row.original.caller_number}<br />
            {String(info.getValue())} {" ➡️ "} {info.row.original.destination}<br />

            {/* {info.getValue()} {" ➡️ "} {info.row.original.destination}, {info.row.original.patient}, {info.row.original.nric}, {info.row.original.body_weight}kg, {info.row.original.caller}, {info.row.original.caller_number} */}
            <br />
            CC: {info.row.original.chief_complaint}<br />
            Re: {info.row.original.remarks}
          </div>
        ),
        header: "Case Details",
        // footer: props => props.column.id,
      },
      {
        accessorFn: (row: any) => row.assignment,
        id: 'assignment',
        enableMultiSort: true,
        cell: (info: any) => (
          info.getValue() && <RenderAssignment inlineTrip handleClickVehiclePlate={handleClickVehiclePlate} assignments={info.getValue()} />
        ),
        header: "Assignment",
        // footer: props => props.column.id,
      },
      {

        header: 'Action',
        accessor: 'action',
        cell: info =>
        // (<button className="btn1" onClick={() => {console.log("info", info.row.original)}}>Details</button>)
        (
          <>
            <div style={{ display: "flex" }}>
              <Button style={{ padding: 0, margin: "1px" }} onClick={() => { onClickEdit(info.row.original) }} className="">
                <HiPencil size={20} />
              </Button>
              <Button style={{ padding: 0, margin: "1px" }} onClick={() => { onClickCopy(info.row.original) }} className="">
                <HiClipboardCopy size={20} />
              </Button>
              {
                isCtrl &&
                <Button style={{ padding: 0, margin: "1px" }} className='' onClick={() => {
                  onClickCaseAssignment(info.row.original)
                }}>
                  <HiUserGroup size={20} />
                </Button>

              }
            </div>
          </>

        )
        ,
      }
    ],
    [staffOptions] // Only render on staffOption.
  )







  const RowCheckbox = ({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate])

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    )
  }











  const table = useReactTable({
    data,
    columns,
    getRowId,
    state: {
      sorting,
      rowSelection
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableMultiSort: true,
    debugTable: true
  })

  return (
    <div className="p-2">
      <div className="h-2" />

      <div style={{ overflowX: "scroll" }}>

        <FbTable striped>
          <FbTable.Head>
            {table.getFlatHeaders().map(header => {
              return (
                <FbTable.HeadCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </FbTable.HeadCell>
              )
            })}

          </FbTable.Head>
          <FbTable.Body>
            {table
              .getRowModel()
              .rows
              .map(row => {
                return (
                  <FbTable.Row key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <FbTable.Cell style={{ padding: "1px", color: "black" }} key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </FbTable.Cell>
                      )
                    })}
                  </FbTable.Row>
                )
              })}
          </FbTable.Body>
        </FbTable>
      </div>
      <div className="h-2" />
      {/* <div className="flex items-center gap-2">
        <Button
          size="xs"
          color="gray"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </Button>
        <Button
          size="xs"
          color="gray"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </Button>

        <Button
          size="xs"
          color="gray"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </Button>

        <Button
          size="xs"
          color="gray"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </Button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>{table.getRowModel().rows.length} Rows</div> */}

      {/* Debugger */}
      {/* <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
    </div>
  )
}

export default Table;