
import { Input, Select, RepeaterTable } from '../../form-ui'
import { ISettings, ISetting, ISettingsProps } from '../types'
import { Button } from 'flowbite-react';




const BillToSettings: React.FC<ISettingsProps> = (props) => {
  const { settings, onSettingChange, onSettingSave } = props

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto lg:py-8" style={{ maxWidth: "1000px" }}>
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Bill To</h2>
        <RepeaterTable values={settings["bill_to"]?.config} onChange={onSettingChange} name='bill_to' fieldName='' columns={[
          { type: "text", name: "organisation", fieldName: "Organisation" },
          { type: "text", name: "short_code", fieldName: "Short Code" },
        ]} />

        <Button onClick={onSettingSave} style={{ marginTop: "50px" }}>
          Save
        </Button>
      </div>

    </section>

  );
}

export default BillToSettings;
