import { Case, TripAssignments, InitialTripAssignments } from '../components/cases/types'
import { ServiceTypeMap } from './badge-generator'
import { parseTimestamp } from './utils'



export const renderMessage = (formValues: Case, caseAssignment?: InitialTripAssignments) => {
    const caseUrl = `${process.env.REACT_APP_APP_DOMAIN}cases/edit/` + formValues?.uuid
    const billType = String(formValues.bill_type)
    const serviceType = String(ServiceTypeMap[formValues.service_type]) || ""
    const caseType = formValues.case_type
    const callType = formValues?.call_type

    let formattedPrice = `${formValues?.price_details || ""} = $${formValues?.price || ""}`
    if (formValues?.price == formValues?.price_details && formValues?.price && formValues?.price_details) {
        formattedPrice = `$${formValues?.price}`
    }

    let message = "";

    const fromTime = formValues.timestamp
    console.log("renderMsg", formValues)

    // Handle rendering of assignment (if any)
    if (caseAssignment) {
        message += renderAssignment(caseAssignment)
    }

    // Handle destructuring of "timestamp" datetime into pickup_date and pickup_time
    if (!formValues.pickup_date && !formValues.pickup_time && formValues.timestamp) {
        const parsedDateTime = parseTimestamp(formValues.timestamp)
        formValues.pickup_date = parsedDateTime.date
        formValues.pickup_time = parsedDateTime.time
    }

    // Handle render case
    switch (caseType) {
        case "regular":
            message += renderRegularCaseBody(formValues, formattedPrice, serviceType, billType, callType)
            break;
        case "dialysis":
            message += renderDialysisCaseBody(formValues, serviceType)
            break;
        case "prison":
            message += renderPrisonCaseBody(formValues, formattedPrice, serviceType)
            break;
        default:
            message += renderRegularCaseBody(formValues, formattedPrice, serviceType, billType, callType)
            break;
    }

    message += `\n${caseUrl}`

    return message
}

const renderAssignment = (caseAssignment: InitialTripAssignments) => {
    let message = "";

    if (caseAssignment["2"] && caseAssignment["2"].staff.length !== 0) {
        message += `
${String(caseAssignment["2"].staff.join(", ")) || ""}
Please do rtn trip. First trip done by ${caseAssignment["1"].staff.join(", ") || ""}\n\n`
    }

    if (caseAssignment["1"]) {
        message += `${caseAssignment["1"].staff.join(", ") || ""}\n\n`
    }
    return message
}

const renderRegularCaseBody = (formValues: Case, formattedPrice: string, serviceType: string, billType: string, callType: string | undefined) => {
    let message = "";

    // 1777 and dc dont need to show time 
    const pickUpTimeText = ["1777", "dc"].includes(callType || "") ? callType : `${formValues.pickup_time || ""}hrs`

    message += `${formValues.pickup_date || ""}
${pickUpTimeText}, ${formValues.trip_type || ""}, ${serviceType}
${formValues.location || ""} to ${formValues.destination || ""}
`

    if (billType && ["Paynow", "Cash"].includes(billType)) {
        console.log('this is the new form values', formValues)
        message += `Pt: ${formValues.patient || ""}, ${formValues.nric || ""}, ${formValues.body_weight || ""}kg, ${formValues.chair_type || ""}
Caller: ${formValues.caller || ""}, ${formValues.caller_number || ""}
${formValues.bill_type || ""} ${formattedPrice}
${formValues.job_number || ""}
${formValues.chief_complaint || ""}
${formValues.remarks || ""}`;
    } else {
        // Billing type
        message += `Pt: ${formValues.patient || ""}, ${formValues.nric || ""}, ${formValues.body_weight || ""}kg, ${formValues.chair_type || ""}
Caller: ${formValues.caller || ""}, ${formValues.caller_number || ""}
${formValues.bill_type || ""} ${formValues.organisation || ""}
${formattedPrice}
${formValues.job_number || ""}
${formValues.chief_complaint || ""}
${formValues.remarks || ""}`;
    }
    return message
}

const renderDialysisCaseBody = (formValues: Case, serviceType: string) => {
    let message = "";

    message += `${formValues.pickup_date || ""}
${formValues.pickup_time || ""}hrs ${formValues.trip_type || ""}
${formValues.location || ""} to ${formValues.destination || ""}
Pt: ${formValues.patient || ""}
`
    return message
}


const renderPrisonCaseBody = (formValues: Case, formattedPrice: string, serviceType: string) => {
    let message;
    console.log('this is the new form values', formValues)
    message = `${formValues.pickup_date || ""}, Prison Booking
${formValues.pickup_time || ""}hrs, ${serviceType}, ${formValues.trip_type || ""}
${formValues.location || ""} to ${formValues.destination || ""}
Pt: ${formValues.patient || ""}, ${formValues.body_weight || ""}kg, ${formValues.chair_type || ""}
Inmate No: ${formValues.nric || ""}
Email by: ${formValues.caller || ""}, ${formValues.caller_number || ""}
${formValues.job_number || ""}
${formValues.chief_complaint || ""}
Bill Cgh Prison, don't need to indicate price, issue billing and take back all copies.
${formValues.remarks || ""}`;

    return message
}