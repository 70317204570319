import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';

import Table from './table/table'


function Patients() {


  // useEffect(() => {
  //   console.log("form", formValues)
  // }, [formValues]);
  return (<>
    <Table />
  </>)
}

export default Patients;
