import React, { useState, useEffect } from 'react';
import { Input } from '../../form-ui'
import { Patient } from '../types'
import { Button, Modal, Table as FbTable } from 'flowbite-react';
import { toast } from 'react-toastify';
import axios from 'axios';


interface PatientModal {
    patient?: any;
    openModal: boolean;
    onCloseModal: () => void;
    onSubmit: (patient: any) => void;
}

export const PatientModal: React.FC<PatientModal> = ({ patient, openModal, onCloseModal, onSubmit }) => {
    let type = patient ? "edit" : "create"
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState<{ [key: string]: string }>({});

    const [onError, setOnError] = useState<boolean>(false);
    const [btnClicked, setBtnClicked] = useState<boolean>(false);

    useEffect(() => {
        if (openModal && !patient) {
            setFormValues({})
        }
    }, [openModal]);

    useEffect(() => {
        if (patient) {
            setFormValues({
                ...patient
            })
        }
    }, [patient]);


    const handlePatientCreate = () => {
        setBtnClicked((btnClicked) => !btnClicked)
        const url = `${process.env.REACT_APP_API_URI}patients`
        axios.post(url, formValues).then((res) => {
            toast.success('Patient created')
            const patient = {
                ...formValues,
                id: res.data.patient_id
            }
            onSubmit(patient)
        }).catch((err) => {
            console.error(err)
            const errors = err.response?.data?.errors

            if (errors) {
                console.error(errors)
                Object.keys(errors).forEach((key) => {
                    const message = errors[key].join(', ')
                    toast.error(`${key}: ${message}`)
                });
                setBtnClicked((btnClicked) => !btnClicked)
            }
        });

    }

    const handlePatientEdit = () => {
        const patientId = formValues?.id
        setBtnClicked((btnClicked) => !btnClicked)

        const url = `${process.env.REACT_APP_API_URI}patients/${patientId}`
        axios.put(url, formValues).then((res) => {
            toast.success('Patient edited')
            const patient = {
                ...formValues,
                id: res.data.patient_id
            }
            onSubmit(patient)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setBtnClicked((btnClicked) => !btnClicked)
        });

    }

    const handleSubmit = (submitType: string, copy?: boolean) => {
        if (["create", "duplicate"].includes(submitType)) {
            handlePatientCreate()
        } else {
            handlePatientEdit()
        }

    }
    const handleInputChange = (name: string, value: string | undefined) => {
        // Only set the value in formValues if the value is not empty
        if (value !== undefined) {
            if (value.trim() === '') {
                // Remove the key from formValues if value is empty
                const { [name]: _, ...newFormValues } = formValues;
                setFormValues(newFormValues);
            } else {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                }));
            }
        }
    };

    const handleCloseModal = () => {
        if (onCloseModal) onCloseModal()
        setBtnClicked((btnClicked) => !btnClicked)
    }

    const generateTitle = () => {
        if (patient && type !== "duplicate") {
            return "Edit patient"
        } else if (!patient) {
            return "Create patient"
        }
        return null
    }


    return (
        <Modal show={openModal} onClose={handleCloseModal}>
            <Modal.Header>{generateTitle()}</Modal.Header>
            <Modal.Body>
                <div className="space-y-6" style={{ height: "350px" }}>
                    <div className="py-8 px-4 mx-auto lg:py-8" style={{ maxWidth: "1000px" }}>
                        <form>
                            <div>
                                <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-2">
                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} value={formValues["name"] || ''} name='name' fieldName='PT Name' onChange={handleInputChange} />
                                        </div>
                                    </div>

                                </div>

                                <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-2">
                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} value={formValues["nric"] || ''} name='nric' fieldName='NRIC' onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} type='number' value={formValues["body_weight"] || ''} name='body_weight' fieldName='Weight (kg)' onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mb-2">
                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} value={formValues["caller"] || ''} name='caller' fieldName='Caller Name' onChange={handleInputChange} />
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} value={formValues["caller_number"] || ''} name='caller_number' fieldName='Caller Number' onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>



                                <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-3">
                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} value={formValues["email"] || ''} name='email' fieldName='POC Email' onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mb-3">
                                    <div className="w-full">
                                        <div className="sm:col-span-2">
                                            <Input onError={(e) => setOnError} value={formValues["organisation"] || ''} name='organisation' fieldName='Organisation' onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={onError || btnClicked} onClick={() => handleSubmit(type, false)} >
                    {type} Patient
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
