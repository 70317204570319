import React, { useState, useEffect } from 'react';
import { HiDownload } from 'react-icons/hi';
import { Button, Label, TextInput, Dropdown } from 'flowbite-react';
import { SearchInput, Select, RadioButtons, BillToOrganisationList } from '../form-ui'
import { parseTimestamp } from '../../shared/utils'
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";


const Reporting = () => {
  const [query, setQuery] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const { firstDay, lastDay } = getFirstAndLastDay()
    setQuery(
      {
        ...query,
        from: parseTimestamp(String(firstDay)).date,
        to: parseTimestamp(String(lastDay)).date,
        payment_status: "all",
        receipt_checked: "all",
        case_type: "all",
        organisation: "all"
      }
    )
  }, []);

  // useEffect(() => {
  //   console.log("query", query)
  // }, [query]);


  function getFirstAndLastDay(): { firstDay: string, lastDay: string } {
    const today: Date = new Date();
    const firstDay: Date = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay: Date = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formatDate = (date: Date): string => {
      const day: number = date.getDate();
      const month: number = date.getMonth() + 1;
      const year: number = date.getFullYear();

      const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
      const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;

      return `${year}-${formattedMonth}-${formattedDay}`;
    };

    const firstDayFormatted: string = formatDate(firstDay);
    const lastDayFormatted: string = formatDate(lastDay);

    return { firstDay: firstDayFormatted, lastDay: lastDayFormatted };
  }




  function convertToRequiredFormat(date: string): string {
    const [dd, mm, yy] = date.split('/');
    return `20${yy}-${mm}-${dd}`;
  }

  const getReport = () => {
    const URL = `${process.env.REACT_APP_API_URI}cases/report/download`

    return axios.get(URL, {
      responseType: 'blob',
      params: query
    }).then((res) => {
      const contentDisposition = res.headers?.["content-disposition"] || ""
      console.log("contentDisposition", contentDisposition)
      const filename = contentDisposition.split("filename=")[1]
      console.log("filename", filename)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      return res.data;
    }).catch((err) => {
      console.error(err)
      return []; // Return an empty array in case of error
    });
  }

  const handleInputChange = (name: string, value: string | undefined) => {
    // Only set the value in formValues if the value is not empty
    if (value !== undefined) {
      if (value.trim() === '') {
        // Remove the key from formValues if value is empty
        const { [name]: _, ...newFormValues } = query;
        setQuery(newFormValues);
      } else {
        setQuery((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    }
  };


  return (

    <div className="p-2">
      <div className="h-2" />
      <div className="flex " style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ flex: "1", display: "inline-flex" }}>
          <div style={{ margin: "0 10px " }} className="w-50">
            <div style={{ display: "flex", flexWrap: "wrap", gap: "45px" }} className='border border-gray-300 rounded-lg bg-gray-50 p-3'>
              <div style={{ minWidth: "200px" }} >
                <Label
                  htmlFor="from"
                  value="From"
                />
                <TextInput
                  onChange={(e) => { handleInputChange("from", parseTimestamp(String(e.target.value)).date) }}
                  value={convertToRequiredFormat(query["from"] || "///")}
                  id="from"
                  sizing="sm"
                  type="date"
                />
                <Label
                  htmlFor="to"
                  value="To"
                />
                <TextInput
                  onChange={(e) => { handleInputChange("to", parseTimestamp(String(e.target.value)).date) }}
                  value={convertToRequiredFormat(query["to"] || "///")}
                  id="to"
                  sizing="sm"
                  type="date"
                />
              </div>
              <div style={{ width: "450px" }} >
                <RadioButtons values={[{ value: "all", label: "All" }, { value: "paid", label: "Paid" }, { value: "unpaid", label: "Unpaid" }]} value={query["payment_status"] || ''} name='payment_status' fieldName='Payment Status' onChange={handleInputChange} />
                <RadioButtons values={[{ value: "all", label: "All" }, { value: "true", label: "Yes" }, { value: "false", label: "No" }]} value={query["receipt_checked"] || ''} name='receipt_checked' fieldName='Receipt Checked' onChange={handleInputChange} />
              </div>
              <div style={{ width: "450px" }} >
                <RadioButtons values={[{ value: "all", label: "All" }, { value: "regular", label: "Regular" }, { value: "dialysis", label: "Dialysis" }, { value: "prison", label: "Prison" }]} value={query["case_type"] || ''} name='case_type' fieldName='Case Type' onChange={handleInputChange} />
                <BillToOrganisationList values={[{ label: "s", value: "s" }]} unsetOption={{ label: "All", value: "all" }} value={query["organisation"] || 's'} name='organisation' fieldName='Bill To' type='searchable' onChange={handleInputChange} />
              </div>
              <div style={{ width: "450px" }} >
                <Select name='report_type' fieldName='Report type' values={[{ value: "case_report", label: "Case Report" }, { value: "bill_report", label: "Bill Report" }]} value={query["report_type"]} type='regular' onChange={handleInputChange} />
              </div>
            </div>
          </div>
        </div>
        <Button onClick={() => { getReport() }} size="md"><HiDownload className="mr-2 h-5 w-5" /><p>Generate Report</p></Button>
      </div>
      <div style={{ overflowX: "scroll" }}>

      </div>
      <div className="h-2" />
    </div>
  )
}

export default Reporting;