import React, { useState, useEffect } from 'react';
import { Table as FbTable } from 'flowbite-react';
import { Button, Label, TextInput, Badge } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import { Staff } from '../types'
import { HiPlus } from 'react-icons/hi';
import Swal from 'sweetalert2'

import './styles.css'


import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'


interface IOption {
  value: string;
  label: string;
}




function Table() {
  const rerender = React.useReducer(() => ({}), {})[1]
  const navigate = useNavigate();


  const [sorting, setSorting] = React.useState<SortingState>([])


  const onClickEdit = (staffData: Staff) => {
    const staffId = staffData?.id
    navigate("/staff/edit/" + staffId, { state: { staffData } })
  }

  const onClickCreate = (staffData: Staff) => {
    navigate("/staff/create")
  }

  const deleteStaff = (id: number) => {
    const URL = `${process.env.REACT_APP_API_URI}staff/${String(id)}`

    axios.delete(URL).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Staff deleted.',
        confirmButtonText: 'Ok',
        // footer: 
      }).then((result) => {
        fetchStaff()
      })
    }).catch((err) => {
      console.error(err)
    });
  }

  const onClickDelete = (staffData: Staff) => {
    const staffId = staffData?.id

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStaff(staffId)
      }
    });

  }

  const generateTeleInviteLink = (uuid: string) => {
    const url = `${process.env.REACT_APP_API_URI}staff/telegram/link`
    axios.post(url, { uuid }).then((res) => {
      navigator.clipboard.writeText(res.data?.link).then((res) => {
        Swal.fire({
          icon: 'success',
          title: 'Invite copied.',
          confirmButtonText: 'Ok',
          // footer: 
        }).then((result) => {
        })
      }).catch((err) => { })

    }).catch((err) => {
      console.error(err)
    });
  }



  const columns = React.useMemo<ColumnDef<Staff>[]>(
    () => [
      {
        accessorFn: row => row.name,
        id: 'name',
        enableMultiSort: true,
        cell: info => <>{info.getValue()}</>,
        header: "Name",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.role,
        id: 'nric',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "Role",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.tele_handle,
        id: 'tele_handle',
        enableMultiSort: true,
        cell: info => (
          <>@{info.getValue()}</>
        ),
        header: "Telegram ID",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.tele_id,
        id: 'registered',
        enableMultiSort: true,
        cell: info => (

          info.getValue() ?
            <Badge style={{ width: "fit-content" }} color="success">Registered </Badge>
            :
            <Badge style={{ width: "fit-content" }} color="red">Not registered</Badge>
        ),
        header: "Registered Status",
        // footer: props => props.column.id,
      },
      {

        header: 'Action',
        accessor: 'action',
        cell: info =>
        // (<button className="btn1" onClick={() => {console.log("info", info.row.original)}}>Details</button>)
        (
          <>
            <div style={{ display: "flex" }}>
              <Button style={{ padding: 0, margin: "1px" }} onClick={() => { onClickEdit(info.row.original) }} className="">
                Edit
              </Button>
              {
                !info.row.original.tele_id &&
                <Button style={{ padding: 0, margin: "1px" }} onClick={() => { generateTeleInviteLink(info.row.original.uuid) }} className="">
                  Copy invite
                </Button>
              }
              <Button color='failure' style={{ padding: 0, margin: "1px" }} onClick={() => { onClickDelete(info.row.original) }} className="">
                Delete
              </Button>
            </div>
          </>

        )
        ,
      }
    ],
    [] // Only render on staffOption.
  )

  const [data, setData] = React.useState<Staff[]>([])

  const fetchStaff = () => {
    const URL = `${process.env.REACT_APP_API_URI}staff`

    axios.get(URL).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.error(err)
    });
  }

  useEffect(() => {
    fetchStaff()
  }, []);


  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    enableMultiSort: true,
    debugTable: true
  })

  return (
    <div className="p-2">
      <div className="h-2" />
      <div className="flex " style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ flex: "1", display: "inline-flex" }}>
          <div style={{ margin: "10px" }}>
            <Button size="md" onClick={onClickCreate}><HiPlus className="mr-2 h-5 w-5" /><p>Create Staff</p></Button>
          </div>
        </div>
        {/* <div style={{ flex: "1" }}>
          <div style={{ margin: "10px", display: "flex", justifyContent: 'flex-end' }}>
            <Button color="gray" onClick={onClickPtSearch} size="md"><MdOutlinePeople className="mr-2 h-5 w-5" /><p>Search Patient</p></Button>
          </div>
        </div> */}


      </div>
      <div style={{ overflowX: "scroll" }}>

        <FbTable striped>
          <FbTable.Head>
            {table.getFlatHeaders().map(header => {
              return (
                <FbTable.HeadCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </FbTable.HeadCell>
              )
            })}

          </FbTable.Head>
          <FbTable.Body>
            {table
              .getRowModel()
              .rows
              .map(row => {
                return (
                  <FbTable.Row key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <FbTable.Cell style={{ padding: "1px", color: "black" }} key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </FbTable.Cell>
                      )
                    })}
                  </FbTable.Row>
                )
              })}
          </FbTable.Body>
        </FbTable>
      </div>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <Button
          size="xs"
          color="gray"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </Button>
        <Button
          size="xs"
          color="gray"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </Button>

        <Button
          size="xs"
          color="gray"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </Button>

        <Button
          size="xs"
          color="gray"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </Button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>{table.getRowModel().rows.length} Rows</div>

      {/* Debugger */}
      {/* <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
    </div>
  )
}

export default Table;