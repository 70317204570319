import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';

import Table from './table/table'


function Dialysis() {

  return (<>
    <Table />
  </>)
}

export default Dialysis;
