import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Case } from './types'
import { Input, TextArea, TimeInput, DateInput, RadioButtons, Select, AddressSearch, PriceInput } from '../form-ui'
import { AssignCaseModal } from './assign-case'
import { renderMessage } from '../../shared/msg-generator'
import { parseTimestamp, cleanEmptyStrObj } from '../../shared/utils'
import { serviceType, chairType, billType, tripType, callType } from '../../shared/dropdown-constraints'
import _ from "lodash";

import { RegularCaseForm, PrisonCaseForm, DialysisCaseForm } from '../../templates'
import { toast } from 'react-toastify';
import { Staff } from '../staff/types'
import { Vehicle } from '../vehicle/types'


import axios from 'axios'
import Swal from 'sweetalert2'

interface IOption {
  value: string;
  label: string;
}

function SingleCase() {
  const navigate = useNavigate();
  const location = useLocation();
  let caseData = location?.state?.caseData;

  const [type, setType] = useState<"create" | "edit" | "duplicate">(location?.state?.type ? location?.state?.type : caseData ? "edit" : "create");
  const [activeTab, setActiveTab] = useState<"regular" | "prison" | "dialysis">(caseData?.case_type || 'regular');
  const [orderUuid, setOrderUuid] = useState<string>();

  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [message, setMessage] = useState('');
  const [user, setUser] = useState<Record<string, string>>()
  const [showBilling, setShowBilling] = useState<boolean>(false);
  const [onError, setOnError] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<boolean>(false);

  // For assignment Modal
  const [assignment, setAssignment] = useState<Record<string, unknown>>()
  const [openAssignmentModal, setOpenAssignmentModal] = React.useState<boolean>(false)
  const [selectedCase, setSelectedCase] = React.useState<Case | undefined>(undefined)
  const [vehicleOptions, setVehicleOptions] = useState<IOption[]>()
  const [staffOptions, setStaffOptions] = useState<IOption[]>()



  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user") || "")
      setUser(user)
    } else {
      setUser(undefined)
      navigate("/login")
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    const path = location.pathname.split('/')
    let _orderUuid = ""
    if (path[2] === "edit" && path[3].length === 32) {
      _orderUuid = path[3]
      setOrderUuid(_orderUuid)
      console.log("path[3]", path[3])
    }

    console.log("path", path)
    console.log("orderUuid", _orderUuid)


    if (location?.state?.type) {
      setType(location?.state?.type)
    } else if (caseData) {
      setType("edit")
    } else {
      // Check if URI contains UUID
      // If URI contains "edit" and is a UUID 
      if (_orderUuid) {
        getCaseData(_orderUuid)
        setType("edit")
      } else {
        // Create case
        setType("create")
      }
    }
  }, []);


  useEffect(() => {
    console.log('activeTab', activeTab)
  }, [activeTab]);

  const getCaseData = (uuid: string) => {
    const URL = `${process.env.REACT_APP_API_URI}cases/${uuid}`

    axios.get(URL).then((res) => {
      const data = res.data

      // Initalise formvalues
      setFormValues(
        {
          ...data,
          ...(
            data.timestamp &&
            {
              pickup_date: parseTimestamp(data.timestamp, true).date,
              pickup_time: parseTimestamp(data.timestamp, true).time
            }
          )
        }
      )
    }).catch((err) => {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: 'Cannot open case.',
        confirmButtonText: 'Ok',
        html: `<div>You are not authorised to open this order.</div>`,
        // footer: 
      }).then((result) => {
        navigate("/cases");
      })
    });

  }


  // Initialise formValues if edit
  useEffect(() => {

    handleFetchAndSetStaffAndVehicle()

    // Set default values
    setFormValues(
      {
        ...caseData,
        trip_type: "1w",
        service_type: "a&e",
        chair_type: "stretcher",
        case_type: "regular"
      }
    )

    // Overwrite default values if edit case
    if (caseData) {
      setFormValues(
        {
          ...caseData,
          ...(
            caseData.timestamp &&
            {
              pickup_date: parseTimestamp(caseData.timestamp, true).date,
              pickup_time: parseTimestamp(caseData.timestamp, true).time
            }
          )
        }
      )
    }
  }, []);

  useEffect(() => {
    // setMessage(renderMessage(formValues))
  }, [formValues]);

  const handlePaymentBtn = (formValues: any, copy?: boolean) => {
    const uuid = formValues?.uuid
    const amount = formValues?.price
    const patientName = formValues?.patient
    if (!uuid || !amount) return

    const request = {
      "redirect_uri": window.location.href,
      "amount": amount,
      "pt_name": patientName
    }

    const url = `${process.env.REACT_APP_API_URI}payment/${uuid}`
    axios.post(url, request).then((res) => {
      const url = res.data?.url
      if (copy) {
        navigator.clipboard.writeText(url).then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Payment link copied.',
            confirmButtonText: 'Ok',
          }).then((result) => {
          })
        }).catch((err) => { })
      } else {
        window.open(url, '_blank', 'noreferrer');
      }
    }).catch((err) => {
      console.error(err)
      toast.error(`Unable to get payment link`)
    });
  }

  const PaymentButton = (user: any, type: string, formValues: any) => {

    let buttonText = "Make payment (Paynow)"
    let isDisabled = false

    if (type === "edit" && formValues?.payment_status === "paid" && formValues?.payment_id) {
      <>
        <Button color="failure" disabled onClick={() => handlePaymentBtn(formValues)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
          Paid via Paynow
        </Button>
      </>
    }

    if (type === "edit" && formValues?.payment_status === "paid") {
      return <></>
    }

    if (formValues?.bill_type !== "paynow" || !formValues?.uuid || !formValues?.price || formValues?.price == 0 || (formValues?.status !== "trip2_end" && formValues?.trip_type === "2w")) {
      return <></>
    }

    return <>
      <Button color="failure" disabled={isDisabled} onClick={() => handlePaymentBtn(formValues)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
        {buttonText}
      </Button>
      {
        user?.role === "Ctrl" &&
        <Button color="failure" disabled={isDisabled} onClick={() => handlePaymentBtn(formValues, true)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
          Copy payment link
        </Button>
      }
    </>
  }

  const onStatusChange = async (status: string, case_id: number | undefined, formValues: any) => {
    if (!formValues?.uuid) return

    // Change formValues status
    handleInputChange("status", status)

    const editCaseUrl = `${process.env.REACT_APP_API_URI}cases`
    const message = renderMessage(formValues)
    const uuid = formValues?.uuid

    const changeStatusUrl = `${process.env.REACT_APP_API_URI}cases/status`

    try {
      await editCase(editCaseUrl, uuid, formValues, message)
      axios.put(changeStatusUrl, { "case_id": case_id, status: status }).then((res) => {
        Swal.fire({
          icon: 'success',
          title: 'Trip status updated.',
          confirmButtonText: 'Ok',
          html: `<div>${message}</div>`,
          // footer: 
        }).then((result) => {
        })
      }).catch((err) => {
        console.error(err)
      });
    } catch (err) {
      console.error(err)
    }

  }

  const handleCaseAssign = (caseId: any) => {
    if (assignment && caseId) {
      const _assignment = _.cloneDeep(assignment)
      _assignment.case_id = caseId
      const url = `${process.env.REACT_APP_API_URI}cases/assignment`

      axios.put(url, _assignment).then((res) => { }).catch((err) => { })
    }
  }


  const handleCaseCreate = async (assign?: boolean, copy?: boolean) => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}cases`

    // Reassign new constant
    let newFormValues = _.cloneDeep(formValues);

    // Special handling for duplicate case
    if (type === "duplicate") {
      newFormValues = cleanEmptyStrObj(formValues)
      newFormValues = _.omit(newFormValues, ["timestamp", "create_on", "create_by", "update_on", "update_by"])
    }

    // Assign a case UUID
    newFormValues["uuid"] = uuidv4().replace(/-/g, '');

    const message = renderMessage(newFormValues)

    axios.post(url, { data: newFormValues, message: message }).then((res) => {
      handleCaseAssign(res.data?.case_id)

      if (assign) {
        onClickCaseAssignment({
          ...newFormValues,
          id: res.data?.case_id
        })
      } else {
        Swal.fire('Case created', '', 'success')
        if (copy) {
          navigator.clipboard.writeText(message).then((r) => { })
        }
        navigate("/cases");
      }
    }).catch((err) => {
      console.error(err)
      const errors = err.response?.data?.errors

      if (errors) {
        console.error(errors)
        Object.keys(errors).forEach((key) => {
          const message = errors[key].join(', ')
          toast.error(`${key}: ${message}`)
        });
        setBtnClicked((btnClicked) => !btnClicked)
      }
    });

  }

  const editCase = (url: string, uuid: string, formValues: any, message: string) => {
    return axios.put(`${url}/${uuid}`, { data: formValues, message: message })
  }

  const handleCaseEdit = (copy?: boolean) => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}cases`
    const message = renderMessage(formValues)
    const uuid = formValues?.uuid
    editCase(url, uuid, formValues, message).then((res) => {
      handleCaseAssign(res.data?.case_id)
      if (copy) {
        navigator.clipboard.writeText(message).then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Case edited and copied.',
            confirmButtonText: 'Ok',
            html: `<div>${message}</div>`,
            // footer: 
          }).then((result) => {
            navigate("/cases");
          })
        }).catch((err) => { })
      } else {
        Swal.fire('Case edited', '', 'success')
        navigate("/cases");
      }
    }).catch((err) => {
      console.error(err)
    });

  }

  const handleSubmit = (submitType: string, assign?: boolean, copy?: boolean) => {
    if (["create", "duplicate"].includes(submitType)) {
      handleCaseCreate(assign, copy)
    } else {
      handleCaseEdit(true)
    }
  }


  const handleInputChange = (name: string, value: string | undefined) => {
    // Only set the value in formValues if the value is not empty
    if (value !== undefined) {
      if (value.trim() === '') {
        // Remove the key from formValues if value is empty
        const { [name]: _, ...newFormValues } = formValues;
        setFormValues(newFormValues);
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    }
  };

  const handlePriceChange = (name: string, price_details: string, price: string | undefined) => {
    console.log("price_details", price_details)
    console.log("price", price)
    handleInputChange("price_details", price_details)
    handleInputChange("price", price)
  }

  const fetchStaff = () => {
    const URL = `${process.env.REACT_APP_API_URI}staff`


    return axios.get(URL).then((res) => {
      return res.data;
    }).catch((err) => {
      console.error(err)
      return []; // Return an empty array in case of error
    });
  }

  const fetchVehicles = () => {
    const URL = `${process.env.REACT_APP_API_URI}vehicles`
    return axios.get(URL).then((res) => {
      return res.data
    }).catch(() => { })

  }

  const handleFetchAndSetStaffAndVehicle = () => {
    fetchStaff()
      .then(data => {
        const mappedStaffOptions = data.map((driver: Staff) => {

          return {
            label: `${driver.name}`,
            value: driver.id
          }
        })
        setStaffOptions(mappedStaffOptions)
      });

    fetchVehicles()
      .then(data => {
        const mappedVehicleOptions = data.map((vehicle: Vehicle) => {
          return {
            label: vehicle.plate,
            value: vehicle.id,
          }
        })
        setVehicleOptions(mappedVehicleOptions)
      }).catch((err) => {
        console.error("Cannot fetch veh", err)
      })
  }

  const onClickCaseAssignment = (caseData: Case) => {
    setSelectedCase(caseData)
    setOpenAssignmentModal((openAssignmentModal) => !openAssignmentModal)
  }

  const handleAssignment = (assignmentObj: any) => {
    setAssignment(assignmentObj)
    setOpenAssignmentModal((openAssignmentModal) => !openAssignmentModal)

    if (type === "create") {
      navigate("/cases");
    }
  }

  const generateTitle = () => {
    if (caseData && type !== "duplicate") {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit case</h2>
    } else if (!caseData) {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Create new case</h2>
    } else {
      return <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Duplicate case</h2>
    }
  }

  useEffect(() => {
    console.log("form", formValues)
    if (formValues && formValues.bill_type === "bill") {
      setShowBilling(true)
    } else {
      setShowBilling(false)
    }

    // Handle removal of organisation if bill_type not bill
    if (formValues && formValues.bill_type !== "bill") {
      if (!("organisation" in formValues)) return

      // Only remove organisation key if not already in formValues
      const { ["organisation"]: removedKey, ...rest } = formValues
      setFormValues(rest)
    }
  }, [formValues]);

  const tabMap = {
    regular: <RegularCaseForm
      type={type}
      user={user}
      formValues={formValues}
      handleInputChange={handleInputChange}
      setFormValues={setFormValues}
      activeTab={activeTab}
      handlePriceChange={handlePriceChange}
      onError={onError}
      btnClicked={btnClicked}
      handleCaseCreate={handleCaseCreate}
      setOnError={setOnError}
      tripType={tripType}
      serviceType={serviceType}
      chairType={chairType}
      callType={callType}
      billType={billType}
      showBilling={showBilling}
    />,
    prison: <PrisonCaseForm
      type={type}
      user={user}
      formValues={formValues}
      handleInputChange={handleInputChange}
      setFormValues={setFormValues}
      activeTab={activeTab}
      handlePriceChange={handlePriceChange}
      onError={onError}
      btnClicked={btnClicked}
      handleCaseCreate={handleCaseCreate}
      setOnError={setOnError}
      tripType={tripType}
      serviceType={serviceType}
      chairType={chairType}
      billType={billType}
      showBilling={showBilling}
    />,
    dialysis: <DialysisCaseForm
      type={type}
      user={user}
      formValues={formValues}
      handleInputChange={handleInputChange}
      setFormValues={setFormValues}
      activeTab={activeTab}
      handlePriceChange={handlePriceChange}
      onError={onError}
      btnClicked={btnClicked}
      handleCaseCreate={handleCaseCreate}
      setOnError={setOnError}
      tripType={tripType}
      serviceType={serviceType}
      chairType={chairType}
      callType={callType}
      billType={billType}
      showBilling={showBilling}
    />

  }

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto lg:py-8" style={{ maxWidth: "1000px" }}>
        {
          generateTitle()
        }
        <Button.Group color='gray'>
          <Button disabled={type === "edit"} size="xs" color={activeTab === "regular" ? "info" : "gray"} onClick={() => { setActiveTab('regular') }}>
            Regular
          </Button>
          <Button disabled={type === "edit"} size="xs" color={activeTab === "prison" ? "info" : "gray"} onClick={() => { setActiveTab('prison') }}>
            Prison
          </Button>
          <Button disabled={type === "edit"} size="xs" color={activeTab === "dialysis" ? "info" : "gray"} onClick={() => { setActiveTab('dialysis') }}>
            Dialysis
          </Button>
        </Button.Group>
        <hr style={{ marginBottom: "15px" }} />
        <form>
          {
            tabMap[activeTab]
          }
          <div className='flex'>
            {
              user?.role === "Ctrl" && type === "create" &&
              <>
                <Button disabled={onError || btnClicked} onClick={() => handleSubmit(type, true)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
                  Create & assign case
                </Button>
                <Button disabled={onError || btnClicked} onClick={() => handleSubmit(type, false, true)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
                  {type} & copy case
                </Button>
              </>
            }


            <Button disabled={onError || btnClicked} onClick={() => handleSubmit(type, false)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
              {type} Case
            </Button>
            {
              ["create", "edit"].includes(type) && ["Admin", "Ctrl"].includes(String(user?.role)) &&
              <Button className="my-4 mr-3" onClick={() => {
                onClickCaseAssignment(formValues)
              }}>
                Assign
              </Button>
            }
            {
              formValues?.trip_type === "2w" && type === "edit" &&
              <>
                {
                  !["trip1_end", "trip2_end", "ended"].includes(formValues?.status) && formValues?.service_start &&
                  <Button color="failure" disabled={onError || btnClicked} onClick={() => onStatusChange("trip1_end", caseData?.id, formValues)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
                    Trip 1 Ended
                  </Button>
                }
                {
                  !["trip2_end", "ended"].includes(formValues?.status) && formValues?.service_start && formValues?.service_end && formValues?.status === "trip1_end" &&
                  <Button color="failure" disabled={onError || btnClicked} onClick={() => onStatusChange("trip2_end", caseData?.id, formValues)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
                    Trip 2 Ended
                  </Button>
                }
              </>
            }
            {
              formValues?.service_end && formValues?.bill_type !== "paynow" &&
              <Button color="failure" disabled={onError || btnClicked} onClick={() => onStatusChange("ended", caseData?.id, formValues)} className="my-4 mr-3" style={{ textTransform: "capitalize" }}>
                End Job
              </Button>
            }
            {
              PaymentButton(user, type, formValues)
            }
          </div>

        </form>
      </div>
      <AssignCaseModal shouldReturnAssignCallback={["edit"].includes(type)} staffOptions={staffOptions} vehicleOptions={vehicleOptions} openModal={openAssignmentModal} copy={["create"].includes(type)} caseData={selectedCase} onAssign={handleAssignment} onCloseModal={() => { setOpenAssignmentModal((openAssignmentModal) => !openAssignmentModal) }} />
    </section>
  );
}

export default SingleCase;
