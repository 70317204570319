import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import { Input, Select, RepeaterTable, FileUpload, DynamicTable, MultiSelect } from '../../form-ui'
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { HiTrash } from 'react-icons/hi';

import axios from 'axios'
import Swal from 'sweetalert2'

interface OptionItem {
  label: string;
  value: string;

}

function ImportCases() {
  const [btnClicked, setBtnClicked] = useState<boolean>(false);

  const [data, setData] = useState<Record<string, string>[]>()

  // Global states for keyCol and updateCols
  const [keyColumn, setKeyColumn] = useState<string>("");
  const [updateColumns, setUpdateColumns] = useState<string[]>();

  // Manage state for selected columns
  const [colToUpdate, setColToUpdate] = useState<OptionItem[]>();


  useEffect(() => {
    if (colToUpdate) {
      setUpdateColumns(colToUpdate?.map((val) => { return val.value }))
    }
  }, [colToUpdate]);

  const handleClearData = () => {
    // Reset states
    setData(undefined)
    setBtnClicked(false)
    setKeyColumn("")
    setUpdateColumns(undefined)
    setColToUpdate(undefined)
  }


  function convertUnknownArray(arr: unknown[]): Record<string, string>[] {
    if (!Array.isArray(arr)) throw new Error('Input is not an array');

    return arr.map(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.entries(item).reduce((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {} as Record<string, string>);
      } else {
        throw new Error('Array contains non-object elements');
      }
    });
  }

  const ExcelComponent = () => {
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const result = e.target?.result as string;
          const workbook = XLSX.read(result, { type: 'binary' });
          // Access sheets, rows, and cells from the workbook
          const wsname = workbook.SheetNames[0];
          const _data = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          // const data = XLSX.utils.sheet_to_json(data)
          console.log(_data);

          setData(convertUnknownArray(_data))
        };
        reader.readAsBinaryString(file);
      }
    };

    return (
      <FileUpload accept='.xlsx, .csv' type='dropzone' name='upload-report' fieldName="Upload" onUpload={handleFileUpload} />

    );
  }

  const handleImport = (data: any) => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}import`
    axios.post(url, data).then((res) => {
      console.log("Import status", res?.data?.status)
      console.log("Import errors", res?.data?.errors)

      Swal.fire({
        icon: res?.data?.errors ? "warning" : 'success',
        title: res?.data?.status,
        confirmButtonText: 'Ok',
        html: `${JSON.stringify(res?.data?.errors) || ""}`
      }).then((result) => {
        setBtnClicked((btnClicked) => !btnClicked)
      })
    }).catch((err) => {
      console.error(err)
      Swal.fire({
        icon: 'error',
        title: err?.response?.data?.status,
        confirmButtonText: 'Ok',
        html: JSON.stringify(err?.response?.data?.errors)
      }).then((result) => {
        setBtnClicked((btnClicked) => !btnClicked)
      })
    });

  }

  const onClickImport = (keyCol: string, updateCol: string[] | undefined, data: Record<string, string>[]) => {
    if (!keyCol || !updateCol) return
    const request = {
      data: data,
      key_column: keyCol,
      update_columns: updateCol
    }

    handleImport(request)
  }

  interface ImportWidget {
    caseData: Record<string, string>[]
  }

  const ImportWidget: React.FC<ImportWidget> = ({ caseData }) => {
    const allowedKeyColumn = ["ID", "UUID", "Payment ID", "Payout ID", "Invoice ID"]
    const allowedColumnToUpdate = [
      "Service Type",
      "Trip Type",
      "Call Type",
      "Bill Type",
      "Caller Name",
      "Caller Number",
      "Billing Organisation",
      "Total Price",
      "Price Working",
      "Job Number",
      "Case Status",
      "Receipt Number",
      "Remarks",
      "Chair Type",
      "Case Type",
      "Patient Name",
      "Payment Status",
      "Payment ID",
      "Invoice ID",
      "Payout ID"
    ]

    const columns = Object.keys(caseData[0])
      .filter((col: string) => allowedKeyColumn.includes(col))
      .map((col: string) => ({
        value: col,
        label: col
      }));

    const SelectKeyColumn = () => {
      return (
        columns && columns.length > 0 ?
          <Select values={columns} value={keyColumn} name='key_column' fieldName='Select key column' type='searchable' onChange={(name, value) => { value && setKeyColumn(value) }} />
          :
          <>
            <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Invalid excel file</h2>
            <p className="text-md text-gray-900 dark:text-white">No importable columns detected.</p>
          </>
      )
    }

    const SelectColumnToUpdate = () => {
      const handleColToUpdateChange = (name: string, value: OptionItem[] | undefined) => {
        if (!value) return
        setColToUpdate(value)
      }

      const columns = Object.keys(caseData[0])
        .filter((col: string) => allowedColumnToUpdate.includes(col))
        .map((col: string) => ({
          value: col,
          label: col
        }));

      return (
        <MultiSelect values={columns} value={colToUpdate} name='col_to_update' fieldName='Column(s) to update its values' onChange={handleColToUpdateChange} />
      )
    }

    return (
      <>
        <SelectKeyColumn />
        <div style={{ marginTop: '20px' }}>
          {
            keyColumn && keyColumn !== "" &&
            <SelectColumnToUpdate />
          }
        </div>

      </>
    )
  }


  return (
    <>
      <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Import/Update case data</h2>
      <p className="text-md text-gray-900 dark:text-white">Please ensure to save a backup before importing.</p>
      {
        data ?
          <>

            <p className="text-xs text-gray-900 dark:text-white">Preview first three rows.</p>
            <div style={{ overflowX: "auto" }}>
              <DynamicTable tableValues={data.slice(0, 3)} />
            </div>
            <Button pill size="xs" color="failure" onClick={handleClearData} >
              <HiTrash className="mr-2 h-4 w-4" />
              Clear data
            </Button>
            <div style={{ marginTop: "20px" }}>
              <ImportWidget caseData={data} />
            </div>
            <Button onClick={() => { onClickImport(keyColumn, updateColumns, data) }} style={{ marginTop: "50px" }} disabled={btnClicked}>
              Import
            </Button>
          </>
          :
          <>
            <ExcelComponent />
          </>
      }

    </>
  );
}

export default ImportCases;
