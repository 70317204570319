import React, { useState, useEffect } from 'react';
import { Case, Assignment, TripAssignments, InitialTripAssignments } from '../types'
import { processTripAssignments, initialTripAssignmentsMapper } from './utils'
import { Button, Modal, Table as FbTable } from 'flowbite-react';
import { renderMessage } from '../../../shared/msg-generator'
import Swal from 'sweetalert2'
import Select from 'react-select';
import axios from 'axios';
import { isCaseOrDefaultClause } from 'typescript';


interface IOption {
    value: string;
    label: string;
}

interface AddressSelectorModal {
    caseData: Case | undefined;
    openModal: boolean;
    staffOptions: IOption[] | undefined;
    vehicleOptions: IOption[] | undefined;
    shouldReturnAssignCallback?: boolean;
    copy?: boolean;
    onCloseModal: () => void;
    onAssign: (assignData?: any) => void;
    // onSelectAddress: (address: OneMapData) => void;
}

export const AssignCaseModal: React.FC<AddressSelectorModal> = ({ caseData, openModal, staffOptions, vehicleOptions, shouldReturnAssignCallback, copy, onCloseModal, onAssign }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tripAssignments, setTripAssignments] = useState<InitialTripAssignments>({});
    const [fieldsDisabled, setFieldsDisabled] = useState<boolean>(false)

    // Track assignment status based on trip.
    // Controlled state management as there should only be two trips for now.
    interface FirstTimeAssign {
        [key: number]: boolean
    }
    const [firstTimeAssigning, setFirstTimeAssigning] = useState<FirstTimeAssign | undefined>(undefined);

    useEffect(() => {
        // if (!caseData?.assignment) return

        // Map assignment into new format
        const mappedAssignment = initialTripAssignmentsMapper(caseData?.assignment) || {}

        // Map mappedAssignment into firstTimeAssigning
        const mappedFirstTimeAssignment = Object.keys(mappedAssignment).reduce((result: any, key) => {
            result[key] = true;
            return result;
        }, {});
        setFirstTimeAssigning(mappedFirstTimeAssignment)

        // Initialize the tripAssignments state based on the caseData's assignment key.
        setTripAssignments(mappedAssignment);

    }, [caseData?.assignment]);

    useEffect(() => {
        console.log("tripAssignments", tripAssignments)
    }, [tripAssignments]);

    const handleCloseModal = () => {
        setTripAssignments({}) // Sets tripAssignment back to default empty object value.
        if (onCloseModal) onCloseModal()
    }

    const handleAssignmentChange = (trip: number, selectedOption: any, type: "staff" | "vehicle") => {
        console.log("selected op", selectedOption)
        if (type === "vehicle") {
            setTripAssignments(prevAssignments => ({
                ...prevAssignments,
                [trip]: {
                    staff: prevAssignments[trip]?.staff,
                    vehicle: selectedOption
                }
            }));
        }

        if (type === "staff") {
            const prevTripAssignmentCount = Array.isArray(tripAssignments[trip]?.staff) ? tripAssignments[trip].staff : []

            // prevTripAssignmentCount -> Previous assignment state's array length
            // selectedOption -> Current selected options
            /**
             * prevTripAssignmentCount must be less than 1 and selectedOption must be 1
             * Basically previous state must be empty, while current state must be an array length of 1.
             * This means that the trip input is being entered for the first time.
             */
            if (prevTripAssignmentCount.length < 1 && selectedOption.length == 1) {
                setFirstTimeAssigning({
                    ...firstTimeAssigning,
                    [trip]: false
                })

                // Disable fields while loading
                setFieldsDisabled(true)
                const staffId = selectedOption[0].value
                const url = `${process.env.REACT_APP_API_URI}cases/assignment/staff/${staffId}`
                axios.get(url).then((res) => {
                    setFieldsDisabled(false)
                    const staff = res.data?.staff;
                    const vehicle = res.data?.vehicle;

                    // Set the current trip assignment to null first
                    // Reassign current trip with staff assignment
                    console.log("staff", staff)
                    setTripAssignments(prevAssignments => ({
                        ...prevAssignments,
                        [trip]: {
                            staff: (Array.isArray(staff) && staff.length === 0) || staff === undefined ? selectedOption : staff,
                            vehicle: vehicle ? vehicle : null
                        }
                    }));
                    // if (staff) {
                    //     staff.forEach((element:IOption) => {
                    //         handleAssignmentChange(trip,element,"staff")
                    //     });
                    // }

                    // if (vehicle) {
                    //     handleAssignmentChange(trip,vehicle,"vehicle")
                    // }

                }).catch((err) => {
                    setFieldsDisabled(false)
                    console.error("Cannot get latest assignment", err)
                    setTripAssignments(prevAssignments => ({
                        ...prevAssignments,
                        [trip]: {
                            staff: selectedOption,
                            vehicle: prevAssignments[trip]?.vehicle
                        }
                    }));
                })
            } else {
                setTripAssignments(prevAssignments => ({
                    ...prevAssignments,
                    [trip]: {
                        staff: selectedOption,
                        vehicle: prevAssignments[trip]?.vehicle
                    }
                }));
            }
        }
    };

    const onClickAssign = async () => {
        // Return if no user assigned
        if (!tripAssignments || !caseData) return
        const cfm_msg = Swal.fire({
            title: 'Confirm assignment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm and copy'
        })
        if (!(await cfm_msg).isConfirmed) return

        // Loop through each tripAssignments entry
        const tripDataLabelArr = processTripAssignments(tripAssignments, "label")
        const tripDataValueArr = processTripAssignments(tripAssignments, "value")


        const assignmentMsg = renderMessage(caseData, tripDataLabelArr)
        await navigator.clipboard.writeText(assignmentMsg)


        const generateRequestBody = () => {
            console.log("tripsData", tripDataLabelArr)
            return {
                "case_id": caseData?.id,
                trips: tripDataValueArr,
                message: assignmentMsg
            };
        };

        const request = generateRequestBody()


        const url = `${process.env.REACT_APP_API_URI}cases/assignment`

        // Trying to assign on a create page
        if (shouldReturnAssignCallback) {
            if (onAssign) onAssign(request)
        } else {
            axios.put(url, request).then((res) => {
                // Handle call back onAssign to parent
                Swal.fire(
                    copy ? 'Assignment confirmed and copied' : 'Assignment confirmed',
                    '',
                    'success'
                )

                if (onAssign) onAssign()
            }).catch((err) => {
                console.error(err)
            });

        }
    }


    const getStaffLatestAssignment = (staffId: string, trip: number) => {
        const url = `${process.env.REACT_APP_API_URI}cases/assignment/staff/${staffId}`
        axios.get(url).then((res) => {
            const staff = res.data?.staff;
            const vehicle = res.data?.vehicle;

            // Set the current trip assignment to null first
            handleAssignmentChange(trip, null, "staff")
            handleAssignmentChange(trip, null, "vehicle")

            // Reassign current trip with staff assignment
            if (staff) {
                staff.forEach((element: IOption) => {
                    handleAssignmentChange(trip, element, "staff")
                });
            }

            if (vehicle) {
                handleAssignmentChange(trip, vehicle, "vehicle")
            }

        }).catch((err) => {
            console.error("Cannot et latest assignment", err)
        })
    }


    return (
        <Modal show={openModal} onClose={handleCloseModal}>
            <Modal.Header>Staff Assignment</Modal.Header>
            <Modal.Body>
                <div className="space-y-6" style={{ height: "350px" }}>
                    <div>
                        <div>
                            <label
                                htmlFor="trip1"
                                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Trip 1
                            </label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                // defaultValue={tripAssignments[1]}
                                // isDisabled={isDisabled}
                                isMulti
                                value={tripAssignments?.[1]?.staff || []}
                                onChange={selectedOption => handleAssignmentChange(1, selectedOption, "staff")}
                                isLoading={isLoading}
                                isClearable={true}
                                isSearchable={true}
                                name="trip1"
                                options={staffOptions}
                                isDisabled={fieldsDisabled}
                            />
                            <label
                                htmlFor="trip1-plate"
                                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Plate Number
                            </label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                // defaultValue={tripAssignments[1]}
                                // isDisabled={isDisabled}
                                value={tripAssignments?.[1]?.vehicle || []}
                                onChange={selectedOption => handleAssignmentChange(1, selectedOption, "vehicle")}
                                isLoading={isLoading}
                                isClearable={true}
                                isSearchable={true}
                                name="trip1-plate"
                                options={vehicleOptions}
                                isDisabled={fieldsDisabled}
                            />
                        </div>
                    </div>

                    {
                        caseData?.trip_type && caseData?.trip_type === "2w" &&
                        <div>
                            <div>
                                <label
                                    htmlFor="trip2"
                                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Trip 2
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    // defaultValue={tripAssignments[2]}
                                    // isDisabled={isDisabled}
                                    isMulti
                                    value={tripAssignments?.[2]?.staff || []}
                                    onChange={selectedOption => handleAssignmentChange(2, selectedOption, "staff")}
                                    isLoading={isLoading}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="trip2"
                                    options={staffOptions}
                                    isDisabled={fieldsDisabled}
                                />
                                <label
                                    htmlFor="trip2-plate"
                                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Plate Number
                                </label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    // defaultValue={tripAssignments[1]}
                                    // isDisabled={isDisabled}
                                    value={tripAssignments?.[2]?.vehicle || []}
                                    onChange={selectedOption => handleAssignmentChange(2, selectedOption, "vehicle")}
                                    isLoading={isLoading}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="trip2-plate"
                                    options={vehicleOptions}
                                    isDisabled={fieldsDisabled}
                                />
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClickAssign}>
                    {shouldReturnAssignCallback ? "Confirm" : "Confirm and copy"}
                </Button>
                <Button color="gray" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
