import { Badge as FbBadge } from 'flowbite-react';
import { FlowbiteColors } from 'flowbite-react'

export interface BadgeProps {
    // status: 'created' | 'active' | 'cancel' | 'assigned';
    status: string;
};

export const StatusMap: Record<string, string> = {
    "created": "Created",
    "active": "Active",
    "cancel": "Cancelled",
    "assigned": "Assigned",
    "assigned_t1": "Assigned T1",
    "assigned_t2": "Assigned T2",
    "hospitalised": "Hospitalised",
    "trip1_end": "Trip 1 Ended",
    "trip2_end": "Trip 2 Ended",
    "next_case": "Ready For Next Case",
    "ended": "Case Ended",
    "on_hold": "On-hold",
    "receipt_received": "Receipt Received"
}



export const StatusBadge: React.FC<BadgeProps> = ({ status }) => {
    let color = 'success'

    switch (status) {
        case 'created':
            color = 'warning';
            break;
        case 'assigned':
            color = 'green';
            break;
        case 'assigned_t1':
            color = 'green';
            break;
        case 'assigned_t2':
            color = 'green';
            break;
        case 'active':
            color = 'green';
            break;
        case 'trip1_end':
            color = 'green';
            break;
        case 'trip2_end':
            color = 'green';
            break;
        case 'cancel':
            color = 'failure';
            break;
        case 'ended':
            color = 'green';
            break;
        case 'receipt_received':
            color = 'green';
            break;
        default:
            color = 'dark';
            break;
    }

    return <FbBadge style={{ width: "fit-content" }} color={color}>{StatusMap[status]}</FbBadge>;
};

export const CaseBadge: React.FC<BadgeProps> = ({ status }) => {
    let color = 'success'

    switch (status) {
        case 'regular':
            color = 'green';
            break;
        case 'dialysis':
            color = 'cyan';
            break;
        case 'prison':
            color = 'failure';
            break;
        default:
            color = 'dark';
            break;
    }

    return <FbBadge style={{ width: "fit-content", textTransform: "capitalize" }} color={color}>{status}</FbBadge>;
};


export const ServiceTypeMap: Record<string, string> = {
    "a&e": "A&E",
    "admission": "Admission",
    "discharge": "Discharge",
    "terminal_discharge": "Terminal Discharge",
    "transfer": "Transfer",
    "medical_appointment": "Medical Appointment",
    "dialysis": "Dialysis",
    "others": "Others"
}

export const PaymentTypeMap: Record<string, string> = {
    "bill": "Bill",
    "paynow": "Paynow",
    "cash": "Cash"
}

export const PaymentTypeBadge: React.FC<BadgeProps> = ({ status }) => {
    // let color = 'success'
    // switch (status) {
    //     case 'bill':
    //         color = 'blue';
    //         break;
    //     case 'paynow':
    //         color = 'bl';
    //         break;
    //     case 'cash':
    //         color = 'green';
    //         break;
    //     default:
    //         color = 'dark';
    //         break;
    // }

    return <FbBadge style={{ width: "fit-content" }} color="gray">{PaymentTypeMap[status]}</FbBadge>;
}


export const SvcTypeBadge: React.FC<BadgeProps> = ({ status }) => {
    let color = 'success'

    // switch (status) {
    //     case 'created':
    //         color = 'warning';
    //         break;
    //     case 'assigned':
    //         color = 'green';
    //         break;
    //     case 'active':
    //         color = 'green';
    //         break;
    //     case 'cancel':
    //         color = 'failure';
    //         break;
    //     default:
    //         color = 'dark';
    //         break;
    // }

    return <FbBadge style={{ width: "fit-content" }} color="green">{ServiceTypeMap[status]}</FbBadge>;
};
