import { CaseProps } from './types'

export const shouldDisableBillType = (type: string, user: Record<string, string> | undefined, formValues: CaseProps["formValues"]) => {
  if (!formValues || !user) return

  if (!["Ctrl", "Admin"].includes(user?.role)) {
    if (type === "edit" && formValues["bill_type"] === "bill") return [0, 1, 2]
    return [0]
  } else {
    // Not disabled
    return false
  }
}