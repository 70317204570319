import React, { useState, useEffect } from 'react';
import { Button, Tabs } from 'flowbite-react';
import ImportCases from './import-cases'
import BillToSettings from './bill-to'
import { HiClipboard, HiUpload, HiUserCircle } from 'react-icons/hi';

import { useNavigate, useLocation } from "react-router-dom";
import { Input, Select, RepeaterTable } from '../form-ui'
import { renderMessage } from '../../shared/msg-generator'
import { parseTimestamp } from '../../shared/utils'
import { serviceType, chairType, billType, tripType, daysOfWeek, month, year } from '../../shared/dropdown-constraints'
import { ISettings, ISetting } from './types'
import { staffType } from '../../shared/dropdown-constraints'

import { toast } from 'react-toastify';

import axios from 'axios'
import Swal from 'sweetalert2'

interface OptionItem {
  label: string;
  value: string;

}

function StaffForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const [onError, setOnError] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<boolean>(false);
  const [settings, setSettings] = useState<any | {}>({});

  const getSettings = () => {
    const URL = `${process.env.REACT_APP_API_URI}settings`

    axios.get(URL).then((res) => {
      const data = res.data
      // Parse config key into JSON
      const parsedData = { ...data };

      // Iterate through each key in the object

      setSettings(parsedData)
    }).catch((err) => {
      console.error(err)
    });

  }


  useEffect(() => {
    getSettings()
  }, []);


  // Initialise formValues if edit

  const handleSettingsSave = (data: ISettings) => {
    setBtnClicked((btnClicked) => !btnClicked)
    const url = `${process.env.REACT_APP_API_URI}settings`
    axios.put(url, data).then((res) => {
      toast.success("Settings saved.")
    }).catch((err) => {
      console.error(err)
      toast.error(`Error saving settings`)
    });

  }


  const handleColumnInputChange = (name: string, value: ISettings | {}) => {
    const updatedValue = value !== undefined ? value : {};

    setSettings((prevRowValues: any) => ({
      ...prevRowValues,
      [name]: updatedValue,
    }));
  };

  const onClickSave = () => {
    console.log("settings", settings)
    handleSettingsSave(settings)
  }


  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto lg:py-8" style={{ maxWidth: "1000px" }}>
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Settings</h2>
        <Tabs.Group aria-label="Default tabs" style="default">
          <Tabs.Item active title="Bill To" icon={HiClipboard}>
            <BillToSettings
              onSettingChange={handleColumnInputChange}
              onSettingSave={onClickSave}
              settings={settings}
            />
          </Tabs.Item>
          <Tabs.Item title="Import/Update Cases" icon={HiUpload}>
            <ImportCases />
          </Tabs.Item>
        </Tabs.Group>
      </div>

    </section>

  );
}

export default StaffForm;
