import React, { useEffect, useContext } from 'react';
import { Card, Dropdown, Button } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button'
import logo from '../../assets/logo.png';
import AuthContext from '../../context/auth-context'
import { Login } from "../../components/login"

import axios from 'axios'


const SignInPage = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const { authStatus, setAuthStatus } = useContext(AuthContext);

  useEffect(() => {
    console.log("hello from login", authStatus)

    // let logout = location?.state?.logout;

    // if (logout === true) {
    //   handleLogout()
    // }

  }, []);

  const handleOnLogin = () => {
    navigate("/cases")
  }


  return (
    <div className='w-full flex justify-center items-center' style={{ margin: 0, top: "50%", position: "absolute", msTransform: "translateY(-50%)", transform: "translateY(-50%)" }}>
      <Card className=''>
        <div className="flex flex-col items-center pb-10">
          <img
            alt="Bonnie image"
            className="mb-3"
            src={logo}
            width="200"
          />
          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
            Welcome to AMS Case Portal
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Please sign in.
          </span>
          <Login onLogin={() => { handleOnLogin() }} />
        </div>
      </Card>

    </div>
  )
}

export default SignInPage