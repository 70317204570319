import React, { useState, useEffect } from 'react';
import { Table as FbTable } from 'flowbite-react';
import { Button, Label, TextInput, Dropdown } from 'flowbite-react';
import { useNavigate, useLocation } from "react-router-dom";
import axios, { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import { SearchInput } from '../../form-ui'
import { Patient } from '../types'
import { PatientModal } from '../patient-modal'

import { parseTimestamp } from '../../../shared/utils'
import { HiPlus } from 'react-icons/hi';
import { MdOutlinePeople } from 'react-icons/md';
import { Link } from "react-router-dom"

import './styles.css'


import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'


interface IOption {
  value: string;
  label: string;
}




function Table() {
  const rerender = React.useReducer(() => ({}), {})[1]
  const navigate = useNavigate();


  const [sorting, setSorting] = React.useState<SortingState>([])

  // Assignment Modal Stuff
  const [openPatientModal, setOpenPatientModal] = React.useState<boolean>(false)
  const [selectedPatient, setSelectedPatient] = React.useState<Patient | undefined>(undefined)
  const [searchPatient, setSearchPatient] = React.useState<string>()


  const handleModalSubmit = (patientData?: Patient) => {
    handleReload()
    setOpenPatientModal((openPatientModal) => !openPatientModal)
  }

  const handleCloseModal = () => {
    setSelectedPatient(undefined)
    setOpenPatientModal((openPatientModal) => !openPatientModal)
  }


  const onClickEdit = (patientData: Patient) => {
    setOpenPatientModal((openPatientModal) => !openPatientModal)
    setSelectedPatient(patientData)
  }

  const handleReload = () => {
    fetchPatients()
  }
  const columns = React.useMemo<ColumnDef<Patient>[]>(
    () => [
      {
        accessorFn: row => row.name,
        id: 'name',
        enableMultiSort: true,
        cell: info => <>{info.getValue()}</>,
        header: "Name",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.nric,
        id: 'nric',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "NRIC",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.status,
        id: 'status',
        enableMultiSort: true,
        cell: info => (
          <>
            {/* <Dropdown
              className=''
              label={<StatusBadge status={String(info.getValue())} />}
              style={{ padding: 0, margin: "1px 0px", backgroundColor: "transparent" }}
            >
              <Dropdown.Item onClick={() => { onStatusChange("created", info.row.original.id) }} >
                Created
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("cancel", info.row.original.id) }} >
                Cancelled
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("assigned", info.row.original.id) }} >
                Assigned
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("assigned_t1", info.row.original.id) }} >
                Assigned T1
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("assigned_t2", info.row.original.id) }} >
                Assigned  T2
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("pt_reach", info.row.original.id) }} >
                Patient Reached
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("pt_onboard", info.row.original.id) }} >
                Patient Onboard
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { onStatusChange("pt_offload", info.row.original.id) }} >
                Patient Offloaded
              </Dropdown.Item>
            </Dropdown> */}
          </>
        ),
        header: "Status",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.organisation,
        id: 'organisation',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "Organisation",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.caller,
        id: 'caller',
        enableMultiSort: true,
        cell: info => (
          <>
            CL: {info.row.original.caller}<br />
            HP:  {info.row.original.caller_number}
          </>
        ),
        header: "Caller",
        // footer: props => props.column.id,
      },
      {
        accessorFn: row => row.remarks,
        id: 'remarks',
        enableMultiSort: true,
        cell: info => (
          <>{info.getValue()}</>
        ),
        header: "Remarks",
        // footer: props => props.column.id,
      },
      {

        header: 'Action',
        accessor: 'action',
        cell: info =>
        // (<button className="btn1" onClick={() => {console.log("info", info.row.original)}}>Details</button>)
        (
          <>
            <div style={{ display: "flex" }}>
              <Button style={{ padding: 0, margin: "1px" }} onClick={() => { onClickEdit(info.row.original) }} className="">
                Edit
              </Button>
            </div>
          </>

        )
        ,
      }
    ],
    [] // Only render on staffOption.
  )

  const [data, setData] = React.useState<Patient[]>([])
  const [queryDate, setQueryDate] = React.useState<string>(parseTimestamp(String(new Date)).date)
  console.log("data", data)

  // useEffect(() => {
  //   console.log("queryDate", queryDate);

  // }, [queryDate]);

  const handleSetQueryDate = (date: string) => {
    setQueryDate(parseTimestamp(String(date)).date)
  }

  const onStatusChange = (status: string, case_id: number | undefined) => {
    const url = `${process.env.REACT_APP_API_URI}cases/status`
    axios.put(url, { "case_id": case_id, status: status }).then((res) => {
      fetchPatients()
      alert(`Case status changed to ${status}`)
    }).catch((err) => {
      console.error(err)
    });


  }

  const openModal = () => {
    setOpenPatientModal((openPatientModal) => !openPatientModal)
  }

  const fetchPatients = () => {
    const URL = `${process.env.REACT_APP_API_URI}patients`
    let query = ""
    if (searchPatient) {
      query = "?name=" + searchPatient
    }

    axios.get(URL + query).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.error(err)
    });
  }


  useEffect(() => {
    // handleFetchAndSetStaffAndVehicle()
  }, []);

  useEffect(() => {
    fetchPatients()
  }, [searchPatient]);


  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    enableMultiSort: true,
    debugTable: true
  })

  return (
    <div className="p-2">
      <div className="h-2" />
      <div className="flex " style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
        <div style={{ flex: "1", display: "inline-flex" }}>
          <div style={{ margin: "10px" }}>
            <Button size="md" onClick={openModal}><HiPlus className="mr-2 h-5 w-5" /><p>Create Patient</p></Button>
          </div>
          <div style={{ minWidth: "350px" }}>
            <SearchInput value={searchPatient} name='search_pt_name' fieldName='Search Patient' placeholder='Patient name' onSearch={(name) => { setSearchPatient(name) }} />
          </div>
        </div>
        {/* <div style={{ flex: "1" }}>
          <div style={{ margin: "10px", display: "flex", justifyContent: 'flex-end' }}>
            <Button color="gray" onClick={onClickPtSearch} size="md"><MdOutlinePeople className="mr-2 h-5 w-5" /><p>Search Patient</p></Button>
          </div>
        </div> */}


      </div>
      <div style={{ overflowX: "scroll" }}>

        <FbTable striped>
          <FbTable.Head>
            {table.getFlatHeaders().map(header => {
              return (
                <FbTable.HeadCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </FbTable.HeadCell>
              )
            })}

          </FbTable.Head>
          <FbTable.Body>
            {table
              .getRowModel()
              .rows
              .map(row => {
                return (
                  <FbTable.Row key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <FbTable.Cell style={{ padding: "1px", color: "black" }} key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </FbTable.Cell>
                      )
                    })}
                  </FbTable.Row>
                )
              })}
          </FbTable.Body>
        </FbTable>
      </div>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <Button
          size="xs"
          color="gray"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </Button>
        <Button
          size="xs"
          color="gray"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </Button>

        <Button
          size="xs"
          color="gray"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </Button>

        <Button
          size="xs"
          color="gray"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </Button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>{table.getRowModel().rows.length} Rows</div>

      {/* Debugger */}
      {/* <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
      <PatientModal openModal={openPatientModal} patient={selectedPatient} onCloseModal={handleCloseModal} onSubmit={(patient) => { handleModalSubmit(patient) }} />
    </div>
  )
}

export default Table;