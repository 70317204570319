
export const staffType = [
    { label: "Admin", value: "Admin" },
    { label: "Driver", value: "Driver" },
    { label: "Medic", value: "Medic" },
    { label: "Controller", value: "Ctrl" }
]

export const serviceType = [
    { label: "A&E", value: "a&e" },
    { label: "Admission", value: "admission" },
    { label: "Discharge", value: "discharge" },
    { label: "Terminal Discharge", value: "terminal_discharge" },
    { label: "Transfer", value: "transfer" },
    { label: "Medical Appointment", value: "medical_appointment" },
    { label: "Others", value: "others" },
]

export const chairType = [
    { label: "Own Wheelchair", value: "owc" },
    { label: "Motorised Wheelchair", value: "motorwheelchair" },
    { label: "Stretcher", value: "stretcher" },
    { label: "Ambulance Wheelchair", value: "awc" }
]

export const billType = [
    { value: "bill", label: "Bill" },
    { value: "paynow", label: "Paynow" },
    { value: "cash", label: "Cash" }
]

export const tripType = [
    { value: "1w", label: "1W" },
    { value: "2w", label: "2W" },
]

export const callType = [
    { label: "Regular", value: "regular" },
    { label: "1777", value: "1777" },
    { label: "Direct Call", value: "dc" }
]

export const daysOfWeek = [
    { label: "Monday", value: "0" },
    { label: "Tuesday", value: "1" },
    { label: "Wednesday", value: "2" },
    { label: "Thursday", value: "3" },
    { label: "Friday", value: "4" },
    { label: "Saturday", value: "5" },
    { label: "Sunday", value: "6" }
];


export const month = [
    { label: "-- Month --", value: "" },
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" }
];

export const year = [
    { label: "-- Year --", value: "" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
    { label: "2029", value: "2029" },
    { label: "2030", value: "2030" },
    { label: "2031", value: "2031" },
    { label: "2032", value: "2032" },
    { label: "2033", value: "2033" }
];
